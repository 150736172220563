import { ArrowBackTwoTone } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  TextField,
  Box,
  Card,
  Grid,
  Button,
  Typography,
  Stack,
  styled,
  Divider,
} from "@mui/material";
import { Confirm, Notify } from "notiflix";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import SuspenseLoader from "src/components/SuspenseLoader";
import axiosInstance from "src/utils/axiosInstance";

export default function AddEditCafe() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { cafeId } = useParams();
  const [loading, setLoading] = useState(true);
  const [formInput, setFormInput] = useState({});
  const [isWait, setIsWait] = useState(false);
  const [coverImage, setCoverImage] = useState(null);

  const handleChange = (event) => {
    event.preventDefault();
    setFormInput({ ...formInput, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    const _cafeId = formInput?._id || cafeId;
    if (!_cafeId) {
      setLoading(false);
      return;
    }
    (async () => {
      try {
        setLoading(true);
        const resp = await axiosInstance.get(`/cafes/${_cafeId}`);
        if (resp?.status === 200) {
          const _blog = resp?.data?.data || {};
          _blog.tags = _blog?.tags?.join(", ");
          setFormInput(_blog);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        Notify.failure(
          error.response?.data?.message ||
            error.response?.statusText ||
            "an error occured"
        );
      }
    })();
  }, []);

  const handleSave = async (status) => {
    try {
      if (!formInput?.address?.address1) {
        Notify.failure("Please enter complete address");
        return;
      }
      if (
        !formInput?.googleMapLink &&
        (!formInput?.address?.latitude || !formInput?.address?.longitude)
      ) {
        Notify.failure(
          "Please enter either google map link or latitude and longitude"
        );
        return;
      }
      setIsWait(true);

      // if cover image is changed
      if (coverImage) {
        const formData = new FormData();
        formData.append("files", coverImage);
        formData.append("fileCategory", "blogs");

        const imageResp = await axiosInstance.post(
          "/users/upload-media",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (imageResp.status === 200 && imageResp.data) {
          formInput.coverImage = imageResp.data?.[0];
          setFormInput({
            ...formInput,
            coverImage: imageResp.data?.[0],
          });
        }
      }

      if (formInput?.address?.latitude) {
        formInput.address.latitude = parseFloat(formInput.address.latitude);
      }
      if (formInput?.address?.longitude) {
        formInput.address.longitude = parseFloat(formInput.address.longitude);
      }
      if (formInput?._id) {
        const resp = await axiosInstance.put(`/cafes/${formInput?._id}`, {
          ...formInput,
        });
        if (resp?.status === 200) {
          const _blog = resp?.data?.data || {};
          setFormInput(_blog);
          Notify.success("Cafe updated successfully");
        }
      } else {
        const resp = await axiosInstance.post(`/cafes`, {
          ...formInput,
        });
        if (resp?.status === 201) {
          const _blog = resp?.data?.data || {};
          setFormInput(_blog);
          Notify.success("Cafe added successfully");
          navigate(`/dashboard/cafes/${resp?.data?.data?._id}/edit`);
        }
      }
      setIsWait(false);
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  const handleDelete = async () => {
    try {
      setIsWait(true);
      const resp = await axiosInstance.delete(`/cafes/${formInput?._id}`);
      if (resp?.status === 200) {
        Notify.success("Cafe deleted successfully");
        navigate(`/dashboard/cafes`);
      }
      setIsWait(false);
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  return loading ? (
    <SuspenseLoader />
  ) : (
    <Box m={3}>
      <Box flex={1} mb={2}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <Typography variant="h3" component="h3" gutterBottom>
                  {formInput?._id ? t("Edit Cafe") : t("Add Cafe")}
                </Typography>
                <Typography variant="subtitle2">
                  {t("Fill in the fields below")}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item>
            <Button
              sx={{
                mt: { xs: 2, sm: 0 },
              }}
              component={Link}
              startIcon={<ArrowBackTwoTone />}
              to={`/dashboard/cafes`}
            >
              {t("Go back to all cafes")}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Card
        sx={{
          p: 3,
        }}
      >
        <Stack spacing={2}>
          {/* <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            pb={3}
          >
            <AvatarWrapper>
              <Avatar
                variant="rounded"
                alt={""}
                src={
                  coverImage
                    ? URL.createObjectURL(coverImage)
                    : formInput?.coverImage || ""
                      ? `${process.env.REACT_APP_DO_SPACE_URL}${formInput?.coverImage}?${new Date().getTime()}`
                      : ""
                }
              />
              <ButtonUploadWrapper>
                <Input
                  accept="image/*"
                  id="icon-button-file"
                  name="icon-button-file"
                  type="file"
                  onChange={(e) => {
                    setCoverImage(e.target.files[0]);
                  }}
                />
                <label htmlFor="icon-button-file">
                  <IconButton component="span" color="primary">
                    <CloudUploadTwoTone />
                  </IconButton>
                </label>
              </ButtonUploadWrapper>
            </AvatarWrapper>
          </Box> */}
          <TextField
            fullWidth
            name="title"
            placeholder={t("Enter Cafe name (optional)")}
            variant="outlined"
            value={formInput?.title || ""}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            label="Complete Address*"
            name="address"
            value={formInput?.address?.address1 || ""}
            onChange={(e) => {
              setFormInput({
                ...formInput,
                address: {
                  ...(formInput?.address || {}),
                  address1: e.target.value,
                },
              });
            }}
            multiline
            rows={4}
          />
        </Stack>
        <Stack mt={4} spacing={2}>
          <TextField
            fullWidth
            name="googleMapLink"
            placeholder={"Paste google map link here..."}
            variant="outlined"
            value={formInput?.googleMapLink || ""}
            onChange={handleChange}
            onBlur={(e) => {
              const url = e.target.value;
              const coordinates = url.match(/@([-0-9.]+),([-0-9.]+)/);

              if (coordinates && coordinates.length >= 3) {
                const latitude = coordinates?.[1]; // Latitude
                const longitude = coordinates?.[2]; // Longitude

                setFormInput({
                  ...formInput,
                  address: {
                    ...(formInput?.address || {}),
                    latitude: latitude,
                    longitude: longitude,
                  },
                });
              } else {
                Notify.failure(
                  "Invalid google map link. Latitude and Longitude not found in the link."
                );
              }
            }}
          />
          <Divider>OR</Divider>
          <Stack direction={"row"} spacing={2} alignItems={"center"}>
            <TextField
              fullWidth
              label="Enter Latitude"
              placeholder={"Enter Latitude"}
              variant="outlined"
              type="number"
              value={formInput?.address?.latitude || ""}
              onChange={(e) => {
                setFormInput({
                  ...formInput,
                  address: {
                    ...(formInput?.address || {}),
                    latitude: e.target.value,
                  },
                });
              }}
            />
            <TextField
              fullWidth
              label="Enter Longitude"
              placeholder={"Enter Longitude"}
              variant="outlined"
              type="number"
              value={formInput?.address?.longitude || ""}
              onChange={(e) => {
                setFormInput({
                  ...formInput,
                  address: {
                    ...(formInput?.address || {}),
                    longitude: e.target.value,
                  },
                });
              }}
            />
          </Stack>
        </Stack>

        <Stack
          direction={"row"}
          my={3}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Box>
            {formInput?._id && (
              <LoadingButton
                variant="contained"
                loading={isWait}
                onClick={() => {
                  Confirm.show(
                    "Confirmation",
                    "Are you sure you want to delete this blog? This action cannot be undone.",
                    "Confirm",
                    "Cancel",
                    () => handleDelete(),
                    () => {},
                    {
                      width: "500px",
                      messageMaxLength: 1000,
                    }
                  );
                }}
                color="error"
              >
                {t("Delete")}
              </LoadingButton>
            )}
          </Box>
          <Stack direction={"row"} spacing={2} alignItems={"center"}>
            <LoadingButton
              variant="contained"
              loading={isWait}
              onClick={() => {
                handleSave();
              }}
              color="success"
            >
              {t("Save")}
            </LoadingButton>
          </Stack>
        </Stack>
      </Card>
    </Box>
  );
}

const Input = styled("input")({
  display: "none",
});

const AvatarWrapper = styled(Box)(
  ({ theme }) => `
  
      position: relative;
  
      .MuiAvatar-root {
        width: ${theme.spacing(16)};
        height: ${theme.spacing(16)};
      }
  `
);

const ButtonUploadWrapper = styled(Box)(
  ({ theme }) => `
      position: absolute;
      width: ${theme.spacing(6)};
      height: ${theme.spacing(6)};
      bottom: -${theme.spacing(2)};
      right: -${theme.spacing(2)};
  
      .MuiIconButton-root {
        border-radius: 100%;
        background: ${theme.colors.primary.main};
        color: ${theme.palette.primary.contrastText};
        box-shadow: ${theme.colors.shadows.primary};
        width: ${theme.spacing(6)};
        height: ${theme.spacing(6)};
        padding: 0;
    
        &:hover {
          background: ${theme.colors.primary.dark};
        }
      }
  `
);
