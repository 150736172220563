import { capitalize } from "@mui/material";

export const getRoleTitle = (accountType, role) => {
  // admin, owner, moderator, user
  let _role = "user";

  if (accountType === "admin") {
    _role = accountType;
  } else if (accountType === "user") {
    _role = role || "user";
  }

  return _role;
};

export const roleFormator = (role) => {
  if (!role) return "";
  return role
    ?.split("-")
    ?.map((s) => capitalize(s))
    ?.join(" ");
};

export function isUrlValid(str) {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$", // fragment locator
    "i",
  );
  return pattern.test(str);
}

// currency number formatter for INR currency
export const currencyFormatter = (amount) => {
  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });

  return formatter.format(amount);
};

export const refineText = (text) => {
  // remove special characters and not spaces
  return text?.replace(/[^a-zA-Z0-9\s]/g, " ");
};

export const convertBytesToReadable = (bytes) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
};

export const convertSecondsToMinutes = (seconds) => {
  seconds = Number(seconds || 0);
  if (seconds === 0) {
    return "-";
  }
  const m = Math.floor(seconds / 60);
  const s = Math.floor(seconds % 60);
  return `${m > 0 ? m + "m" : ""} ${s > 0 ? s + "s" : ""}`;
};

export const getChatObjectMetadata = (
  chat, // The chat item for which metadata is being generated.
  userId, // The currently logged-in user details.
  participants,
) => {
  if (!chat) {
    return null;
  }

  if (chat?.is_group_chat) {
    // Case: Group chat
    // Return metadata specific to group chats.
    return {
      id: chat?.chat_id,
      // Default avatar for group chats.
      avatar: chat?.avatar
        ? process.env.REACT_APP_DO_SPACE_URL + chat?.avatar
        : "",
      title: chat?.name, // Group name serves as the title.
      description: `${chat?.participants?.length} members in the chat`, // Description indicates the number of members.
      lastMessage: chat?.last_message || "New chat",
    };
  } else {
    // 1. get other user
    const otherUserId = chat?.participants?.find((p) => p !== userId);
    // find the participant
    const participant = participants.find(
      (p) => p?.mongo_user_id === otherUserId,
    );

    // Return metadata specific to individual chats.
    return {
      chatId: chat?.chat_id,
      id: participant?.mongo_user_id,
      avatar: participant?.profile_image
        ? process.env.REACT_APP_DO_SPACE_URL + participant?.profile_image
        : "", // Participant's avatar URL.
      title: participant?.first_name + " " + participant?.last_name, // Participant's username serves as the title.
      description: participant?.email, // Email address of the participant.
      lastMessage: chat?.last_message || "New chat",
      createdAt: chat?.created_at,
      updatedAt: chat?.updated_at,
    };
  }
};

export const getImageUrl = (url) => {
  return url.startsWith("http")
    ? url
    : `${process.env.REACT_APP_DO_SPACE_URL}${url?.profileImage}?${new Date().getTime()}`;
};

export const generateSlug = (str) => {
  return str
    ?.toLowerCase()
    ?.trim() // Remove white spaces from start and end
    ?.replace(/\s+/g, " ")
    ?.replace(/[^a-zA-Z0-9 ]/g, "") // Remove special characters
    ?.replace(/\s+/g, "-"); // Replace spaces with -
};
