import AnalyticsTwoToneIcon from "@mui/icons-material/AnalyticsTwoTone";
import AssignmentIndTwoToneIcon from "@mui/icons-material/AssignmentIndTwoTone";
import VpnKeyTwoToneIcon from "@mui/icons-material/VpnKeyTwoTone";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import Payments from "@mui/icons-material/Payments";
import CampaignOutlined from "@mui/icons-material/CampaignOutlined";
import {
  Book,
  Call,
  ConfirmationNumber,
  FlagCircle,
  Storefront,
  Tag,
} from "@mui/icons-material";

const commonItems = {
  heading: "Additionals",
  items: [
    {
      name: "Profile",
      icon: AccountBoxIcon,
      link: "/dashboard/account/profile",
    },
    {
      name: "Change Password",
      icon: VpnKeyTwoToneIcon,
      link: "/dashboard/account/change-password",
    },
  ],
};

const malwareItems = {
  heading: "Malware",
  items: [
    {
      name: "Upload",
      icon: CloudUploadIcon,
      link: "/dashboard/reports/upload",
    },
    {
      name: "Reports",
      icon: AssignmentIndTwoToneIcon,
      link: "/dashboard/reports",
    },
  ],
};

const organisationItems = {
  heading: "Organistion",
  items: [
    {
      name: "Users",
      icon: PeopleAltIcon,
      link: "/dashboard/users",
    },
    {
      name: "Account",
      icon: CorporateFareIcon,
      link: "/dashboard/organisations/account",
    },
  ],
};

export const menuItems = {
  admin: [
    {
      heading: "Dashboard",
      items: [
        {
          name: "Analytics",
          icon: AnalyticsTwoToneIcon,
          link: "/dashboard",
        },
        {
          name: "Users",
          icon: AssignmentIndTwoToneIcon,
          link: "/dashboard/users",
        },
        {
          name: "Appointments",
          icon: PeopleAltIcon,
          link: "/dashboard/appointments",
        },
        {
          name: "Tickets",
          icon: ConfirmationNumber,
          link: "/dashboard/tickets",
        },

        {
          name: "Orders",
          icon: Payments,
          link: "/dashboard/orders",
        },
        {
          name: "Blogs",
          icon: Book,
          link: "/dashboard/blogs",
        },
        {
          name: "Fraud Reports",
          icon: FlagCircle,
          link: "/dashboard/reports",
        },
        {
          name: "Call History",
          icon: Call,
          link: "/dashboard/call-history",
        },
        {
          name: "Cafes",
          icon: Storefront,
          link: "/dashboard/cafes",
        },
        {
          name: "Campaigns",
          icon: CampaignOutlined,
          link: "/dashboard/campaign-notifications",
        },
        {
          name: "Tag Manager",
          icon: Tag,
          link: "/dashboard/tag-manager",
        },
      ],
    },
    commonItems,
  ],
  owner: [
    {
      heading: "",
      items: [
        {
          name: "Dashboard",
          icon: AnalyticsTwoToneIcon,
          link: "/dashboard",
        },
      ],
    },
    malwareItems,
    organisationItems,
    commonItems,
  ],
  moderator: [
    {
      heading: "",
      items: [
        {
          name: "Dashboard",
          icon: AnalyticsTwoToneIcon,
          link: "/dashboard",
        },
      ],
    },
    malwareItems,
    organisationItems,
    commonItems,
  ],
  user: [
    {
      heading: "",
      items: [
        {
          name: "Dashboard",
          icon: AnalyticsTwoToneIcon,
          link: "/dashboard",
        },
      ],
    },
    malwareItems,
    commonItems,
  ],
};
