import React, { useEffect, useState } from "react";
import CafeCard from "./components/CafeCard";
import {
  Box,
  Button,
  IconButton,
  InputBase,
  List,
  Pagination,
  Paper,
  Stack,
} from "@mui/material";
import axiosInstance from "src/utils/axiosInstance";
import SuspenseLoader from "src/components/SuspenseLoader";
import { PAGE_LIMIT } from "src/utils/constants";
import { Link, useSearchParams } from "react-router-dom";
import { Notify } from "notiflix";
import EmptyHere from "src/components/EmptyHere";
import { Add, Search } from "@mui/icons-material";

export default function CopconnectCafes() {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get("page");
  const [cafes, setCafes] = useState([]);
  const [filterObj, setFilterObj] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(parseInt(pageParam) || 1);
  const [searchString, setSearchString] = useState("");

  const handlePageChange = (event, value) => {
    setPage(value);
    setSearchParams({ page: value });
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const reqParams = {
          params: { limit: PAGE_LIMIT, skip: (page - 1) * PAGE_LIMIT },
        };

        if (filterObj) {
          reqParams.params = { ...reqParams.params, ...filterObj };
        }

        const resp = await axiosInstance.get("/cafes", reqParams);
        if (resp?.status === 200) {
          setCafes(resp.data);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        Notify.failure(
          error.response?.data?.message ||
            error.response?.statusText ||
            "an error occured"
        );
      }
    })();
  }, [page, filterObj]);

  return (
    <Box m={3}>
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ my: 3 }}
        >
          <Box fullWidth display={"flex"}>
            <Paper
              component="form"
              sx={{
                display: "flex",
                alignItems: "center",
                width: 500,
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search here..."
                inputProps={{ "aria-label": "search here" }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    if (e.target.value.length) {
                      setFilterObj({ ...filterObj, search: searchString });
                    } else {
                      setFilterObj({ ...filterObj, search: "" });
                    }
                  }
                }}
                onChange={(e) => {
                  if (e.target.value?.length > 0) {
                    setSearchString(e.target.value);
                  } else {
                    if (filterObj?.search) {
                      setFilterObj({
                        ...filterObj,
                        search: "",
                      });
                    }
                  }
                }}
              />
              <IconButton
                type="button"
                sx={{
                  m: 0.2,
                  bgcolor: "primary.main",
                  color: "white",
                }}
                onClick={() => {
                  setFilterObj({
                    ...filterObj,
                    search: searchString,
                  });
                }}
              >
                <Search />
              </IconButton>
            </Paper>
          </Box>
          <Box display={"flex"}>
            <Button
              size="small"
              variant="outlined"
              startIcon={<Add />}
              sx={{
                minWidth: 100,
              }}
              component={Link}
              to={`/dashboard/cafes/new`}
            >
              Create New
            </Button>
            {/* <Autocomplete
              disablePortal
              size="small"
              id="org-box"
              options={[
                { title: "Open", value: "open" },
                { title: "Closed ", value: "closed" },
              ]}
              getOptionLabel={(option) => option?.title}
              sx={{ width: 200, ml: 2 }}
              renderInput={(params) => <TextField {...params} label="Status" />}
              onChange={(e, v) => {
                setFilterObj({
                  ...filterObj,
                  status: v?.value,
                });
              }}
            /> */}
          </Box>
        </Stack>{" "}
        {loading ? (
          <SuspenseLoader />
        ) : cafes?.data?.length > 0 ? (
          <>
            <List disablePadding component={Stack} spacing={2}>
              {cafes?.data?.map((item, idx) => (
                <CafeCard key={idx} item={item} />
              ))}
            </List>
            {!loading && cafes?.data?.length > 0 && (
              <Box sx={{ m: 3, display: "flex", justifyContent: "flex-end" }}>
                <Stack spacing={2}>
                  <Pagination
                    count={cafes?.totalPages}
                    page={page}
                    onChange={handlePageChange}
                    color="primary"
                  />
                </Stack>
              </Box>
            )}
          </>
        ) : (
          <EmptyHere title="No cafes found" />
        )}
      </Box>
    </Box>
  );
}
