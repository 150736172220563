import { Box, Card, Divider, Stack, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { TAGS_STRING, TAGS_TYPE } from "src/utils/constants";
import Specialization from "./Specialization";
import Tags from "./Tags";

export default function TagManager() {
  const [searchParams, setSearchParams] = useSearchParams();
  const typeParam = searchParams.get("type");
  const [selectedTag, setSelectedTag] = useState(
    typeParam || TAGS_STRING.specialization,
  );

  function handleTabChange(event, newValue) {
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      type: newValue,
    });
    setSelectedTag(newValue);
  }

  return (
    <Box m={3}>
      <Stack spacing={3} my={2}>
        <Card>
          <Stack p={1}>
            <Tabs
              onChange={handleTabChange}
              scrollButtons="auto"
              textColor="secondary"
              value={selectedTag}
              variant="scrollable"
            >
              {TAGS_TYPE.map((t) => (
                <Tab key={t.value} value={t.value} label={t.label} />
              ))}
            </Tabs>
          </Stack>
          <Divider />
          {selectedTag === TAGS_STRING.specialization ? (
            <Specialization />
          ) : (
            <Tags type={selectedTag} />
          )}
        </Card>
      </Stack>
    </Box>
  );
}
