import {
  Typography,
  CardContent,
  Card,
  Box,
  Divider,
  Stack,
  styled,
  Grid,
  CardHeader,
  Button,
  capitalize,
  Tooltip,
} from "@mui/material";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import Text from "src/components/Text";
import Label from "src/components/Label";

import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { Fragment, useState } from "react";
import axiosInstance from "src/utils/axiosInstance";
import { Notify } from "notiflix";
import { Link } from "react-router-dom";
import { Check, LocationOn } from "@mui/icons-material";
import { fTime } from "src/utils/date-fns";
import { WORKING_DAYS } from "src/utils/constants";
import ValidationCard from "src/components/Common/ValidationCard";

const StatCard = styled(Card)(
  ({ theme }) => `
    padding-top: 24px;
    padding-bottom: 24px;
    text-align: center;
    font-weight: 700;
    line-height: 1.5;
    font-size: 1.25rem;
    `,
);

const StatWrapper = styled(Box)(
  ({ theme }) => `
    color: ${theme.palette.secondary.main};
    line-height: 1.57143;
    font-size: 0.875rem;
    font-weight: 400;
    `,
);

export default function UserSummarySection({ item }) {
  const { t } = useTranslation();
  const [buttonLoading, setButtonLoading] = useState(false);

  const handleSendVerifyEmail = async () => {
    try {
      setButtonLoading(true);
      const response = await axiosInstance.post("/auth/send-verify-email");
      if (response.status === 200) {
        Notify.success(response.data.message);
        setButtonLoading(false);
      }
    } catch (error) {
      console.log(error);
      setButtonLoading(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured",
      );
    }
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid xs={12} sm={4} item>
          <Stack spacing={2}>
            {["vendor", "responder"]?.includes(item?.accountType) && (
              <StatCard>
                <Stack direction={"row"}>
                  {item?.accountType === "vendor" ? (
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      {item?.appointmentsCount || 0}
                      <StatWrapper>Appointments</StatWrapper>
                    </Stack>
                  ) : (
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      {item?.ticketsCount || 0}
                      <StatWrapper>Tickets</StatWrapper>
                    </Stack>
                  )}
                  <Divider
                    sx={{
                      height: "auto",
                      borderWidth: "0px thin 0px 0px",
                      borderStyle: "dashed",
                    }}
                  />
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    {item?.callsCount || 0}
                    <StatWrapper>Calls Answered</StatWrapper>
                  </Stack>
                </Stack>
              </StatCard>
            )}
            <Card>
              <CardHeader title="About"></CardHeader>
              <Divider />
              <Stack sx={{ p: 2 }} spacing={1}>
                <Box sx={{ mb: 3 }}>{item?.bio || ""}</Box>
                <Stack spacing={1.5}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    Profession
                    <b>{capitalize(item?.profession?.join(", ") || "-")}</b>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    Specialization
                    <b>{capitalize(item?.specialization?.join(", ") || "-")}</b>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    Expertise
                    <b> {capitalize(item?.expertise?.join(", ") || "-")}</b>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    Experience <b> {item?.experience || "-"}</b>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    Languages
                    <b> {capitalize(item?.languages?.join(", ") || "-")}</b>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    Location
                    {item?.address?.latitude && item?.address?.longitude ? (
                      <Button
                        size="small"
                        startIcon={<LocationOn />}
                        component={Link}
                        to={`https://www.google.com/maps?q=${item?.address?.latitude},${item?.address?.longitude}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Check on Map
                      </Button>
                    ) : (
                      <p>{"-"}</p>
                    )}
                  </Box>
                </Stack>
              </Stack>
            </Card>
          </Stack>
        </Grid>
        <Grid xs={12} sm={8} item>
          <Stack spacing={2}>
            <Card>
              <Box
                p={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box>
                  <Typography variant="h4" gutterBottom>
                    {t("Personal Details")}
                  </Typography>
                  <Typography variant="subtitle2">
                    {t("Informations related to personal details")}
                  </Typography>
                </Box>
                <Box></Box>
              </Box>
              <Divider />
              <CardContent sx={{ pb: 4 }}>
                <Stack direction="row">
                  <Typography variant="subtitle2" sx={{ ml: 6, width: "100%" }}>
                    <Stack direction="row" spacing={2} sx={{ m: 2 }}>
                      <Typography>Full Name: </Typography>
                      <Text color="black">
                        <b>
                          {item?.firstName || ""} {item?.lastName || ""}
                        </b>
                      </Text>
                    </Stack>{" "}
                    <Stack direction="row" spacing={2} sx={{ m: 2 }}>
                      <Typography>Email ID: </Typography>
                      <Text color="black">
                        <b>{item?.email || "-"}</b>
                      </Text>
                    </Stack>
                    <Stack direction="row" spacing={2} sx={{ m: 2 }}>
                      <Typography>Phone No: </Typography>
                      <Text color="black">
                        {item?.phoneNumber ? (
                          <Tooltip
                            title={
                              "Phone Number " +
                              (item?.phoneVerified
                                ? "verified"
                                : "not verified")
                            }
                          >
                            <Box>
                              <ValidationCard
                                title={<b>{"+91-" + item?.phoneNumber}</b>}
                                icon={
                                  <Check
                                    color={
                                      item?.phoneVerified ? "success" : "error"
                                    }
                                    fontSize="small"
                                  />
                                }
                              />
                            </Box>
                          </Tooltip>
                        ) : (
                          "-"
                        )}
                      </Text>
                    </Stack>
                    <Stack direction="row" spacing={2} sx={{ m: 2 }}>
                      <Typography>Location: </Typography>
                      <Text color="black">
                        <b>
                          {[
                            item?.address?.city,
                            item?.address?.state,
                            item?.address?.country,
                            item?.address?.pincode,
                          ]
                            .filter(Boolean)
                            .join(", ") || "-"}
                        </b>
                      </Text>
                    </Stack>
                    {item?.address?.address1 && (
                      <Stack direction="row" spacing={2} sx={{ m: 2 }}>
                        <Typography>Address: </Typography>
                        <Text color="black">
                          <b>{item?.address?.address1 || "-"}</b>
                        </Text>
                      </Stack>
                    )}
                    {item?.accountType === "vendor" && (
                      <Fragment>
                        <Stack direction="row" spacing={2} sx={{ m: 2 }}>
                          <Typography>Amount per Session: </Typography>
                          <Text color="black">
                            <b>{item?.amount || "0"} INR</b>
                          </Text>
                        </Stack>
                        <Stack direction="row" spacing={2} sx={{ m: 2 }}>
                          <Typography>Working Days: </Typography>
                          <Text color="black">
                            <b>
                              {item?.workingDays
                                ? WORKING_DAYS.filter((o) =>
                                    item?.workingDays?.includes(o.value),
                                  )
                                    .map((o) => o.title)
                                    .join(", ")
                                : "-"}
                            </b>
                          </Text>
                        </Stack>
                        <Stack direction="row" spacing={2} sx={{ m: 2 }}>
                          <Typography>Work Start At: </Typography>
                          <Text color="black">
                            <b>
                              {item?.workStartAt
                                ? fTime(item?.workStartAt)
                                : "-"}
                            </b>
                          </Text>
                        </Stack>
                        <Stack direction="row" spacing={2} sx={{ m: 2 }}>
                          <Typography>Work End At: </Typography>
                          <Text color="black">
                            <b>
                              {item?.workEndAt ? fTime(item?.workEndAt) : "-"}
                            </b>
                          </Text>
                        </Stack>
                        <Stack direction="row" spacing={2} sx={{ m: 2 }}>
                          <Typography>Experience: </Typography>
                          <Text color="black">
                            <b>
                              {item?.experience
                                ? `${item?.experience} years`
                                : "-"}
                            </b>
                          </Text>
                        </Stack>
                      </Fragment>
                    )}
                  </Typography>
                </Stack>
              </CardContent>
            </Card>

            <Card>
              <Box
                p={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box>
                  <Typography variant="h4" gutterBottom>
                    {t("Account Settings")}
                  </Typography>
                  <Typography variant="subtitle2">
                    {t("Manage details related to your account")}
                  </Typography>
                </Box>
              </Box>
              <Divider />
              <CardContent sx={{ p: 4 }}>
                <Typography variant="subtitle2" sx={{ ml: 6 }}>
                  <Stack direction="row" spacing={2} sx={{ m: 2 }}>
                    <Typography>Language: </Typography>
                    <Text color="black">
                      <b>English (US)</b>
                    </Text>
                  </Stack>
                  <Stack direction="row" spacing={2} sx={{ m: 2 }}>
                    <Typography>Timezone: </Typography>
                    <Text color="black">
                      <b>GMT +2</b>
                    </Text>
                  </Stack>
                  <Stack direction="row" spacing={2} sx={{ m: 2 }}>
                    <Typography>Account status: </Typography>
                    <Label color={item?.isLocked ? "error" : "success"}>
                      <b>{item?.isLocked ? "Deactivated" : "Active"}</b>
                    </Label>
                  </Stack>
                  <Stack direction="row" spacing={2} sx={{ m: 2 }}>
                    <Typography>Email Verification: </Typography>
                    <Label color={!item?.isVerified ? "error" : "success"}>
                      <b>{!item?.isVerified ? "Not Verified" : "Verified"}</b>
                    </Label>{" "}
                    {!item?.isVerified && (
                      <LoadingButton
                        variant="outlined"
                        color="primary"
                        sx={{ ml: 2 }}
                        size="small"
                        startIcon={<ForwardToInboxIcon />}
                        onClick={handleSendVerifyEmail}
                        loading={buttonLoading}
                      >
                        Send Verification Email
                      </LoadingButton>
                    )}
                  </Stack>
                </Typography>
              </CardContent>
            </Card>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
