import {
  AttachFileTwoTone,
  CheckBoxTwoTone,
  DateRangeTwoTone,
  DriveFileRenameOutlineTwoTone,
  ListTwoTone,
  RadioButtonCheckedTwoTone,
  StarBorderPurple500TwoTone,
  TextFieldsTwoTone,
  CheckBoxOutlineBlankOutlined,
} from "@mui/icons-material";

export const PAGE_LIMIT = 10;

export const RuppeeSign = "₹";

export const FORM_TYPE = {
  FRAUD_REPORT: "fraud-report",
};

export const CAMPAIGN_CATEGORIES = [
  { label: "All", value: "all" },
  { label: "Responders", value: "responder" },
  { label: "Specialists", value: "vendor" },
  { label: "Users", value: "user" },
];

export const CAMPAIGN_SPACIALIST_ROLES = [
  { label: "Lawyer", value: "lawyer" },
  { label: "Psychologist", value: "psychologist" },
  { label: "Cyber Security Expert", value: "cyber-security-expert" },
];

export const APPOINTMENT_STATUS = {
  "payment-pending": {
    color: "warning",
    label: "Payment Pending",
  },
  confirmed: {
    color: "primary",
    label: "Confirmed",
  },
  inprogress: {
    color: "info",
    label: "In Progress",
  },
  completed: {
    color: "success",
    label: "Completed",
  },
  cancelled: {
    color: "error",
    label: "Cancelled",
  },
};
export const PUBLIC_PATHS = ["/auth", "/status", "/confirm", "/blogs"];

export const OWNER_RESTRICTED_PATHS = ["/dashboard/organisations"];

export const USER_RESTRICTED_PATHS = [
  "/dashboard/users",
  "/dashboard/organisations",
];

export const EMAIL_STATUS = {
  PENDING: "PENDING",
  SENT: "SENT",
  FAILED: "FAILED",
  "NOT-SENT": "NOT SENT",
};

// call status
export const CALL_STATUS = {
  dialed: {
    color: "warning",
    label: "Dialed",
  },
  missed: {
    color: "secondary",
    label: "Missed",
  },
  answered: {
    color: "success",
    label: "Answered",
  },
  rejected: {
    color: "error",
    label: "Rejected",
  },
};

// enum: ["attempted", "pending", "completed", "failed", "cancelled"],
export const ORDER_STATUS = {
  attempted: {
    color: "secondary",
    label: "Attempted",
  },
  pending: {
    color: "warning",
    label: "Pending",
  },
  completed: {
    color: "success",
    label: "Completed",
  },
  failed: {
    color: "error",
    label: "Failed",
  },
  cancelled: {
    color: "error",
    label: "Cancelled",
  },
};

export const InputTypesObject = {
  text: {
    label: "Text Input",
    value: "text",
    icon: <DriveFileRenameOutlineTwoTone fontSize="sm" />,
  },
  textarea: {
    label: "Textarea",
    value: "textarea",
    icon: <TextFieldsTwoTone fontSize="sm" />,
  },
  select: {
    label: "Select",
    value: "select",
    icon: <ListTwoTone color="" fontSize="medium" />,
  },
  checkbox: {
    label: "Checkbox",
    value: "checkbox",
    icon: <CheckBoxOutlineBlankOutlined fontSize="medium" />,
  },
  radio: {
    label: "Radio",
    value: "radio",
    icon: <RadioButtonCheckedTwoTone fontSize="medium" />,
  },
  file: {
    label: "File",
    value: "file",
    icon: <AttachFileTwoTone fontSize="sm" />,
  },
  date: {
    label: "Date",
    value: "date",
    icon: <DateRangeTwoTone fontSize="sm" />,
  },
};

export const InputTypes = [
  {
    label: "Text Input",
    value: "text",
    icon: DriveFileRenameOutlineTwoTone,
  },
  {
    label: "Textarea",
    value: "textarea",
    icon: TextFieldsTwoTone,
  },
  {
    label: "Select",
    value: "select",
    icon: ListTwoTone,
  },
  {
    label: "Checkbox",
    value: "checkbox",
    icon: CheckBoxTwoTone,
  },
  {
    label: "Radio",
    value: "radio",
    icon: RadioButtonCheckedTwoTone,
  },
  {
    label: "File",
    value: "file",
    icon: AttachFileTwoTone,
  },
  {
    label: "Date",
    value: "date",
    icon: DateRangeTwoTone,
  },
];

export const IMAGE_FORMATS = ["jpg", "jpeg", "png", "gif", "svg"];

export const INDIAN_LANGUAGES = [
  "Assamese",
  "Bengali",
  "Bodo",
  "Dogri",
  "Gujarati",
  "Hindi",
  "Kannada",
  "Kashmiri",
  "Konkani",
  "Maithili",
  "Malayalam",
  "Manipuri",
  "Marathi",
  "Nepali",
  "Odia",
  "Punjabi",
  "Sanskrit",
  "Santali",
  "Sindhi",
  "Tamil",
  "Telugu",
  "Urdu",
  "English",
];

export const WORKING_DAYS = [
  { title: "Monday", value: 1 },
  { title: "Tuesday", value: 2 },
  { title: "Wednesday", value: 3 },
  { title: "Thursday", value: 4 },
  { title: "Friday", value: 5 },
  { title: "Saturday", value: 6 },
  { title: "Sunday", value: 7 },
];

export const TAGS_TYPE = [
  { value: "specialization", label: "Specializations" },
  { value: "blogs", label: "Blogs Categories" },
];

export const TAGS_STRING = {
  specialization: "specialization",
  blogs: "blogs",
};
