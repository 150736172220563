import { Notify } from "notiflix";
import { useState, createContext } from "react";
import { Outlet, useNavigate } from "react-router";
import axiosInstance from "src/utils/axiosInstance";

export const ReportContext = createContext({});

export const ReportProvider = ({ children }) => {
  const navigate = useNavigate();
  const [report, setReport] = useState(null);
  const [reports, setReports] = useState([]);

  const handleGetAllReports = async (reqParams) => {
    try {
      const resp = await axiosInstance.get("/reports", reqParams);
      if (resp.status === 200) {
        setReports(resp.data);
      }
    } catch (error) {
      console.log(error);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  const handleGetReport = async (id) => {
    try {
      const resp = await axiosInstance.get(`/reports/${id}`);
      if (resp.status === 200) {
        setReport(resp.data);
      }
    } catch (error) {
      console.log(error);
      if (error.response?.status === 404) {
        return;
      }
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  const handleUploadReport = async (obj, type) => {
    try {
      let resp = null;
      if (type === "url") {
        resp = await axiosInstance.post("/reports/upload/url", obj);
      } else if (type === "file") {
        resp = await axiosInstance.post("/reports/upload/file", obj);
      } else {
        Notify.failure("Invalid Scan type");
        return null;
      }
      if (resp.status === 200) {
        setReport(resp.data);
        Notify.success("Report uploaded successfully");
        return resp.data;
      }
    } catch (error) {
      console.log(error);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );

      if (error.response?.data?.message === "Please verify your Account") {
        navigate("/dashboard/account/profile");
      }
      return null;
    }
  };

  const handleSubmitForManualReview = async (id, reqObj) => {
    try {
      const confirm = window.confirm(
        "Submit report for Manual Intervention. Our team will review this report and get back to you with a Manual Intervention Report."
      );
      if (confirm) {
        const resp = await axiosInstance.put(`/reports/${id}`, reqObj);
        if (resp?.status === 200) {
          Notify.success("Report submitted for Manual Intervention");
          return true;
        }
        return false;
      }
    } catch (error) {
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
      return false;
    }
  };

  return (
    <ReportContext.Provider
      value={{
        // state
        report,
        reports,

        // methods
        handleUploadReport,
        handleGetAllReports,
        handleGetReport,
        handleSubmitForManualReview,
      }}
    >
      <Outlet />
    </ReportContext.Provider>
  );
};
