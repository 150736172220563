import {
  Autocomplete,
  Box,
  IconButton,
  InputBase,
  LinearProgress,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  capitalize,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import axiosInstance from "src/utils/axiosInstance";
import { CALL_STATUS, PAGE_LIMIT } from "src/utils/constants";
import { Search } from "@mui/icons-material";
import Label from "src/components/Label";
import { fDateTimeSuffix } from "src/utils/date-fns";
import { convertSecondsToMinutes } from "src/utils/helpers";
import CallRecordingPreview from "./components/CallRecordingPreview";
import CallingStats from "./components/CallingStats";

const CallRecordings = ({ userId, whichPage }) => {
  const [calls, setCalls] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParam = searchParams.get("search");
  const pageParam = searchParams.get("page");
  const statusParam = searchParams.get("status");
  const callTypeParam = searchParams.get("type");
  const [filterObj, setFilterObj] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(parseInt(pageParam) || 1);
  const [searchString, setSearchString] = useState(searchParam || null);

  const handlePageChange = (event, value) => {
    setPage(value);
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: value,
    });
  };

  useEffect(() => {
    console.log("filterObj", filterObj);
    if (filterObj) {
      (async () => {
        setLoading(true);
        const reqParams = {
          params: { limit: PAGE_LIMIT, skip: (page - 1) * PAGE_LIMIT },
        };

        if (filterObj) {
          reqParams.params = { ...reqParams.params, ...filterObj };
        }

        if (filterObj?.item === "plan") {
          reqParams.params.type = "plan";
          delete reqParams.params.item;
        }

        if (userId) {
          reqParams.params = { ...reqParams.params, userId };
        }

        const resp = await axiosInstance.get("/calls", reqParams);
        if (resp?.status === 200) {
          setCalls(resp?.data);
        }
        setLoading(false);
      })();
    }
  }, [page, filterObj, userId]);

  useEffect(() => {
    let updatedParams = {};

    if (searchParam) {
      updatedParams = { ...updatedParams, search: searchParam };
    }

    if (statusParam) {
      updatedParams = { ...updatedParams, status: statusParam };
    }

    if (callTypeParam) {
      updatedParams = { ...updatedParams, type: callTypeParam };
    }

    setFilterObj({ ...updatedParams });

    // console.log("search", searchParam);
    // if (searchParam) {
    //   console.log("in search p", searchParam);
    //   setFilterObj({ ...filterObj, search: searchParam });
    // } else {
    //   // delete search from search params
    //   searchParams.delete("search");
    //   setSearchParams(searchParams);
    //   delete filterObj?.search;
    //   setFilterObj({ ...filterObj });
    // }

    // if (statusParam) {
    //   setFilterObj({ ...filterObj, status: statusParam });
    // } else {
    //   // delete status from search params
    //   searchParams.delete("status");
    //   setSearchParams(searchParams);
    //   delete filterObj?.status;
    //   setFilterObj({ ...filterObj });
    // }

    // if (callTypeParam) {
    //   setFilterObj({ ...filterObj, type: callTypeParam });
    // } else {
    //   // delete type from search params
    //   searchParams.delete("type");
    //   setSearchParams(searchParams);
    //   delete filterObj?.type;
    //   setFilterObj({ ...filterObj });
    // }
    // setFilterObj({ ...filterObj, ...updatedParams });
  }, [searchParams]);

  const callTypeFilters = [
    {
      label: "Audio",
      value: "audio",
    },
    {
      label: "Video",
      value: "video",
    },
  ];

  return (
    <Box mx={3}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ pt: 3, pb: 2 }}
      >
        <Paper
          component="form"
          sx={{
            display: "flex",
            alignItems: "center",
            width: 500,
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search here..."
            inputProps={{ "aria-label": "search here" }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                e.preventDefault();
                // if (e.target.value.length) {
                //   setFilterObj({ ...filterObj, search: searchString });
                // } else {
                //   setFilterObj({ ...filterObj, search: null });
                // }
                setPage(1);
                searchParams.set(
                  "search",
                  e.target.value.length ? searchString : null,
                );
                searchParams.delete("page");
                setSearchParams(searchParams);
              }
            }}
            value={searchString}
            onChange={(e) => {
              e.preventDefault();
              setSearchString(e.target.value);
              if (e.target.value?.length === 0) {
                searchParams.delete("search");
                setSearchParams(searchParams);
              }
            }}
          />
          <IconButton
            type="button"
            sx={{
              p: "10px",
              m: 0.2,
              bgcolor: "primary.main",
              color: "white",
            }}
            onClick={() => {
              setPage(1);
              searchParams.set("search", searchString);
              searchParams.delete(page);
              setSearchParams(searchParams);
            }}
          >
            <Search />
          </IconButton>
        </Paper>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Autocomplete
            disablePortal
            size="small"
            id="org-box"
            options={Object.keys(CALL_STATUS) || []}
            getOptionLabel={(option) => capitalize(option || "")}
            sx={{ width: 250, mr: 2 }}
            value={filterObj?.status || ""}
            renderInput={(params) => <TextField {...params} label="Status" />}
            onChange={(e, v) => {
              setFilterObj({ ...filterObj, status: v });
              if (v) {
                searchParams.set("status", v);
                setSearchParams(searchParams);
              } else {
                searchParams.delete("status");
                setSearchParams(searchParams);
              }
              setPage(1);
              searchParams.delete("page");
              setSearchParams(searchParams);
            }}
          />
          <Autocomplete
            disablePortal
            size="small"
            options={callTypeFilters || []}
            getOptionLabel={(option) => option?.label}
            sx={{ width: 250, mr: 2 }}
            value={
              callTypeFilters.find((o) => o.value === filterObj?.type) || null
            }
            renderInput={(params) => (
              <TextField {...params} label="Call Type" />
            )}
            onChange={(e, v) => {
              setFilterObj({ ...filterObj, type: v?.value });
              if (v) {
                searchParams.set("type", v?.value);
                setSearchParams(searchParams);
              } else {
                searchParams.delete("type");
                setSearchParams(searchParams);
              }
              setPage(1);
              searchParams.delete("page");
              setSearchParams(searchParams);
            }}
          />
        </Stack>
      </Stack>
      <Box>
        {whichPage !== "profile" && (
          <Box mb={2}>
            <CallingStats
              status={filterObj?.status}
              handleStatus={(val) => {
                if (filterObj?.status !== val) {
                  setPage(1);
                  searchParams.delete("page");
                  searchParams.set("status", val);
                  setSearchParams(searchParams);
                } else {
                  setPage(1);
                  searchParams.delete("page");
                  searchParams.delete("status");
                  setSearchParams(searchParams);
                }
              }}
              type={filterObj?.type}
              handleType={(val) => {
                if (filterObj?.type !== val) {
                  setPage(1);
                  searchParams.delete("page");
                  searchParams.set("type", val);
                  setSearchParams(searchParams);
                } else {
                  setPage(1);
                  searchParams.delete("page");
                  searchParams.delete("type");
                  setSearchParams(searchParams);
                }
              }}
            />
          </Box>
        )}
        {loading && <LinearProgress />}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Call ID</TableCell>
                <TableCell>Caller</TableCell>
                <TableCell>Receiver</TableCell>
                <TableCell>Call Type</TableCell>
                <TableCell>Duration</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Called At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {calls?.data?.map((row, idx) => (
                <TableRow
                  key={idx}
                  sx={{ "&:last-child td, &:last-child th": { bcall: 0 } }}
                >
                  <TableCell sx={{ maxWidth: "300px" }}>
                    {row?.callId?.toUpperCase() || "-"}
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Link
                        to={`/dashboard/users/${row?.caller?._id}`}
                        style={{ textDecoration: "none" }}
                      >
                        <b>
                          {row?.caller?.firstName || "-"}{" "}
                          {row?.caller?.lastName || ""}
                        </b>
                      </Link>
                      {row?.callerRecording && (
                        <CallRecordingPreview
                          item={{
                            name:
                              row?.caller?.firstName +
                              " " +
                              (row?.caller?.lastName || ""),
                            url: row?.callerRecording,
                            type: row?.type,
                          }}
                        />
                      )}
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Link
                        to={`/dashboard/users/${row?.callee?._id}`}
                        style={{ textDecoration: "none" }}
                      >
                        <b>
                          {row?.callee?.firstName || "-"}{" "}
                          {row?.callee?.lastName || ""}
                        </b>
                      </Link>
                      {row?.calleeRecording && (
                        <CallRecordingPreview
                          item={{
                            name:
                              row?.callee?.firstName +
                              " " +
                              (row?.callee?.lastName || ""),
                            url: row?.calleeRecording,
                            type: row?.type,
                          }}
                        />
                      )}
                    </Stack>
                  </TableCell>
                  <TableCell>{row?.type?.toUpperCase() || "-"}</TableCell>
                  <TableCell>
                    {convertSecondsToMinutes(row?.duration) || "-"}
                  </TableCell>
                  <TableCell>
                    <Label color={CALL_STATUS[row?.status]?.color}>
                      <b>{CALL_STATUS[row?.status]?.label}</b>
                    </Label>
                  </TableCell>
                  <TableCell>{fDateTimeSuffix(row?.createdAt)}</TableCell>
                </TableRow>
              ))}

              {calls?.data?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No Call Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {!loading && calls?.data?.length > 0 && (
            <Box sx={{ m: 3, display: "flex", justifyContent: "flex-end" }}>
              <Stack spacing={2}>
                <Pagination
                  count={calls?.totalPages}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                />
              </Stack>
            </Box>
          )}
        </TableContainer>
      </Box>
    </Box>
  );
};

export default CallRecordings;
