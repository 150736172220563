import { Card, Typography, useTheme } from "@mui/material";
import React from "react";

export default function EmptyHere({ title, subtitle }) {
  const theme = useTheme();
  return (
    <Card
      sx={{
        py: 5,
        px: 2.5,
        flexGrow: 1,
        textAlign: "center",
      }}
    >
      <Typography
        variant="h3"
        sx={{
          color: `${theme.colors.alpha.black[70]}`,
          mb: 2,
        }}
      >
        {title || "It's empty here :("}
      </Typography>
      <Typography
        variant="h4"
        sx={{
          color: `${theme.colors.alpha.black[50]}`,
          mb: 2,
        }}
      >
        {subtitle || "There is no data to display"}
      </Typography>
    </Card>
  );
}
