import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  Stack,
  styled,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Divider,
  Autocomplete,
  capitalize,
  Chip,
} from "@mui/material";
import { Notify } from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { GlobalContext } from "src/contexts/GlobalContext";
import axiosInstance from "src/utils/axiosInstance";
import UploadTwoToneIcon from "@mui/icons-material/UploadTwoTone";
import { Link } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import "dayjs/locale/en-in";
import dayjs from "dayjs";
import { ArrowBackIos } from "@mui/icons-material";
import SuspenseLoader from "src/components/SuspenseLoader";
import CountriesWithStates from "src/utils/countries/states.json";
import CountriesWithCities from "src/utils/countries/cities.json";
import {
  CAMPAIGN_SPACIALIST_ROLES,
  INDIAN_LANGUAGES,
  WORKING_DAYS,
} from "src/utils/constants";

const Input = styled("input")({
  display: "none",
});

const AvatarWrapper = styled(Card)(
  ({ theme }) => `

    position: relative;
    overflow: visible;
    display: inline-block;
    .MuiAvatar-root {
      width: ${theme.spacing(16)};
      height: ${theme.spacing(16)};
    }
`,
);

const ButtonUploadWrapper = styled(Box)(
  ({ theme }) => `
    position: absolute;
    width: ${theme.spacing(4)};
    height: ${theme.spacing(4)};
    bottom: -${theme.spacing(1)};
    right: -${theme.spacing(1)};

    .MuiIconButton-root {
      border-radius: 100%;
      background: ${theme.colors.primary.main};
      color: ${theme.palette.primary.contrastText};
      box-shadow: ${theme.colors.shadows.primary};
      width: ${theme.spacing(4)};
      height: ${theme.spacing(4)};
      padding: 0;
  
      &:hover {
        background: ${theme.colors.primary.dark};
      }
    }
`,
);

const CustomInputWrapper = styled(Box)(
  ({ theme }) => `
    position: relative;
    border-width: 0.5px;
    border-style: solid;
    border-radius: ${theme.spacing(1)};
    border-color: ${theme.palette.grey[400]};
  `,
);

const CustomInputLabel = styled(InputLabel)(
  ({ theme }) => `
    position: absolute;
    font-size: 14px;
    font-weight: 400;
    top: 0;
    left: 8px;
    padding-left: 5px;
    padding-right: 5px;
    transform: translateY(-50%) scale(0.75);
    background: ${theme.palette.background.paper};
  `,
);

export default function AddEditProfile({ page }) {
  const { userId } = useParams();
  const { currentUser } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);
  const [isWait, setIsWait] = useState(false);
  const [formInput, setFormInput] = useState(null);
  const [profileImage, setProfileImage] = useState("");
  const navigate = useNavigate();
  const [googleMapLink, setGoogleMapLink] = useState("");

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let response;
        if (page === "profile") {
          // update current user
          response = await axiosInstance.get("/users/profile");
        } else if (userId) {
          // edit user by id
          response = await axiosInstance.get(`/users/${userId}`);
        } else {
          // add new user
          setLoading(false);
          return setFormInput({});
        }
        if (response.status === 200) {
          setFormInput(response.data);
          setProfileImage(response.data?.profileImage);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
        Notify.failure(
          error.response?.data?.message ||
            error.response?.statusText ||
            "an error occured",
        );
      }
    })();
  }, [userId, page]);

  const handleChangeInput = (event) => {
    const { name, value } = event.target;
    setFormInput({ ...formInput, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (formInput?.accountType === "vendor") {
        if (!formInput?.role || formInput?.role === "responder") {
          return Notify.warning("Please select vendor's specialization");
        }
        if (!formInput?.amount || formInput?.amount <= 0) {
          return Notify.warning("Please enter valid amount per session");
        }
        if (!formInput?.workStartAt || !formInput?.workEndAt) {
          return Notify.warning("Please select working hours");
        }
      }

      setIsWait(true);
      if (page === "profile") {
        const response = await axiosInstance.post("/users/profile", formInput);
        if (response.status === 200) {
          Notify.success("Profile updated successfully");
          // update local storage
          if (localStorage.getItem("user")) {
            const user = JSON.parse(localStorage.getItem("user"));
            user.firstName = formInput?.firstName;
            user.lastName = formInput?.lastName;
            localStorage.setItem("user", JSON.stringify(user));
          }

          setIsWait(false);
        }
      } else if (userId) {
        const response = await axiosInstance.put(`/users/${userId}`, formInput);
        if (response.status === 200) {
          Notify.success("Profile updated successfully");
          setIsWait(false);
        }
      } else {
        const response = await axiosInstance.post(`/auth/create`, formInput);
        if (response.status === 200) {
          setIsWait(false);
          navigate(`/dashboard/users/${response.data._id}`);
        }
        if (response.status === 201) {
          Notify.success("User created successfully");
          setIsWait(false);
          navigate(`/dashboard/users/${response.data._id}`);
        }
      }
    } catch (error) {
      console.log(error);
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured",
      );
    }
  };

  const handleDeactivate = async () => {
    try {
      setIsWait(true);
      const confirm = window.confirm("Are you sure?");
      if (!confirm) {
        setIsWait(false);
        return;
      }
      const response = await axiosInstance.delete(`/users/${userId}`);
      if (response.status === 200) {
        Notify.success(
          formInput?.isLocked
            ? "Profile activated successfully"
            : "Profile deactivated successfully",
        );
        setIsWait(false);
        if (!formInput?.isLocked) {
          navigate("/dashboard/users");
        } else {
          setFormInput({ ...formInput, isLocked: false });
        }
      }
    } catch (error) {
      console.log(error);
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured",
      );
    }
  };

  const handleUploadProfileImage = async (e) => {
    try {
      setIsWait(true);
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("fileCategory", "profileimage");
      formData.append("files", file);
      formData.append("userId", formInput?._id || "");
      const resp = await axiosInstance.post("/users/upload-media", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (resp.status === 200 && resp.data?.length) {
        // update in db
        if (page === "profile") {
          const response = await axiosInstance.post("/users/profile", {
            profileImage: resp.data?.[0],
          });
          if (response.status === 200) {
            setProfileImage(resp.data?.[0]);
            // update in local storage
            if (localStorage.getItem("user")) {
              const user = JSON.parse(localStorage.getItem("user"));
              user.profileImage = resp.data?.[0];
              localStorage.setItem("user", JSON.stringify(user));
            }
            setIsWait(false);
            setFormInput({
              ...formInput,
              profileImage: resp.data?.[0],
            });

            Notify.success("Profile updated successfully");
          }
        } else {
          const response = await axiosInstance.put(`/users/${userId}`, {
            profileImage: resp.data?.[0],
          });
          if (response.status === 200) {
            setProfileImage(resp.data?.[0]);
            setFormInput({
              ...formInput,
              profileImage: resp.data?.[0],
            });
            Notify.success("Profile updated successfully");
            setIsWait(false);
          }
        }
      }
      setIsWait(false);
    } catch (error) {
      setIsWait(false);
      console.log(error);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured",
      );
    }
  };

  return loading ? (
    <SuspenseLoader />
  ) : (
    <>
      <Box sx={{ mx: 4, mt: 2 }}>
        {page !== "profile" && (
          <Link
            to={`/dashboard/users`}
            style={{
              textDecoration: "none",
            }}
          >
            <Button color="info" sx={{ mr: 1 }} startIcon={<ArrowBackIos />}>
              View All Users
            </Button>
          </Link>
        )}
      </Box>
      <Card sx={{ m: 4, p: 3 }}>
        <>
          {(page === "profile" || userId) && (
            <Box sx={{ mb: 4, textAlign: "center" }}>
              <AvatarWrapper>
                {isWait ? (
                  <CircularProgress size={130} />
                ) : (
                  <Avatar
                    variant="rounded"
                    alt={formInput?.firstName}
                    src={
                      profileImage
                        ? `${process.env.REACT_APP_DO_SPACE_URL}${profileImage}?${new Date().getTime()}`
                        : ""
                    }
                  />
                )}
                <ButtonUploadWrapper>
                  <Input
                    accept="image/png, image/jpeg, image/jpg"
                    id="icon-button-file"
                    name="icon-button-file"
                    type="file"
                    onChange={handleUploadProfileImage}
                  />
                  <label htmlFor="icon-button-file">
                    <IconButton component="span" color="primary">
                      <UploadTwoToneIcon />
                    </IconButton>
                  </label>
                </ButtonUploadWrapper>
              </AvatarWrapper>
            </Box>
          )}
          <Stack spacing={{ xs: 2, md: 3 }}>
            <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
              <TextField
                fullWidth
                label="First Name"
                name="firstName"
                value={formInput?.firstName || ""}
                onChange={handleChangeInput}
              />
              <TextField
                fullWidth
                label="Last Name"
                name="lastName"
                value={formInput?.lastName || ""}
                onChange={handleChangeInput}
              />
            </Stack>
            <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
              <TextField
                fullWidth
                disabled={userId}
                label="Email Address"
                value={formInput?.email || ""}
                onChange={(e) => {
                  setFormInput({
                    ...formInput,
                    email: e.target.value,
                  });
                }}
              />
              <TextField
                fullWidth
                label="Phone Number"
                name="phoneNumber"
                value={formInput?.phoneNumber || ""}
                onChange={(e) => {
                  setFormInput({
                    ...formInput,
                    phoneNumber: e.target.value,
                  });
                }}
              />
            </Stack>
            {page !== "profile" && currentUser?.accountType === "admin" && (
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <FormControl fullWidth>
                  <InputLabel>Account Type</InputLabel>
                  <Select
                    fullWidth
                    label="Account Type"
                    name="accountType"
                    value={formInput?.accountType || ""}
                    onChange={(e) => {
                      setFormInput({
                        ...formInput,
                        accountType: e.target.value,
                      });
                    }}
                  >
                    {["responder", "vendor", "user"]?.map((item, idx) => (
                      <MenuItem key={idx} value={item}>
                        {capitalize(item)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {formInput?.accountType === "vendor" && (
                  <Autocomplete
                    fullWidth
                    options={CAMPAIGN_SPACIALIST_ROLES}
                    getOptionLabel={(option) => option.label}
                    value={
                      !formInput?.role
                        ? null
                        : CAMPAIGN_SPACIALIST_ROLES.find(
                            (v) => v.value === formInput?.role,
                          )
                    }
                    onChange={(e, value) => {
                      setFormInput({
                        ...formInput,
                        role: value?.value,
                      });
                    }}
                    renderInput={(params) => (
                      <>
                        <TextField
                          {...params}
                          label="Select Vendor's Specialization"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password", // disable autocomplete and autofill
                          }}
                        />
                      </>
                    )}
                  />
                )}
              </Stack>
            )}
            <Divider>Professional Details</Divider>
            <Autocomplete
              fullWidth
              autoHighlight
              multiple
              options={INDIAN_LANGUAGES}
              value={formInput?.languages || []}
              onChange={(e, value) => {
                setFormInput({
                  ...formInput,
                  languages: value,
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Language(s)"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />{" "}
            {formInput?.accountType === "vendor" && (
              <>
                <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                  <TextField
                    fullWidth
                    label="Profession(s)"
                    placeholder="Enter your profession (e.g. Doctor, Lawyer, etc.) separated by comma if multiple"
                    value={formInput?.profession?.join(",") || ""}
                    onChange={(e) => {
                      setFormInput({
                        ...formInput,
                        profession: e.target.value.split(","),
                      });
                    }}
                  />
                  <TextField
                    fullWidth
                    label="Specialization"
                    placeholder="Enter your specialization (e.g. Cardiologist, Criminal Lawyer, etc.) separated by comma if multiple"
                    value={formInput?.specialization?.join(",") || ""}
                    onChange={(e) => {
                      setFormInput({
                        ...formInput,
                        specialization: e.target.value.split(","),
                      });
                    }}
                  />
                  <TextField
                    fullWidth
                    label="Expertise"
                    placeholder="Enter your expertise (e.g. Heart Surgery, Criminal Defense, etc.) separated by comma if multiple"
                    value={formInput?.expertise?.join(",") || ""}
                    onChange={(e) => {
                      setFormInput({
                        ...formInput,
                        expertise: e.target.value.split(","),
                      });
                    }}
                  />
                  <TextField
                    fullWidth
                    label="Experience"
                    placeholder="Enter your experience in years"
                    value={formInput?.experience || ""}
                    onChange={(e) => {
                      setFormInput({
                        ...formInput,
                        experience: e.target.value,
                      });
                    }}
                  />
                </Stack>
                <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                  <TextField
                    fullWidth
                    label="Amount per session (in INR)"
                    value={formInput?.amount || ""}
                    onChange={(e) => {
                      setFormInput({
                        ...formInput,
                        amount: e.target.value,
                      });
                    }}
                  />
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    locale="en-in"
                  >
                    <TimePicker
                      label="Start Time"
                      placeholder="Select your start time of work"
                      sx={{ width: "100%" }}
                      value={dayjs(
                        formInput?.workStartAt
                          ? new Date(formInput?.workStartAt)
                          : new Date(),
                      )}
                      onChange={(newValue) => {
                        setFormInput({
                          ...formInput,
                          workStartAt: newValue,
                        });
                      }}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    locale="en-in"
                  >
                    <TimePicker
                      label="End Time"
                      placeholder="Select your end time of work"
                      sx={{ width: "100%" }}
                      value={dayjs(
                        formInput?.workEndAt
                          ? new Date(formInput?.workEndAt)
                          : new Date(),
                      )}
                      onChange={(newValue) => {
                        setFormInput({
                          ...formInput,
                          workEndAt: newValue,
                        });
                      }}
                    />
                  </LocalizationProvider>
                </Stack>
                <Stack>
                  <CustomInputWrapper>
                    <CustomInputLabel>Select Working Days</CustomInputLabel>
                    <Box sx={{ padding: 2 }}>
                      {WORKING_DAYS.map((each) => {
                        let existDay = formInput?.workingDays?.includes(
                          each?.value,
                        );
                        return (
                          <Chip
                            sx={{ mr: 1 }}
                            label={each.title}
                            color={existDay ? "primary" : "default"}
                            variant={existDay ? "filled" : "outlined"}
                            onClick={() => {
                              setFormInput({
                                ...formInput,
                                workingDays: existDay
                                  ? formInput?.workingDays?.filter(
                                      (v) => v !== each.value,
                                    )
                                  : formInput?.workingDays
                                    ? [...formInput?.workingDays, each.value]
                                    : [each.value],
                              });
                            }}
                          />
                        );
                      })}
                    </Box>
                  </CustomInputWrapper>
                </Stack>
              </>
            )}
            <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
              <TextField
                fullWidth
                placeholder="Write a short bio about yourself"
                name="address"
                value={formInput?.bio || ""}
                onChange={(e) => {
                  setFormInput({
                    ...formInput,
                    bio: e.target.value,
                  });
                }}
                multiline
                rows={3}
              />
            </Stack>
            <Divider>Address Details</Divider>
            {currentUser?.accountType === "admin" && (
              <Stack mt={4} spacing={2}>
                <TextField
                  fullWidth
                  placeholder={"Paste google map link here..."}
                  variant="outlined"
                  value={googleMapLink || ""}
                  onChange={(e) => {
                    setGoogleMapLink(e.target.value);
                  }}
                  onBlur={(e) => {
                    const url = e.target.value;
                    const coordinates = url.match(/@([-0-9.]+),([-0-9.]+)/);

                    if (coordinates && coordinates.length >= 3) {
                      const latitude = coordinates?.[1]; // Latitude
                      const longitude = coordinates?.[2]; // Longitude

                      setFormInput({
                        ...formInput,
                        address: {
                          ...(formInput?.address || {}),
                          latitude: latitude,
                          longitude: longitude,
                        },
                      });
                    } else {
                      Notify.failure(
                        "Invalid google map link. Latitude and Longitude not found in the link.",
                      );
                    }
                  }}
                />
                <Divider>OR</Divider>
                <Stack direction={"row"} spacing={2} alignItems={"center"}>
                  <TextField
                    fullWidth
                    label="Enter Latitude"
                    placeholder={"Enter Latitude"}
                    variant="outlined"
                    type="number"
                    value={formInput?.address?.latitude || ""}
                    onChange={(e) => {
                      setFormInput({
                        ...formInput,
                        address: {
                          ...(formInput?.address || {}),
                          latitude: e.target.value,
                        },
                      });
                    }}
                  />
                  <TextField
                    fullWidth
                    label="Enter Longitude"
                    placeholder={"Enter Longitude"}
                    variant="outlined"
                    type="number"
                    value={formInput?.address?.longitude || ""}
                    onChange={(e) => {
                      setFormInput({
                        ...formInput,
                        address: {
                          ...(formInput?.address || {}),
                          longitude: e.target.value,
                        },
                      });
                    }}
                  />
                </Stack>
              </Stack>
            )}
            <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
              <Autocomplete
                fullWidth
                autoHighlight
                options={
                  CountriesWithStates?.find(
                    (item) =>
                      item?.name === (formInput?.address?.country || "India"),
                  )?.states?.map((item) => item?.name) || []
                }
                value={formInput?.address?.state || ""}
                onChange={(e, value) => {
                  setFormInput({
                    ...formInput,
                    address: {
                      ...formInput.address,
                      state: value,
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select State"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
              <Autocomplete
                fullWidth
                autoHighlight
                options={
                  CountriesWithCities?.find(
                    (item) =>
                      item?.country ===
                      (formInput?.address?.country || "India"),
                  )?.cities || []
                }
                value={formInput?.address?.city || ""}
                onChange={(e, value) => {
                  setFormInput({
                    ...formInput,
                    address: {
                      ...formInput.address,
                      city: value,
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select City"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
              <TextField
                fullWidth
                label="Pincode"
                name="pincode"
                placeholder="Enter Pincode/Zipcode"
                value={formInput?.address?.pincode || ""}
                onChange={(e) => {
                  setFormInput({
                    ...formInput,
                    address: {
                      ...formInput.address,
                      pincode: e.target.value,
                    },
                  });
                }}
              />
            </Stack>
            <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
              <TextField
                fullWidth
                label="Complete Address"
                name="address"
                value={formInput?.meta?.address || ""}
                onChange={(e) => {
                  setFormInput({
                    ...formInput,
                    meta: { ...formInput?.meta, address: e.target.value },
                  });
                }}
                multiline
                rows={3}
              />
            </Stack>
          </Stack>

          <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
            <Box>
              {formInput?._id &&
                currentUser?.role !== "user" &&
                page !== "profile" && (
                  <LoadingButton
                    variant={formInput?.isLocked ? "contained" : "outlined"}
                    color={!formInput?.isLocked ? "error" : "success"}
                    sx={{ mr: 2 }}
                    loading={isWait}
                    onClick={handleDeactivate}
                  >
                    {!formInput?.isLocked ? "Deactivate" : "Activate"} Account
                  </LoadingButton>
                )}
            </Box>
            <Box>
              {" "}
              {formInput?._id && (
                <Link
                  to={
                    page === "profile"
                      ? `/dashboard/account/profile`
                      : `/dashboard/users/${userId}`
                  }
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <Button variant="contained" color="info" sx={{ mr: 1 }}>
                    View Profile
                  </Button>
                </Link>
              )}
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isWait}
                onClick={handleSubmit}
                disabled={formInput?.isLocked}
              >
                Save Changes
              </LoadingButton>
            </Box>
          </Box>
        </>
      </Card>
    </>
  );
}
