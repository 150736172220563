import {
  Box,
  Card,
  styled,
  useTheme,
  Typography,
  Stack,
  alpha,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import Text from "src/components/Text";
import { PaymentsTwoTone } from "@mui/icons-material";
import { RuppeeSign } from "src/utils/constants";

const DividerVertial = styled(Box)(
  ({ theme }) => `
    height: 60%;
    width: 6px;
    left: -3px;
    border-radius: 50px;
    position: absolute;
    top: 20%;
  `,
);

const Label = styled(Box)(
  ({ theme }) => `
      font-weight: bold;
      border-radius: ${theme.general.borderRadiusSm};
      text-transform: uppercase;
      font-size: ${theme.typography.pxToRem(10)};
      padding: ${theme.spacing(0.5, 1.5)};
  `,
);

function StatCard1({ item, sx, onClick = () => {} }) {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Card
      sx={{
        overflow: "visible",
        position: "relative",
        p: 2,
        height: "100%",
        "&:hover": {
          background: alpha(theme.colors[item.color].main, 0.1),
        },
        ...sx,
      }}
      variant="outlined"
      onClick={onClick}
    >
      <DividerVertial
        sx={{
          background: theme.colors[item.color].main,
        }}
      />
      <Typography color="text.primary" variant="h3" fontWeight="bold">
        {item?.count}
      </Typography>
      <Stack mt={1} direction={"row"} alignItems={"center"} spacing={2}>
        <Label
          sx={{
            background: theme.colors[item.color].main,
            color: theme.palette[item.color].contrastText,
          }}
        >
          {t(item.label)}
        </Label>
        <Text flex ml={1}>
          <PaymentsTwoTone
            sx={{
              ml: 1,
              mr: 0.5,
            }}
            fontSize="small"
          />
          <b>
            {" "}
            {RuppeeSign} {item?.value}
          </b>
        </Text>
      </Stack>
    </Card>
  );
}

export default StatCard1;
