import {
  Box,
  IconButton,
  LinearProgress,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Autocomplete,
  InputBase,
  Stack,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import axiosInstance from "src/utils/axiosInstance";
import { APPOINTMENT_STATUS, PAGE_LIMIT } from "src/utils/constants";
import { ArrowForwardIos, Search } from "@mui/icons-material";
import Label from "src/components/Label";
import { GlobalContext } from "src/contexts/GlobalContext";

import { fDateTimeSuffix } from "src/utils/date-fns";
import AppointmentStats from "./AppointmentStats";

export default function AppointmentList({ userId, whichPage }) {
  const statusOptions =
    Object.entries(APPOINTMENT_STATUS).map(([key, value]) => ({
      title: value.label,
      value: key,
    })) || [];

  const [appointments, setAppointments] = useState([]);
  const { currentUser } = useContext(GlobalContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get("page");
  const statusParam = searchParams.get("status");
  const itemTypeParam = searchParams.get("item");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(parseInt(pageParam) || 1);
  const [organisations, setOrganisations] = useState([]);
  const [filterObj, setFilterObj] = useState({
    search: searchParams.get("search") || null,
    status: statusParam || null,
  });
  const [searchString, setSearchString] = useState(
    searchParams.get("search") || null,
  );

  const handlePageChange = (event, value) => {
    setPage(value);
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: value,
    });
  };

  useEffect(() => {
    if (statusParam) {
    } else {
      // delete status from search params
      searchParams.delete("status");
      setSearchParams(searchParams);
    }

    if (itemTypeParam) {
    } else {
      // delete item from search params
      searchParams.delete("item");
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  useEffect(() => {
    (async () => {
      if (currentUser?.accountType !== "admin") return;
      const resp = await axiosInstance.get(`/organisations/all`, {
        params: {
          isClaimed: true,
        },
      });
      if (resp?.status === 200) {
        setOrganisations(resp?.data);
      }
    })();
  }, [currentUser]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const reqParams = {
        params: { limit: PAGE_LIMIT, skip: (page - 1) * PAGE_LIMIT },
      };

      if (filterObj) {
        reqParams.params = { ...reqParams.params, ...filterObj };
        if (!filterObj.search) {
          let { search, ...rest } = reqParams.params;
          reqParams.params = rest;
        }
      }

      if (userId) {
        reqParams.params = { ...reqParams.params, userId };
      }

      const resp = await axiosInstance.get("/appointments", reqParams);
      if (resp?.status === 200) {
        setAppointments(resp?.data);
      }
      setLoading(false);
    })();
  }, [page, filterObj, userId]);

  return (
    <Box m={3}>
      <Box sx={{ my: 2 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ my: 3 }}
        >
          <Box>
            <Box fullWidth display={"flex"}>
              <Paper
                component="form"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: 500,
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search here..."
                  inputProps={{ "aria-label": "search here" }}
                  value={searchString}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                      let _search;
                      if (e.target.value.length) {
                        _search = searchString;
                        searchParams.set("search", searchString);
                      } else {
                        searchParams.delete("search");
                        _search = null;
                      }
                      setPage(1);
                      setFilterObj({ ...filterObj, search: _search });
                      searchParams.delete("page");
                      setSearchParams(searchParams);
                    }
                  }}
                  onChange={(e) => {
                    if (e.target.value?.length > 0) {
                      setSearchString(e.target.value);
                    } else {
                      if (filterObj?.search) {
                        setFilterObj({
                          ...filterObj,
                          search: "",
                        });
                        searchParams.delete("search");
                        setSearchParams(searchParams);
                        setSearchString(e.target.value);
                      }
                    }
                  }}
                />
                <IconButton
                  type="button"
                  sx={{
                    m: 0.2,
                    bgcolor: "primary.main",
                    color: "white",
                  }}
                  onClick={() => {
                    setFilterObj({
                      ...filterObj,
                      search: searchString,
                    });
                    setPage(1);
                    searchParams.delete("page");
                    searchParams.set("search", searchString);
                    setSearchParams(searchParams);
                  }}
                >
                  <Search />
                </IconButton>
              </Paper>
            </Box>
          </Box>
          <Box display={"flex"}>
            <Autocomplete
              disablePortal
              size="small"
              id="org-box"
              options={statusOptions}
              getOptionLabel={(option) => option?.title}
              sx={{ width: 200, ml: 2 }}
              renderInput={(params) => <TextField {...params} label="Status" />}
              value={
                searchParams.has("status")
                  ? statusOptions.filter(
                      (e) => e.value === searchParams.get("status"),
                    )[0]
                  : null
              }
              onChange={(e, v) => {
                setFilterObj({
                  ...filterObj,
                  status: v?.value,
                });
                setPage(1);
                searchParams.delete("page");
                if (Object.keys(APPOINTMENT_STATUS).includes(v?.value)) {
                  searchParams.set("status", v?.value);
                } else {
                  searchParams.delete("status");
                }
                setSearchParams(searchParams);
              }}
            />
          </Box>
        </Stack>{" "}
        {whichPage !== "profile" && (
          <Stack mb={2}>
            <AppointmentStats
              status={filterObj?.status || null}
              handleClick={(val) => {
                if (filterObj?.status === val) {
                  setFilterObj({
                    ...filterObj,
                    status: null,
                  });
                  setPage(1);
                  searchParams.delete("status");
                  searchParams.delete("page");
                  setSearchParams(searchParams);
                } else {
                  setFilterObj({
                    ...filterObj,
                    status: val,
                  });
                  setPage(1);
                  if (Object.keys(APPOINTMENT_STATUS).includes(val)) {
                    searchParams.set("status", val);
                  } else {
                    searchParams.delete("status");
                  }
                  searchParams.delete("page");
                  setSearchParams(searchParams);
                }
              }}
            />
          </Stack>
        )}
        {loading && <LinearProgress />}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Appointment ID</TableCell>
                <TableCell>
                  Customer
                  {/* <Typography color="text.secondary">
                    <small>Organisation</small>
                  </Typography> */}
                </TableCell>
                <TableCell>Vendor</TableCell>
                <TableCell>Amount Paid </TableCell>
                {/* <TableCell>
                  Quantity
                  <Tooltip title="Number of submissions allowed">
                    <InfoTwoTone sx={{ mx: 1 }} fontSize="0.7rem" />
                  </Tooltip>
                </TableCell> */}
                <TableCell>Duration</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Appointment Date</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {appointments?.data?.map((row, idx) => (
                <TableRow
                  key={idx}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell sx={{ maxWidth: "300px" }}>
                    <b>{row?.appointmentId?.toUpperCase() || "-"}</b>
                  </TableCell>
                  <TableCell>
                    <Link
                      to={`/dashboard/users/${row?.user?._id}`}
                      style={{ textDecoration: "none" }}
                    >
                      {row?.user?.firstName || "-"} {row?.user?.lastName || ""}
                    </Link>
                    {/* <Typography color="text.secondary">
                      <small>{row?.organisation?.title || "-"}</small>
                    </Typography> */}
                  </TableCell>
                  <TableCell>
                    {" "}
                    <Link
                      to={`/dashboard/users/${row?.vendor?._id}`}
                      style={{ textDecoration: "none" }}
                    >
                      {row?.vendor?.firstName || "-"}{" "}
                      {row?.vendor?.lastName || ""}
                    </Link>
                  </TableCell>
                  <TableCell whiteSpace={"nowrap"}>
                    {/* {row?.amount && ( */}
                    <b>Rs. {row?.amount || "0"}</b>
                    {/* )} */}
                  </TableCell>
                  {/* <TableCell>
                    <b>{row?.quantity || "-"}</b>
                  </TableCell> */}
                  <TableCell>{Math.floor(row?.duration / 60)} mins</TableCell>
                  <TableCell>
                    <Label color={APPOINTMENT_STATUS[row?.status]?.color}>
                      <b>{APPOINTMENT_STATUS[row?.status]?.label}</b>
                    </Label>
                  </TableCell>
                  <TableCell>
                    {row?.startAt ? fDateTimeSuffix(row?.startAt) : "-"}
                  </TableCell>
                  <TableCell align="right">
                    <Link
                      to={`/dashboard/appointments/${row?._id}`}
                      style={{
                        textDecoration: "none",
                      }}
                    >
                      <IconButton
                        variant="outlined"
                        color="primary"
                        size="small"
                        sx={{ mr: 1 }}
                      >
                        <ArrowForwardIos />
                      </IconButton>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}

              {appointments?.data?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No Appointment Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {!loading && appointments?.data?.length > 0 && (
            <Box sx={{ m: 3, display: "flex", justifyContent: "flex-end" }}>
              <Stack spacing={2}>
                <Pagination
                  count={appointments?.totalPages}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                />
              </Stack>
            </Box>
          )}
        </TableContainer>
      </Box>
    </Box>
  );
}
