import {
  Avatar,
  Box,
  Card,
  Chip,
  Stack,
  Typography,
  capitalize,
  styled,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const CardActions = styled(Box)(
  ({ theme }) => `
      position: absolute;
      right: ${theme.spacing(1)};
      top: ${theme.spacing(1)};
      z-index: 7;
    `
);

export default function ProfileShortPreview({ data, action }) {
  return (
    <>
      <Card
        sx={{
          position: "relative",
          p: 1,
        }}
      >
        <CardActions>{action || <></>}</CardActions>
        <Box mb={2} display="flex" alignItems="center">
          <Avatar
            variant="rounded"
            sx={{
              width: 70,
              height: 70,
            }}
            src={
              data?.profileImage
                ? process.env.REACT_APP_DO_SPACE_URL + data?.profileImage
                : ""
            }
          />
          <Box
            sx={{
              width: "100%",
            }}
            ml={1.5}
          >
            <Link
              to={"/dashboard/users/" + data?._id}
              style={{ textDecoration: "none" }}
            >
              <Typography variant="h4" component="h4" gutterBottom>
                {data?.firstName || ""} {data?.lastName || ""}
              </Typography>
            </Link>

            <Stack direction="row" spacing={1}>
              <Typography gutterBottom variant="subtitle2">
                {data?.email || ""}
              </Typography>
              {data?.phoneNumber && (
                <>
                  <Typography gutterBottom variant="subtitle2">
                    •
                  </Typography>
                  <Typography gutterBottom variant="subtitle2">
                    {data?.phoneNumber || ""}
                  </Typography>
                </>
              )}
            </Stack>
            {["vendor", "responder"]?.includes(data?.accountType) && (
              <Chip
                label={capitalize(data?.accountType || "")}
                color="primary"
                size="small"
                variant="outlined"
                sx={{ mt: 0.4 }}
              />
            )}
          </Box>
        </Box>
      </Card>
    </>
  );
}
