import { Avatar, CardHeader } from "@mui/material";
import React from "react";

export default function UserCard({ avatar, title, subtitle }) {
  return (
    <CardHeader
      avatar={
        <Avatar
          sx={{
            width: 30,
            height: 30,
          }}
          variant="circular"
          alt={title}
          src={avatar}
        />
      }
      title={title}
      subheader={subtitle}
      sx={{
        py: 0,
        pl: 0,
      }}
      titleTypographyProps={{
        variant: "h6",
      }}
    />
  );
}
