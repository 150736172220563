import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  IconButton,
  InputBase,
  Pagination,
  Paper,
  Stack,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  capitalize,
  LinearProgress,
} from "@mui/material";
import axiosInstance from "src/utils/axiosInstance";
import { PAGE_LIMIT } from "src/utils/constants";
import { Link, useSearchParams } from "react-router-dom";
import { Notify } from "notiflix";
import EmptyHere from "src/components/EmptyHere";
import { ArrowForwardIos, Search } from "@mui/icons-material";
import { fDateTimeSuffix } from "src/utils/date-fns";
import TicketStats from "./components/TicketStats";

export default function Tickets({ userId, whichPage }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get("page");
  const statusParam = searchParams.get("status");
  const [tickets, setTickets] = useState([]);
  const [filterObj, setFilterObj] = useState({
    search: searchParams.get("search") || null,
    status: statusParam || null,
  });
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(parseInt(pageParam) || 1);
  const [searchString, setSearchString] = useState("");

  const handlePageChange = (event, value) => {
    setPage(value);
    searchParams.set("page", value);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const reqParams = {
          params: { limit: PAGE_LIMIT, skip: (page - 1) * PAGE_LIMIT },
        };

        if (filterObj) {
          reqParams.params = { ...reqParams.params, ...filterObj };
          if (!filterObj.search) {
            let { search, ...rest } = reqParams.params;
            reqParams.params = rest;
          }
        }

        if (userId) {
          reqParams.params = { ...reqParams.params, userId };
        }

        const resp = await axiosInstance.get("/tickets", reqParams);
        if (resp?.status === 200) {
          setTickets(resp.data);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        Notify.failure(
          error.response?.data?.message ||
            error.response?.statusText ||
            "an error occured",
        );
      }
    })();
  }, [page, filterObj, userId]);

  return (
    <Box m={3}>
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ my: 3 }}
        >
          <Box>
            <Box fullWidth display={"flex"}>
              <Paper
                component="form"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: 500,
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search here..."
                  inputProps={{ "aria-label": "search here" }}
                  value={searchParams.get("search")}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                      let _search;
                      if (e.target.value.length) {
                        _search = searchString;
                        searchParams.set("search", searchString);
                      } else {
                        searchParams.delete("search");
                        _search = null;
                      }
                      setPage(1);
                      setFilterObj({ ...filterObj, search: _search });
                      searchParams.delete("page");
                      setSearchParams(searchParams);
                    }
                  }}
                  onChange={(e) => {
                    if (e.target.value?.length > 0) {
                      setSearchString(e.target.value);
                      searchParams.set("search", e.target.value);
                    } else {
                      if (filterObj?.search) {
                        setFilterObj({
                          ...filterObj,
                          search: null,
                        });
                        searchParams.delete("search");
                      }
                    }
                    setSearchParams(searchParams);
                  }}
                />
                <IconButton
                  type="button"
                  sx={{
                    m: 0.2,
                    bgcolor: "primary.main",
                    color: "white",
                  }}
                  onClick={() => {
                    setFilterObj({
                      ...filterObj,
                      search: searchString,
                    });
                  }}
                >
                  <Search />
                </IconButton>
              </Paper>
            </Box>
          </Box>
          <Box display={"flex"}>
            <Autocomplete
              disablePortal
              size="small"
              id="org-box"
              options={[
                { title: "Open", value: "open" },
                { title: "Closed", value: "closed" },
              ]}
              getOptionLabel={(option) => option?.title}
              sx={{ width: 200, ml: 2 }}
              renderInput={(params) => <TextField {...params} label="Status" />}
              value={
                searchParams.has("status")
                  ? [
                      { title: "Open", value: "open" },
                      { title: "Closed", value: "closed" },
                    ].filter((e) => e.value === searchParams.get("status"))[0]
                  : null
              }
              onChange={(e, v) => {
                setFilterObj({
                  ...filterObj,
                  status: v?.value,
                });
                setPage(1);
                searchParams.delete("page");
                if (["open", "closed"].includes(v?.value)) {
                  searchParams.set("status", v?.value);
                } else {
                  searchParams.delete("status");
                }
                setSearchParams(searchParams);
              }}
            />
          </Box>
        </Stack>{" "}
        {whichPage !== "profile" && (
          <Stack mb={2}>
            <TicketStats
              status={filterObj?.status || null}
              handleClick={(val) => {
                if (filterObj?.status === val) {
                  setFilterObj({
                    ...filterObj,
                    status: null,
                  });
                  setPage(1);
                  searchParams.delete("page");
                  searchParams.delete("status");
                  setSearchParams(searchParams);
                } else {
                  setFilterObj({
                    ...filterObj,
                    status: val,
                  });
                  setPage(1);
                  searchParams.delete("page");
                  if (["open", "closed"].includes(val)) {
                    searchParams.set("status", val);
                  } else {
                    searchParams.delete("status");
                  }
                  setSearchParams(searchParams);
                }
              }}
            />
          </Stack>
        )}
        {loading && <LinearProgress />}
        {tickets?.data?.length > 0 ? (
          <>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Ticket Id</TableCell>
                    <TableCell>Customer</TableCell>
                    <TableCell>Responder</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell align="right">Date</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tickets?.data?.map((item, idx) => (
                    <TableRow key={idx}>
                      <TableCell>{item?.ticketId}</TableCell>
                      <TableCell>
                        <Link
                          to={`/dashboard/users/${item?.client?._id}`}
                          style={{ textDecoration: "none" }}
                        >
                          {item?.client?.firstName || ""}{" "}
                          {item?.client?.lastName || ""}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Link
                          to={`/dashboard/users/${item?.assignedTo?._id}`}
                          style={{ textDecoration: "none" }}
                        >
                          {item?.assignedTo?.firstName || "-"}{" "}
                          {item?.assignedTo?.lastName || ""}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Chip
                          color={
                            item?.status === "closed" ? "success" : "warning"
                          }
                          label={<b>{capitalize(item?.status || "closed")}</b>}
                          size="small"
                        />
                      </TableCell>{" "}
                      <TableCell align="right">
                        {fDateTimeSuffix(item?.createdAt) || ""}
                      </TableCell>
                      <TableCell align="right">
                        <Link
                          to={`/dashboard/tickets/${item?._id}`}
                          style={{
                            textDecoration: "none",
                          }}
                        >
                          <IconButton
                            variant="outlined"
                            color="primary"
                            size="small"
                            sx={{ mr: 1 }}
                          >
                            <ArrowForwardIos />
                          </IconButton>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {!loading && tickets?.data?.length > 0 && (
              <Box sx={{ m: 3, display: "flex", justifyContent: "flex-end" }}>
                <Stack spacing={2}>
                  <Pagination
                    count={tickets?.totalPages}
                    page={page}
                    onChange={handlePageChange}
                    color="primary"
                  />
                </Stack>
              </Box>
            )}
          </>
        ) : (
          <EmptyHere title="No tickets found" />
        )}
      </Box>
    </Box>
  );
}
