import { PublishedWithChanges } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { Confirm, Notify } from "notiflix";
import React, { useState } from "react";
import axiosInstance from "src/utils/axiosInstance";

export default function ClosingRemarkDialog({
  reportId,
  reportData,
  setReportData,
}) {
  const [open, setOpen] = useState(false);
  const [closingRemark, setClosingRemark] = useState("");

  const handleMarkAsClosed = async () => {
    try {
      const resp = await axiosInstance.put(`/reports/${reportId}`, {
        status: "closed",
        closingRemark: closingRemark || "",
      });
      if (resp.status === 200) {
        setReportData({
          ...reportData,
          status: "closed",
          closingRemark: closingRemark || "",
        });
      }
    } catch (error) {
      console.log(error);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  return (
    <div>
      <Tooltip title="Mark as closed">
        <IconButton onClick={() => setOpen(true)}>
          <PublishedWithChanges />
        </IconButton>
      </Tooltip>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setClosingRemark("");
        }}
      >
        <DialogTitle>Enter Closing Remark</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the closing remark for this report to let the user know
            why this report has been closed.
          </DialogContentText>
          <TextField
            autoFocus
            fullWidth
            placeholder="Enter closing remark here..."
            value={closingRemark}
            onChange={(e) => setClosingRemark(e.target.value)}
            multiline
            rows={4}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setClosingRemark("");
              setOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (!closingRemark) {
                Notify.failure("Closing remark is required");
                return;
              }
              Confirm.show(
                "Confirmation",
                "Are you sure you want to mark this report as closed?",
                "Confirm",
                "Cancel",
                () => {
                  handleMarkAsClosed();
                },

                () => {},
                {
                  width: "500px",
                  messageMaxLength: 1000,
                }
              );
            }}
            variant="contained"
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
