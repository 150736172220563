import { EditTwoTone } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Typography,
} from "@mui/material";

const SpecializationCard = ({ details, handleEdit }) => {
  return (
    <Card>
      <CardContent>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Box display={"flex"} alignItems={"center"}>
            <Box
              sx={{
                height: 48,
                width: 48,
              }}
            >
              <img
                variant="rounded"
                style={{
                  objectFit: "contain",
                  background: "#444",
                  height: "100%",
                  width: "100%",
                  borderRadius: 4,
                  padding: 2,
                }}
                src={process.env.REACT_APP_DO_SPACE_URL + details?.icon}
              />
            </Box>
            <Typography sx={{ ml: 2 }} variant="h5">
              {details?.name}
            </Typography>
          </Box>
          <Button
            variant="outlined"
            startIcon={<EditTwoTone />}
            title="Click here to edit this specialization"
            onClick={() => handleEdit(details)}
          >
            Edit
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default SpecializationCard;
