import { LoadingButton } from "@mui/lab";
import {
  Chip,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Confirm, Notify } from "notiflix";
import React, { useEffect, useState } from "react";
import SuspenseLoader from "src/components/SuspenseLoader";
import axiosInstance from "src/utils/axiosInstance";
import { generateSlug } from "src/utils/helpers";

export default function Tags({ type }) {
  const [newTagInput, setNewTagInput] = useState("");
  const [allTags, setAllTags] = useState({});
  const [loading, setLoading] = useState(true);
  const [isWait, setIsWait] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const resp = await axiosInstance.get("/tag-manager/tags", {
        params: {
          type,
        },
      });
      if (resp?.status === 200) {
        setAllTags(resp?.data?.data?.tags || []);
      }
      setLoading(false);
    })();
  }, [type]);

  const handleEditTag = async ({ action, item }) => {
    try {
      setIsWait(true);
      let _tags = [];
      if (action === "add") {
        _tags = [
          ...(allTags || []),
          {
            slug: generateSlug(item),
            name: item,
          },
        ];
      }
      if (action === "remove") {
        _tags = allTags?.filter((tag) => tag?.slug !== item?.slug);
      }

      const reqBody = {
        type: type,
        tags: JSON.stringify(_tags),
      };
      const resp = await axiosInstance.put(`/tag-manager`, reqBody);
      if (resp.status === 200) {
        setAllTags(_tags);
        setIsWait(false);
        setNewTagInput("");
      }
      return true;
    } catch (error) {
      console.log(error);
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured",
      );
      return false;
    }
  };

  return (
    <Stack p={2} px={4}>
      <Stack my={2} spacing={2} width={500}>
        <Stack spacing={2} direction="row" alignItems="center">
          <TextField
            fullWidth
            placeholder="Enter tag name here"
            onChange={(e) => setNewTagInput(e.target.value)}
            value={newTagInput}
            size="small"
          />
          <LoadingButton
            variant="contained"
            sx={{
              whiteSpace: "nowrap",
            }}
            disabled={!newTagInput}
            loading={isWait}
            onClick={() => {
              if (allTags?.includes(newTagInput)) {
                Notify.warning("Tag already exists");
                return;
              }
              handleEditTag({
                action: "add",
                item: newTagInput,
              });
            }}
          >
            Add Tag
          </LoadingButton>
        </Stack>
      </Stack>
      <Divider />
      <Stack sx={{ minHeight: 166 }} my={2}>
        {loading ? (
          <SuspenseLoader />
        ) : allTags?.length > 0 ? (
          <Grid container spacing={2} direction={"row"}>
            {allTags?.map((item, idx) => (
              <Grid item key={idx} xs={3}>
                <Chip
                  size="small"
                  label={item?.name}
                  variant="outlined"
                  sx={{
                    overflow: "hidden",
                    maxWidth: "300px",
                  }}
                  onDelete={() => {
                    Confirm.show(
                      "Confirmation",
                      "Are you sure you want to delete this tag?",
                      "Confirm",
                      "Cancel",
                      () =>
                        handleEditTag({
                          action: "remove",
                          item,
                        }),
                      () => {},
                      {
                        width: "500px",
                        messageMaxLength: 1000,
                      },
                    );
                  }}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography
            variant="body2"
            color="text.secondary"
            textAlign="center"
            m={2}
          >
            {`No ${type} tags found`}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}
