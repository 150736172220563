import { alpha, Grid, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import StatCard1 from "src/components/Statistics/StatCard1";
import { GlobalContext } from "src/contexts/GlobalContext";

export default function AppointmentStats({
  whichPage,
  status,
  handleClick = () => {},
}) {
  const theme = useTheme();
  const { stats, handleGetStats } = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);
  const { appointmentsStats } = stats || {};
  const [appStats, setAppStats] = useState({});

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        await handleGetStats();
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (appointmentsStats) {
      const stats = appointmentsStats?.appointmentsByStatus?.reduce(
        (acc, item) => {
          acc[item._id] = item;
          return acc;
        },
        {},
      );
      setAppStats(stats);
    }
  }, [appointmentsStats]);

  return (
    !loading && (
      <Grid container spacing={2}>
        {[
          {
            label: "Total Appointments",
            color: "primary",
            count: appointmentsStats?.totalAppointments || 0,
            value: appointmentsStats?.totalAppointmentsAmount || 0,
            status: null,
          },
          {
            label: "Payment Pending",
            color: "warning",
            count: appStats?.["payment-pending"]?.count || 0,
            value: appStats?.["payment-pending"]?.payment || 0,
            status: "payment-pending",
          },
          {
            label: "Completed / Confirmed",
            color: "success",
            count:
              (appStats?.completed?.count || 0) +
                (appStats?.confirmed?.count || 0) +
                (appStats?.inprogress?.count || 0) || 0,
            value:
              (appStats?.completed?.payment || 0) +
                (appStats?.confirmed?.payment || 0) +
                (appStats?.inprogress?.payment || 0) || 0,
            status: "completed",
          },
          {
            label: "Cancelled",
            color: "error",
            count: appStats?.cancelled?.count || 0,
            value: appStats?.cancelled?.payment || 0,
            status: "cancelled",
          },
        ]?.map((item, idx) => (
          <Grid item xs={12} md={whichPage === "dashboard" ? 6 : 3} key={idx}>
            <StatCard1
              item={item}
              onClick={() => handleClick(item.status)}
              sx={{
                backgroundColor:
                  status === item.status
                    ? alpha(theme.palette[item.color]?.main, 0.1)
                    : null,
                cursor: "pointer",
              }}
            />
          </Grid>
        ))}
      </Grid>
    )
  );
}
