import { ArrowBackTwoTone, CloudUploadTwoTone } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  TextField,
  Box,
  Card,
  Grid,
  Button,
  Typography,
  Stack,
  Switch,
  styled,
  IconButton,
  Avatar,
  Autocomplete,
  capitalize,
} from "@mui/material";
import { Confirm, Notify } from "notiflix";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import QuillTextarea from "src/components/ReactQuill";
import SuspenseLoader from "src/components/SuspenseLoader";
import axiosInstance from "src/utils/axiosInstance";

export default function AddEditBlog() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { blogId } = useParams();
  const [loading, setLoading] = useState(true);
  const [formInput, setFormInput] = useState({});
  const [isWait, setIsWait] = useState(false);
  const [coverImage, setCoverImage] = useState(null);
  const [allTags, setAllTags] = useState([]);

  const handleChange = (event) => {
    event.preventDefault();
    setFormInput({ ...formInput, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const resp = await axiosInstance.get("/tag-manager/tags", {
        params: {
          type: "blogs",
        },
      });
      if (resp?.status === 200) {
        setAllTags(resp?.data?.data?.tags || []);
      }
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    const _blogId = formInput?._id || blogId;
    if (!_blogId) {
      setLoading(false);
      return;
    }
    (async () => {
      try {
        setLoading(true);
        const resp = await axiosInstance.get(`/blogs/${_blogId}`);
        if (resp?.status === 200) {
          const _blog = resp?.data?.data || {};
          _blog.tags = _blog?.tags?.join(", ");
          setFormInput(_blog);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        Notify.failure(
          error.response?.data?.message ||
            error.response?.statusText ||
            "an error occured",
        );
      }
    })();
  }, []);

  const handleSave = async (status) => {
    try {
      if (!formInput?.title) return Notify.warning("Title is required");
      if (!formInput?.content)
        return Notify.warning("Blog Content is required");

      if (!coverImage && !formInput?.coverImage)
        return Notify.warning("Cover Image is required");

      setIsWait(true);

      // if cover image is changed
      if (coverImage) {
        const formData = new FormData();
        formData.append("files", coverImage);
        formData.append("fileCategory", "blogs");

        const imageResp = await axiosInstance.post(
          "/users/upload-media",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
        );
        if (imageResp.status === 200 && imageResp.data) {
          formInput.coverImage = imageResp.data?.[0];
          setFormInput({
            ...formInput,
            coverImage: imageResp.data?.[0],
          });
        }
      }

      formInput.status = status || "draft";
      if (formInput?._id) {
        const resp = await axiosInstance.put(`/blogs/${formInput?._id}`, {
          ...formInput,
          // filter and trim
          tags: formInput?.tags
            ?.split(",")
            .map((tag) => tag.trim())
            ?.filter((tag) => tag),
        });
        if (resp?.status === 200) {
          const _blog = resp?.data?.data || {};
          _blog.tags = _blog?.tags?.join(", ");
          setFormInput(_blog);
          Notify.success("Blog updated successfully");
        }
      } else {
        const resp = await axiosInstance.post(`/blogs`, {
          ...formInput,
          tags: formInput?.tags
            ?.split(",")
            ?.map((tag) => tag.trim())
            ?.filter((tag) => tag),
        });
        if (resp?.status === 201) {
          const _blog = resp?.data?.data || {};
          _blog.tags = _blog?.tags?.join(", ");
          setFormInput(_blog);
          Notify.success(
            resp?.data?.data?.status === "draft"
              ? "saved successfully"
              : "Blog published successfully",
          );
          if (status === "published") {
            navigate(`/dashboard/blogs/${resp?.data?.data?._id}`);
          }
        }
      }
      setIsWait(false);
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured",
      );
    }
  };

  const handleDelete = async () => {
    try {
      setIsWait(true);
      const resp = await axiosInstance.delete(`/blogs/${formInput?._id}`);
      if (resp?.status === 200) {
        Notify.success("Blog deleted successfully");
        navigate(`/dashboard/blogs`);
      }
      setIsWait(false);
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured",
      );
    }
  };

  return loading ? (
    <SuspenseLoader />
  ) : (
    <Box m={3}>
      <Box mx={6}>
        <Box flex={1} mb={2}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box>
                  <Typography variant="h3" component="h3" gutterBottom>
                    {formInput?._id ? t("Edit Blog") : t("Add Blog")}
                  </Typography>
                  <Typography variant="subtitle2">
                    {t("Fill in the fields below")}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item>
              <Button
                sx={{
                  mt: { xs: 2, sm: 0 },
                }}
                component={Link}
                startIcon={<ArrowBackTwoTone />}
                to={`/dashboard/blogs`}
              >
                {t("Go back to all blogs")}
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Card
          sx={{
            p: 3,
            overflow: "visible",
          }}
        >
          <Stack spacing={2}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              pb={3}
            >
              <AvatarWrapper>
                <Avatar
                  variant="rounded"
                  alt={""}
                  src={
                    coverImage
                      ? URL.createObjectURL(coverImage)
                      : formInput?.coverImage || ""
                        ? `${process.env.REACT_APP_DO_SPACE_URL}${formInput?.coverImage}?${new Date().getTime()}`
                        : ""
                  }
                />
                <ButtonUploadWrapper>
                  <Input
                    accept="image/*"
                    id="icon-button-file"
                    name="icon-button-file"
                    type="file"
                    onChange={(e) => {
                      setCoverImage(e.target.files[0]);
                    }}
                  />
                  <label htmlFor="icon-button-file">
                    <IconButton component="span" color="primary">
                      <CloudUploadTwoTone />
                    </IconButton>
                  </label>
                </ButtonUploadWrapper>
              </AvatarWrapper>
            </Box>
            <TextField
              fullWidth
              name="title"
              placeholder={t("Blog title here...")}
              variant="outlined"
              value={formInput?.title || ""}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              name="subtitle"
              placeholder={t("Blog subtitle here...")}
              variant="outlined"
              value={formInput?.subtitle || ""}
              onChange={handleChange}
            />
            <Stack spacing={2} direction={"row"}>
              <Autocomplete
                fullWidth
                options={allTags}
                getOptionLabel={(option) => capitalize(option?.name || "")}
                value={
                  allTags?.find(
                    (tag) => tag?.name === formInput?.subcategory,
                  ) || null
                }
                onChange={(e, value) => {
                  setFormInput({
                    ...formInput,
                    subcategory: value?.name || "",
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Category"
                    placeholder="eg : Technology, Health"
                  />
                )}
              />
              <TextField
                fullWidth
                name="tags"
                placeholder={t("Enter tags here... (comma separated)")}
                variant="outlined"
                value={formInput?.tags || ""}
                onChange={handleChange}
              />
            </Stack>
            <Stack direction={"row"} spacing={2} pb={3} alignItems={"center"}>
              <Typography variant="subtitle1">Add to featured blogs</Typography>
              <Switch
                checked={formInput?.isFeatured ? formInput?.isFeatured : false}
                name="isFeatured"
                color="primary"
                onChange={(e) => {
                  setFormInput({
                    ...formInput,
                    isFeatured: e.target.checked,
                  });
                }}
              />
            </Stack>
            <QuillTextarea
              id="post-description_id"
              value={formInput?.content || ""}
              onChange={(value) => {
                setFormInput({
                  ...formInput,
                  content: value,
                });
              }}
            />
          </Stack>

          <Stack
            direction={"row"}
            my={3}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Box>
              {formInput?._id && (
                <LoadingButton
                  variant="contained"
                  loading={isWait}
                  onClick={() => {
                    Confirm.show(
                      "Confirmation",
                      "Are you sure you want to delete this blog? This action cannot be undone.",
                      "Confirm",
                      "Cancel",
                      () => handleDelete(),
                      () => {},
                      {
                        width: "500px",
                        messageMaxLength: 1000,
                      },
                    );
                  }}
                  color="error"
                >
                  {t("Delete")}
                </LoadingButton>
              )}
            </Box>
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
              <LoadingButton
                variant="outlined"
                loading={isWait}
                onClick={() => {
                  handleSave("draft");
                }}
                color="secondary"
              >
                {formInput?.status === "published"
                  ? "Save and Unpublish"
                  : "Save as draft"}
              </LoadingButton>
              <LoadingButton
                variant="contained"
                loading={isWait}
                onClick={() => {
                  handleSave("published");
                }}
                color="success"
              >
                {formInput?.status === "published" ? "Update" : "Publish"}
              </LoadingButton>
            </Stack>
          </Stack>
        </Card>
      </Box>
    </Box>
  );
}

const Input = styled("input")({
  display: "none",
});

const AvatarWrapper = styled(Box)(
  ({ theme }) => `
  
      position: relative;
  
      .MuiAvatar-root {
        width: ${theme.spacing(16)};
        height: ${theme.spacing(16)};
      }
  `,
);

const ButtonUploadWrapper = styled(Box)(
  ({ theme }) => `
      position: absolute;
      width: ${theme.spacing(6)};
      height: ${theme.spacing(6)};
      bottom: -${theme.spacing(2)};
      right: -${theme.spacing(2)};
  
      .MuiIconButton-root {
        border-radius: 100%;
        background: ${theme.colors.primary.main};
        color: ${theme.palette.primary.contrastText};
        box-shadow: ${theme.colors.shadows.primary};
        width: ${theme.spacing(6)};
        height: ${theme.spacing(6)};
        padding: 0;
    
        &:hover {
          background: ${theme.colors.primary.dark};
        }
      }
  `,
);
