import {
  Avatar,
  Box,
  Button,
  Divider,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Notify } from "notiflix";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  CancelOutlined,
  EditOutlined,
  SaveOutlined,
} from "@mui/icons-material";

import FileUpload from "src/components/FileUpload";
import { uploadMedia } from "src/utils/upload";
import { LoadingButton } from "@mui/lab";
import SelectFilesToUpload from "src/components/FileUpload/SelectFilesToUpload";

function AddOrEditSpecialization({
  show,
  selectedSpecialization,
  handleClose,
  onSubmit,
  isLoading,
}) {
  const [newDetails, setNewDetails] = useState(selectedSpecialization ?? {});

  const schema = yup
    .object({
      name: yup.string().required("Please enter specialization title."),
    })
    .required();

  const {
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      icon: selectedSpecialization?.icon ?? null,
      name: selectedSpecialization?.name ?? null,
    },
  });

  const handleLabel = (val) => {
    // remove all the whitespaces character with -
    let slug = val?.trim()?.replaceAll(" ", "-")?.toLowerCase();
    let name = val;

    setNewDetails({
      ...newDetails,
      name,
      slug,
    });
    setValue("name", name);
  };
  const [files, setFiles] = useState([]);

  const handleFileSubmit = async (data) => {
    try {
      const file = data?.[0];
      if (!file) {
        throw new Error("404");
      }

      const image = new Image();
      const url = URL.createObjectURL(file);

      const validateImage = () => {
        return new Promise((resolve, reject) => {
          image.onload = () => {
            // image width and height should be in 3:4 format, example 48px width and 64px height
            if (image.width % 3 !== 0 || image.height % 4 !== 0) {
              reject(new Error("403"));
              return;
            }
            if (image.width / 3 !== image.height / 4) {
              reject(new Error("403"));
              return;
            }
            resolve(file);
          };
          image.src = url;
        });
      };

      const validatedFile = await validateImage();

      if (!validatedFile) {
        throw new Error("403");
      }

      const formData = new FormData();

      formData.append("files", validatedFile);
      formData.append("fileCategory", "others");

      const res = await uploadMedia(formData);

      if (res?.data[0]) {
        clearErrors();
        setNewDetails((prevDetails) => ({
          ...prevDetails,
          icon: res?.data[0],
        }));
        setValue("icon", res?.data[0]);
      } else {
        throw new Error("Upload failed");
      }
    } catch (err) {
      if (err.message === "404") {
        Notify.failure("Please upload an icon");
      } else if (err.message === "403") {
        Notify.failure("Image must be of size - 48px width and 64px height.");
      } else {
        console.error(err);
      }
      throw err; // Propagate the error to handleAddUpdate
    }
  };

  const handleAddUpdate = async (values) => {
    try {
      clearErrors();
      await handleFileSubmit(files);
      onSubmit({
        ...newDetails,
        icon: values?.icon,
      });
    } catch (error) {}
  };

  return (
    <Modal open={show} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
          minWidth: 600,
        }}
      >
        <Box>
          <Typography variant="h3" sx={{ mb: 2 }}>
            {!selectedSpecialization
              ? "Add New Specialization"
              : "Edit Specialization"}
          </Typography>
        </Box>
        <Divider sx={{ my: 2 }} />
        {!newDetails?.icon ? (
          <Box sx={{ mb: 1 }}>
            <Box mb={2}>
              The icons will be used on Map to visualize Vendors with different
              specializations.
              <br />
              <small>
                Note: The icon size must be of 48px width and 64px height.
              </small>
              <br />
            </Box>

            <SelectFilesToUpload
              setFiles={setFiles}
              files={files}
              isSubmitOnDrop={true}
              multiple={false}
              content="Drop or click to upload icon (required)"
              imageSx={{
                width: 48,
                height: 64,
              }}
            />
          </Box>
        ) : (
          <Box sx={{ mb: 1 }} display={"flex"} alignItems={"center"}>
            <Avatar
              variant="rounded"
              sx={{ objectFit: "contain", height: 64, width: 48 }}
              src={process.env.REACT_APP_DO_SPACE_URL + newDetails?.icon}
            />
            <Button
              sx={{ ml: 1 }}
              size="small"
              variant="outlined"
              startIcon={<EditOutlined />}
              title="Click here to change icon"
              onClick={() => {
                setValue("icon", null);
                setNewDetails({ ...newDetails, icon: null });
              }}
            >
              Change
            </Button>
          </Box>
        )}
        {errors?.icon && (
          <Typography sx={{ mb: 1 }} color={"#e63757"}>
            {errors.icon?.message}
          </Typography>
        )}
        <TextField
          fullWidth
          type="text"
          label="Type new specialization here *"
          variant="outlined"
          value={newDetails?.name}
          sx={{ mt: 2, mb: 1 }}
          onChange={(e) => handleLabel(e.target.value)}
        />
        {errors?.name && (
          <Typography color={"#e63757"}>{errors.name?.message}</Typography>
        )}
        <Box sx={{ mt: 2 }} display={"flex"} justifyContent={"end"}>
          <Button
            sx={{ mr: 1 }}
            variant="outlined"
            startIcon={<CancelOutlined />}
            title="Click here to edit this specialization"
            onClick={handleClose}
            size="small"
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            startIcon={<SaveOutlined />}
            title="Click here to edit this specialization"
            onClick={handleSubmit(handleAddUpdate)}
            loading={isLoading}
            disabled={
              isLoading ||
              !newDetails?.name ||
              (files?.length === 0 && !newDetails?.icon)
            }
          >
            {selectedSpecialization ? "Update" : "Save"}
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
}

export default AddOrEditSpecialization;
