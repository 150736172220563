import {
  AdminPanelSettings,
  AssignmentInd,
  People,
  PersonPin,
} from "@mui/icons-material";
import { Button, Card, Stack } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { GlobalContext } from "src/contexts/GlobalContext";

export default function UsersStatsCard({
  whichPage,
  handleTabsChange,
  tabValue,
}) {
  const { stats, handleGetStats } = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);
  const { usersStats } = stats || {};
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        await handleGetStats();
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    })();
  }, []);

  const tabs = [
    {
      label: "Users",
      value: "user",
      icon: People,
      count:
        usersStats?.usersByAccountType?.find((stat) => stat?._id === "user")
          ?.count || 0,
      color: "success",
    },
    {
      label: "Responders",
      value: "responder",
      icon: AssignmentInd,
      count:
        usersStats?.usersByAccountType?.find(
          (stat) => stat?._id === "responder",
        )?.count || 0,
      color: "info",
    },
    {
      label: "Vendors",
      value: "vendor",
      icon: PersonPin,
      count:
        usersStats?.usersByAccountType?.find((stat) => stat?._id === "vendor")
          ?.count || 0,
      color: "primary",
    },
    {
      label: "Adminstrators",
      value: "admin",
      icon: AdminPanelSettings,
      count:
        usersStats?.usersByAccountType?.find((stat) => stat?._id === "admin")
          ?.count || 0,
      color: "error",
    },
  ];

  return (
    !loading && (
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        alignItems={"center"}
        mb={1}
      >
        {tabs?.map((tab, index) => (
          <Button
            startIcon={<tab.icon />}
            endIcon={
              <b style={{ marginLeft: 40, fontWeight: 700, fontSize: "1rem" }}>
                <small>{tab?.count}</small>
              </b>
            }
            variant={
              tabValue === tab?.value || whichPage === "dashboard"
                ? "contained"
                : ""
            }
            component={Card}
            onClick={() =>
              whichPage !== "dashboard"
                ? handleTabsChange(tab?.value)
                : navigate("/dashboard/users?tab=" + tab?.value)
            }
            fullWidth={whichPage === "dashboard"}
            key={index}
            color={whichPage === "dashboard" ? tab?.color : "primary"}
          >
            {tab?.label}
          </Button>
        ))}
      </Stack>
    )
  );
}
