import { Button, capitalize, Card, Stack, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "src/contexts/GlobalContext";

export default function UsersStats({ handleRole, selectedRole }) {
  const theme = useTheme();
  const { stats, handleGetStats } = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);
  const { usersStats } = stats || {};
  const [professions, setProfessions] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        await handleGetStats();
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (usersStats?.vendorsByProfession) {
      setProfessions(usersStats?.vendorsByProfession || []);
    }
  }, [usersStats]);

  return (
    !loading &&
    professions?.length > 0 && (
      <Stack direction="row" spacing={2} alignItems={"center"} mb={1}>
        {professions
          ?.filter((tab) => !["", "responder"]?.includes(tab?._id))
          ?.map((tab, index) => (
            <Button
              endIcon={
                <b style={{ marginLeft: 40 }}>
                  <small>{tab?.count}</small>
                </b>
              }
              sx={{
                textTransform: "capitalize",
                backgroundColor:
                  selectedRole === tab?._id
                    ? theme.palette.primary.main + 14
                    : null,
                borderWidth: selectedRole === tab?._id ? 2 : 1,
                "&:hover": {
                  borderWidth: selectedRole === tab?._id ? 2 : 1,
                },
              }}
              variant={"outlined"}
              component={Card}
              size="small"
              key={index}
              onClick={() => handleRole(tab?._id)}
            >
              {capitalize(tab?._id?.toString()?.replaceAll("-", " ") || "")}s
            </Button>
          ))}
      </Stack>
    )
  );
}
