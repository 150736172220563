import {
  ArrowBackTwoTone,
  CheckCircleOutlined,
  PublishedWithChanges,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
  capitalize,
} from "@mui/material";
import { Confirm, Notify } from "notiflix";
import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";
import EmptyHere from "src/components/EmptyHere";
import axiosInstance from "src/utils/axiosInstance";
import QuestionInputField from "../common/QuestionInputField";
import { Link } from "react-router-dom";
import SuspenseLoader from "src/components/SuspenseLoader";
import ProfileShortPreview from "../common/ProfileShortPreview";
import Label from "src/components/Label";
import ClosingRemarkDialog from "./components/ClosingRemarkDialog";

export default function ReportDetails() {
  const { reportId } = useParams();
  const [reportData, setReportData] = useState({
    type: "",
    inputs: [],
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!reportId) {
      Notify.failure("Report ID is missing");
      return;
    }
    (async () => {
      setLoading(true);
      const resp = await axiosInstance.get(`/reports/${reportId}`);
      if (resp?.status === 200) {
        const _reportData = {
          ...resp?.data?.data,
          inputs: JSON.parse(resp?.data?.data?.inputs || []),
        };
        setReportData(_reportData);
      }
      setLoading(false);
    })();
  }, [reportId]);

  return (
    <Box m={3}>
      <Stack spacing={4}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography variant="h3" component="h3" gutterBottom>
              {capitalize(reportData?.title || "")}
            </Typography>
          </Box>{" "}
          <Button
            sx={{
              mt: { xs: 2, sm: 0 },
            }}
            component={Link}
            startIcon={<ArrowBackTwoTone />}
            to={`/dashboard/reports`}
          >
            Go back to reports
          </Button>
        </Box>

        {loading ? (
          <SuspenseLoader />
        ) : (
          <>
            <ProfileShortPreview
              action={
                <Stack direction="row" spacing={1} alignItems="center">
                  <Chip
                    color={
                      reportData?.status === "closed" ? "success" : "warning"
                    }
                    label={<b>{capitalize(reportData?.status || "closed")}</b>}
                    size="small"
                  />
                  {reportData?.status !== "closed" && (
                    <ClosingRemarkDialog
                      reportId={reportId}
                      reportData={reportData}
                      setReportData={setReportData}
                    />
                  )}
                </Stack>
              }
              data={reportData?.createdBy || {}}
            />
            {reportData?.status === "closed" && (
              <Fragment>
                <Grid
                  container
                  component={Card}
                  p={2}
                  sx={{
                    bgcolor: "success.dark",
                  }}
                >
                  <Grid item xs={3}>
                    <Stack
                      direction="column"
                      mt={2}
                      alignItems="center"
                      spacing={2}
                    >
                      <CheckCircleOutlined
                        fontSize="large"
                        sx={{
                          color: "white",
                        }}
                      />

                      <Typography variant="h3" component="h3" color="white">
                        Report is Closed
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography
                      variant="h4"
                      color="white"
                      component={"pre"}
                      sx={{
                        whiteSpace: "pre-line",
                        textAlign: "left",
                      }}
                    >
                      {reportData?.closingRemark || "No closing remark"}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider sx={{ my: 2 }} />
              </Fragment>
            )}
            <Stack spacing={2}>
              {reportData?.inputs?.length > 0 ? (
                reportData?.inputs?.map((question, index) => (
                  <Card
                    key={question?._id}
                    elevation={0}
                    sx={{
                      "&:hover": {
                        boxShadow: "0 0 10px 0 rgba(33,33,33,.2)",
                      },
                    }}
                  >
                    <CardHeader
                      title={
                        question?.title && (
                          <Typography variant="h5" component="div">
                            {question?.title || ""}
                          </Typography>
                        )
                      }
                      subheader={
                        question?.description && (
                          <Typography variant="subtitle1">
                            {question?.description || ""}
                          </Typography>
                        )
                      }
                    />
                    <CardContent sx={{ py: 0 }}>
                      <QuestionInputField
                        question={question}
                        handleFormInput={() => {}}
                        formInput={{}}
                        setFormInput={() => {}}
                        disabled={true}
                      />
                    </CardContent>
                  </Card>
                ))
              ) : (
                <EmptyHere message="No fields added yet" />
              )}
            </Stack>
          </>
        )}
      </Stack>
    </Box>
  );
}
