import {
  Card,
  Box,
  Button,
  Avatar,
  styled,
  Stack,
  Tabs,
  Tab,
  ListItemText,
  capitalize,
  Chip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "src/contexts/GlobalContext";
import { Confirm, Notify } from "notiflix";
import axiosInstance from "src/utils/axiosInstance";
import {
  RemoveTwoTone,
  ConfirmationNumber,
  Message,
  Phone,
  PublishedWithChanges,
} from "@mui/icons-material";
import UserSummarySection from "./components/UserSummarySection";
import AppointmentList from "../appointments/AppointmentList";
import Tickets from "../Tickets";
import CallRecordings from "../CallRecordings";
import Reports from "../Reports";
import Messages from "../Messages";
import EmptyHere from "src/components/EmptyHere";
import SuspenseLoader from "src/components/SuspenseLoader";
import { roleFormator } from "src/utils/helpers";

const AvatarWrapper = styled(Box)(
  ({ theme }) => `
    overflow: visible;
    .MuiAvatar-root {
      width: ${theme.spacing(14)};
      height: ${theme.spacing(14)};
    }
`,
);

function UserProfile({ page }) {
  const { t } = useTranslation();
  const navigator = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const tabParam = searchParams.get("tab");
  const { currentUser } = useContext(GlobalContext);
  const { userId } = useParams();
  const { hash } = useLocation();
  const [loading, setLoading] = useState(false);
  const [formInput, setFormInput] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [isOrgAdmin, setIsOrgAdmin] = useState(false);
  const [activeTab, setActiveTab] = useState(tabParam || "profile");

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
    setSearchParams({ tab: newValue });
  };

  useEffect(() => {
    if (tabParam) {
      setActiveTab(tabParam);
    }
  }, [tabParam]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        if (hash === "#org-admin") {
          setIsOrgAdmin(true);
        }
        let response;
        if (page === "profile") {
          response = await axiosInstance.get("/users/profile");
        } else {
          if (hash === "#org") {
            response = await axiosInstance.get(`/user/participants/${userId}`);
          } else {
            response = await axiosInstance.get(`/users/${userId}`);
          }
        }
        if (response.status === 200) {
          setFormInput(response.data);
          setProfileImage(response.data?.profileImage || null);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setFormInput({});
        setLoading(false);
        Notify.failure(
          error.response?.data?.message ||
            error.response?.statusText ||
            "an error occured",
        );
      }
    })();
  }, [userId]);

  const handleToggleStatus = async () => {
    try {
      const resp = await axiosInstance.put(`/users/${userId}`, {
        isOnline: !formInput?.isOnline,
      });
      if (resp.status === 200) {
        setFormInput({
          ...formInput,
          isOnline: !formInput?.isOnline,
        });
        Notify.success("User status updated");
      }
    } catch (error) {
      console.log(error);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured",
      );
    }
  };

  const RemoveFromOrgButton = () => {
    const [isWait, setIsWait] = useState(false);
    const handleRemoveFromOrg = async () => {
      try {
        const confirm = window.confirm(
          "Are you sure you want to remove this user from your organisation?",
        );
        if (!confirm) return;
        setIsWait(true);
        const resp = await axiosInstance.delete("/organisations/users", {
          data: {
            roleId: formInput?.roles?.find(
              (role) => role.organisation === currentUser?.orgId,
            )?._id,
          },
        });
        if (resp.status === 200) {
          setIsWait(false);
          Notify.success("User removed from organisation");
          navigator(`/dashboard/users`);
        }
      } catch (error) {
        console.log(error);
        setIsWait(false);
        Notify.failure(
          error.response?.data?.message ||
            error.response?.statusText ||
            "an error occured",
        );
      }
    };
    return (
      <Button
        variant="outlined"
        color={"error"}
        startIcon={<RemoveTwoTone />}
        onClick={handleRemoveFromOrg}
      >
        {t("Remove from Organisation")}
      </Button>
    );
  };

  return loading ? (
    <SuspenseLoader />
  ) : (
    <Box sx={{ mx: 3 }}>
      {!formInput?._id ? (
        <EmptyHere title={"User not found"} />
      ) : (
        <>
          <Box
            display="flex"
            sx={{ my: 2 }}
            alignItems="center"
            justifyContent="space-between"
          ></Box>
          {/* new ui */}
          <Card sx={{ my: 2 }}>
            <Stack
              direction="row"
              spacing={3}
              pb={3}
              px={2}
              pt={2}
              sx={{
                background: (theme) => theme.palette.background.default,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  top: 0,
                }}
              >
                <AvatarWrapper>
                  <Avatar
                    sx={{
                      width: 128,
                      height: 128,
                    }}
                    variant="circular"
                    alt={formInput?.firstName}
                    src={
                      profileImage
                        ? `${process.env.REACT_APP_DO_SPACE_URL}${profileImage}?${new Date().getTime()}`
                        : ""
                    }
                  />
                </AvatarWrapper>
              </Box>
              <Box width={"100%"} sx={{ py: 1 }}>
                <ListItemText
                  primaryTypographyProps={{
                    sx: {
                      fontWeight: "700",
                      fontSize: "2rem",
                    },
                  }}
                  primary={capitalize(
                    formInput?.firstName + " " + (formInput?.lastName || ""),
                  )}
                  secondaryTypographyProps={{
                    sx: {
                      fontSize: "1rem",
                    },
                  }}
                  secondary={
                    <Stack direction="row" spacing={2}>
                      <Typography variant="h6">
                        {formInput?.copconnectUserId}
                      </Typography>
                      {["vendor", "responder"].includes(
                        formInput?.accountType,
                      ) && (
                        <Chip
                          label={
                            <small>
                              <b>
                                {formInput?.isOnline
                                  ? "Available"
                                  : "Not Available right now"}
                              </b>
                            </small>
                          }
                          color={formInput?.isOnline ? "success" : "error"}
                          size="small"
                          icon={<PublishedWithChanges />}
                          onClick={() => {
                            Confirm.show(
                              "Confirmation",
                              "Are you sure you want to change status?",
                              "Confirm",
                              "Cancel",
                              () => handleToggleStatus(),
                              () => {},
                              {
                                width: "500px",
                                messageMaxLength: 1000,
                              },
                            );
                          }}
                        />
                      )}
                    </Stack>
                  }
                />

                <ListItemText
                  secondaryTypographyProps={{
                    sx: {
                      fontSize: "0.875rem",
                    },
                  }}
                  secondary={
                    formInput?.accountType === "vendor"
                      ? roleFormator(formInput?.role)
                      : formInput?.email || ""
                  }
                />
              </Box>
              <Box
                display={"flex"}
                width={"100%"}
                justifyContent={"space-between"}
              >
                <Box></Box>
                <Box>
                  {formInput && (
                    <Stack direction="row" spacing={2}>
                      <Link
                        style={{
                          textDecoration: "none",
                        }}
                        to={
                          currentUser?.userId === formInput?._id
                            ? `/dashboard/account/profile/edit`
                            : `/dashboard/users/${formInput?._id}/edit`
                        }
                      >
                        <Button startIcon={<EditTwoToneIcon />}>
                          {t("Edit Profile")}
                        </Button>
                      </Link>
                    </Stack>
                  )}
                </Box>
              </Box>
            </Stack>
            <Tabs
              sx={{
                ".MuiTabs-indicator": {
                  height: 2,
                  minHeight: 2,
                },
                "& .MuiTab-root.Mui-selected": {
                  color: (theme) => theme.palette.primary.main,
                  "&:hover": {
                    color: (theme) => theme.palette.primary.main,
                  },
                },
                ".MuiTab-root": {
                  "&:hover": {
                    color: (theme) => theme.palette.primary.main,
                  },
                },
                pl: 16,
                background: (theme) => theme.palette.background.paper,
              }}
              value={activeTab}
              onChange={handleChange}
            >
              {[
                {
                  label: "Profile",
                  value: "profile",
                  icon: <PersonPinIcon />,
                },
                {
                  label: "Appointments",
                  value: "appointments",
                  icon: <PeopleAltIcon />,
                },
                {
                  label: "Tickets",
                  value: "tickets",
                  icon: <ConfirmationNumber />,
                },
                {
                  label: "Reports",
                  value: "reports",
                  icon: <ConfirmationNumber />,
                },
                {
                  label: "Messages",
                  value: "messages",
                  icon: <Message />,
                },
                {
                  label: "Call history",
                  value: "call-history",
                  icon: <Phone />,
                },
              ].map((tab, index) =>
                tab?.value === "tickets" &&
                formInput?.accountType === "vendor" ? null : (
                  <Tab
                    key={index}
                    icon={tab.icon}
                    iconPosition="start"
                    label={tab.label}
                    value={tab.value}
                  />
                ),
              )}
            </Tabs>
          </Card>
          <Stack>
            <Box>
              {activeTab === "profile" && (
                <UserSummarySection item={formInput} />
              )}
              {activeTab === "appointments" && (
                <AppointmentList userId={userId} whichPage="profile" />
              )}
              {activeTab === "tickets" && (
                <Tickets userId={userId} whichPage="profile" />
              )}
              {activeTab === "reports" && (
                <Reports userId={userId} whichPage="profile" />
              )}
              {activeTab === "messages" && (
                <Messages
                  userId={userId}
                  user={{
                    firstName: formInput?.firstName,
                    lastName: formInput?.lastName,
                    profileImage: formInput?.profileImage,
                  }}
                />
              )}
              {activeTab === "call-history" && (
                <CallRecordings userId={userId} whichPage="profile" />
              )}
            </Box>
          </Stack>
        </>
      )}
    </Box>
  );
}

export default UserProfile;
