import { useContext } from "react";

import { Box, IconButton, Tooltip, styled, useTheme } from "@mui/material";
import MenuTwoToneIcon from "@mui/icons-material/MenuTwoTone";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import Logo from "src/components/Logo";

import HeaderMenu from "./Menu";
import HeaderUserbox from "./Userbox";
import { GlobalContext } from "src/contexts/GlobalContext";

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        margin-top: ${theme.spacing(3)};
        position: relative;
        justify-content: space-between;
        width: 100%;
`
);

function Header() {
  const { sidebarToggle, toggleSidebar } = useContext(GlobalContext);
  const theme = useTheme();
  const lgDown = theme.breakpoints.down("lg");

  return (
    lgDown && (
      <HeaderWrapper
        display="flex"
        alignItems="center"
        sx={{
          display: { lg: "none" },
        }}
      >
        <Box display="flex" alignItems="center">
          <Box
            component="span"
            sx={{
              display: { lg: "none", xs: "inline-block" },
            }}
          >
            <Logo />
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <HeaderUserbox />
          <Box
            component="span"
            sx={{
              display: { lg: "none", xs: "inline-block" },
            }}
          >
            <Tooltip arrow title="Toggle Menu">
              <IconButton color="primary" onClick={toggleSidebar}>
                {!sidebarToggle ? <MenuTwoToneIcon /> : <CloseTwoToneIcon />}
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </HeaderWrapper>
    )
  );
}

export default Header;
