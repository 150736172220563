import React, { Fragment, useState } from "react";
import {
  Button,
  TextField,
  Card,
  Box,
  CircularProgress,
  Avatar,
  IconButton,
  styled,
  Stack,
} from "@mui/material";
import { Save, UploadTwoTone } from "@mui/icons-material";
import { Notify } from "notiflix";
import axiosInstance from "src/utils/axiosInstance";
import { useNavigate } from "react-router";

export default function UserForm({ accountType, role, organisationId }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formInput, setFormInput] = useState({});
  const [profileImage, setProfileImage] = useState(null);

  const handleChangeInput = (event) => {
    setFormInput({
      ...formInput,
      [event.target.name]: event.target.value,
    });
  };

  const handleCreateAndSaveUser = async () => {
    try {
      setLoading(true);
      if (profileImage) {
        const formData = new FormData();
        formData.append("files", profileImage);
        const imageResp = await axiosInstance.post(
          "/users/upload-media",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
        );
        if (imageResp.status === 200 && imageResp.data?.originalFileUri) {
          formInput.profileImage = imageResp.data?.originalFileUri;
          setFormInput(formInput);
        }
      }

      formInput.accountType = accountType;
      formInput.role = role;

      if (organisationId) {
        formInput.organisationId = organisationId;
      }

      const resp = await axiosInstance.post("/organisations/users", formInput);
      if (resp.status === 201) {
        Notify.success("User added successfully");
        setLoading(false);
        setFormInput({});
        setProfileImage(null);
        setTimeout(() => {
          navigate(`/dashboard/users/${resp.data?.data?._id}`);
        }, 1000);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured",
      );
    }
  };

  return (
    <Fragment>
      <Box sx={{ mb: 4, textAlign: "center" }}>
        <AvatarWrapper>
          {loading ? (
            <CircularProgress size={130} />
          ) : (
            <Avatar
              variant="rounded"
              alt={formInput?.firstName}
              src={
                profileImage
                  ? URL.createObjectURL(profileImage)
                  : formInput?.profileImage || ""
              }
            />
          )}
          <ButtonUploadWrapper>
            <Input
              accept="image/png, image/jpeg, image/jpg"
              id="icon-button-file"
              name="icon-button-file"
              type="file"
              onChange={(e) => {
                setProfileImage(e.target.files[0]);
              }}
            />
            <label htmlFor="icon-button-file">
              <IconButton component="span" color="primary">
                <UploadTwoTone />
              </IconButton>
            </label>
          </ButtonUploadWrapper>
        </AvatarWrapper>
      </Box>
      <Stack spacing={{ xs: 2, md: 3 }}>
        <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
          <TextField
            fullWidth
            label="First Name"
            name="firstName"
            value={formInput?.firstName || ""}
            onChange={handleChangeInput}
          />
          <TextField
            fullWidth
            label="Last Name"
            name="lastName"
            value={formInput?.lastName || ""}
            onChange={handleChangeInput}
          />
        </Stack>

        <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
          <TextField
            fullWidth
            label="Email Address"
            name="email"
            value={formInput?.email || ""}
            onChange={handleChangeInput}
          />
          <TextField
            fullWidth
            label="Phone Number"
            name="phoneNumber"
            value={formInput?.phoneNumber || ""}
            onChange={(e) => {
              setFormInput({
                ...formInput,
                phoneNumber: e.target.value,
              });
            }}
          />
        </Stack>
        <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
          <TextField
            fullWidth
            label="Address"
            name="address"
            value={formInput?.meta?.address || ""}
            onChange={(e) => {
              setFormInput({
                ...formInput,
                meta: { ...formInput?.meta, address: e.target.value },
              });
            }}
            multiline
            rows={4}
          />
        </Stack>
      </Stack>
      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        <Box sx={{ flex: "1 1 auto" }} />
        <Button
          variant="outlined"
          startIcon={<Save />}
          onClick={handleCreateAndSaveUser}
          disabled={loading}
        >
          Create and Save
        </Button>
      </Box>
    </Fragment>
  );
}

const Input = styled("input")({
  display: "none",
});

const AvatarWrapper = styled(Card)(
  ({ theme }) => `

    position: relative;
    overflow: visible;
    display: inline-block;
    .MuiAvatar-root {
      width: ${theme.spacing(16)};
      height: ${theme.spacing(16)};
    }
`,
);

const ButtonUploadWrapper = styled(Box)(
  ({ theme }) => `
    position: absolute;
    width: ${theme.spacing(4)};
    height: ${theme.spacing(4)};
    bottom: -${theme.spacing(1)};
    right: -${theme.spacing(1)};

    .MuiIconButton-root {
      border-radius: 100%;
      background: ${theme.colors.primary.main};
      color: ${theme.palette.primary.contrastText};
      box-shadow: ${theme.colors.shadows.primary};
      width: ${theme.spacing(4)};
      height: ${theme.spacing(4)};
      padding: 0;
  
      &:hover {
        background: ${theme.colors.primary.dark};
      }
    }
`,
);
