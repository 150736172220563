import { Stack, Typography } from "@mui/material";
import React from "react";

export default function ValidationCard({ title, subtitle, icon }) {
  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      justifyContent="space-between"
      maxWidth={120}
    >
      <Typography variant="h6">{title}</Typography>
      {icon ? icon : <Typography variant="subtitle2">{subtitle}</Typography>}
    </Stack>
  );
}
