import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import router from "src/router";

import { CssBaseline } from "@mui/material";
import ThemeProvider from "./theme/ThemeProvider";
import AppInit from "./components/AppInit";
import { useEffect, useState } from "react";
import {
  OWNER_RESTRICTED_PATHS,
  PUBLIC_PATHS,
  USER_RESTRICTED_PATHS,
} from "./utils/constants";
import { Notify } from "notiflix";

function App() {
  const content = useRoutes(router);

  const [isWait, setIsWait] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (!PUBLIC_PATHS.some((a) => location.pathname.includes(a))) {
        const _user = JSON.parse(localStorage.getItem("user"));
        if (!_user) {
          navigate("/auth/login");
          return;
        }
        const accountType = _user?.accountType;
        const role = _user?.role;

        if (
          (role === "owner" &&
            OWNER_RESTRICTED_PATHS.includes(location.pathname)) ||
          (role === "user" && USER_RESTRICTED_PATHS.includes(location.pathname))
        ) {
          Notify.failure("Access Denied");

          localStorage.removeItem("user");
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          navigate("/auth/login");
          return;
        } else {
          setIsWait(false);
        }
      } else {
        setIsWait(false);
      }
    })();
  }, [location.pathname]);

  return (
    <ThemeProvider>
      <CssBaseline />
      {isWait ? <AppInit /> : content}
    </ThemeProvider>
  );
}
export default App;
