import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import SpecializationCard from "./SpecializationCard";
import axiosInstance from "src/utils/axiosInstance";
import { TAGS_STRING } from "src/utils/constants";
import AddOrEditSpecialization from "./AddOrEditSpecialization";
import { Add } from "@mui/icons-material";

export default function Specialization() {
  const [loading, setLoading] = useState(false);
  const [allSpecializations, setAllSpecializations] = useState([]);
  const [specializationInputs, setSpecializationInputs] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedSpecialization, setSelectedSpecialization] = useState();

  const handleClose = () => {
    setShowModal(false);
    setSelectedSpecialization(null);
  };

  const handleEdit = (details) => {
    setShowModal(true);
    setSelectedSpecialization(details);
  };

  const getInputs = useCallback(async () => {
    try {
      const res = await axiosInstance.get(
        "/tag-manager/inputs?type=specialization",
      );
      if (res?.data?.data?.inputs) {
        setSpecializationInputs(res?.data?.data?.inputs);
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  const getTags = useCallback(async () => {
    try {
      const res = await axiosInstance.get(
        "/tag-manager/tags?type=specialization",
      );
      if (res?.data?.data?.tags) {
        setAllSpecializations(res?.data?.data?.tags);
      }
    } catch (err) {
      console.error(err);
    } finally {
    }
  }, []);

  useEffect(() => {
    getTags();
    getInputs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createOrUpdateSpecialization = async (tags) => {
    try {
      setLoading(true);
      const res = await axiosInstance.put("/tag-manager", {
        inputs: JSON.stringify(specializationInputs),
        type: TAGS_STRING.specialization,
        tags: JSON.stringify(tags),
      });
      setAllSpecializations(res?.data?.data?.tags);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  const handleAddNewSpecialization = async (newSpecialization) => {
    createOrUpdateSpecialization([...allSpecializations, newSpecialization]);
  };

  const handleUpdateSpecialization = async (updatedSpecialization) => {
    let filteredSpecializations = allSpecializations.filter(
      (obj) => obj._id !== updatedSpecialization._id,
    );
    createOrUpdateSpecialization([
      ...filteredSpecializations,
      updatedSpecialization,
    ]);
  };

  return (
    <>
      <Box sx={{ py: 2 }} display="flex" alignItems={"center"}>
        <Button
          sx={{ ml: 2 }}
          size="small"
          onClick={() => setShowModal(true)}
          startIcon={<Add />}
        >
          Add new
        </Button>
      </Box>
      <Divider sx={{ mb: 2 }} />

      <Stack sx={{ minHeight: "80vh", p: 2 }}>
        <Grid container spacing={2} direction="row">
          {allSpecializations.map((each) => (
            <Grid item xs={12} md={6}>
              <SpecializationCard details={each} handleEdit={handleEdit} />
            </Grid>
          ))}
          {allSpecializations.length === 0 && !loading && (
            <Box
              sx={{
                m: 2,
                width: "100%",
                height: 160,
                borderWidth: 2,
                borderColor: "InactiveBorder",
                borderStyle: "dashed",
                borderRadius: 2,
              }}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
            >
              <Typography>You have not added anything yet.</Typography>
              <Button
                sx={{ mt: 1 }}
                variant="contained"
                size="small"
                onClick={() => setShowModal(true)}
              >
                Add Now
              </Button>
            </Box>
          )}
        </Grid>
      </Stack>
      {showModal && (
        <AddOrEditSpecialization
          show={showModal}
          selectedSpecialization={selectedSpecialization}
          handleClose={handleClose}
          isLoading={loading}
          onSubmit={
            selectedSpecialization
              ? handleUpdateSpecialization
              : handleAddNewSpecialization
          }
        />
      )}
    </>
  );
}
