import React from "react";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import ScrollTop from "src/hooks/useScrollTop";

import "nprogress/nprogress.css";
import { GlobalProvider } from "src/contexts/GlobalContext";
import * as serviceWorker from "src/serviceWorker";
import "src/styles/index.css";

import App from "./App";

const root = createRoot(document.getElementById("root"));

root.render(
  <HelmetProvider>
    <GlobalProvider>
      <BrowserRouter>
        <ScrollTop />
        <App />
      </BrowserRouter>
    </GlobalProvider>
  </HelmetProvider>
);

serviceWorker.unregister();
