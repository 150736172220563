import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardHeader,
  IconButton,
  InputBase,
  LinearProgress,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import axiosInstance from "src/utils/axiosInstance";
import { PAGE_LIMIT } from "src/utils/constants";
import SearchIcon from "@mui/icons-material/Search";
import { Notify } from "notiflix";
import { GlobalContext } from "src/contexts/GlobalContext";
import AdminUsersListCard from "./components/AdminUsersListCard";
import {
  GroupAddTwoTone,
  PersonAddAltTwoTone,
  RestartAlt,
} from "@mui/icons-material";
import UsersStatsCard from "./components/UsersStatsCard";
import OnlineUsersStats from "./components/OnlineUsersStats";
import UsersStats from "./components/UsersStats";

export default function UsersList() {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get("page");
  const tabParam = searchParams.get("tab");
  const statusParam = searchParams.get("isOnline");
  const roleParam = searchParams.get("role");
  const accountStatus = JSON.parse(searchParams.get("accountStatus"));
  const { currentUser } = useContext(GlobalContext);
  const [filterObj, setFilterObj] = React.useState(
    searchParams.has("isOnline") ? { isOnline: JSON.parse(statusParam) } : null,
  );
  const [loading, setLoading] = React.useState(false);

  const [users, setUsers] = React.useState([]);
  const [page, setPage] = React.useState(parseInt(pageParam) || 1);
  const [searchString, setSearchString] = React.useState("");

  const [tabValue, setTabValue] = React.useState(tabParam || "user");

  const handlePageChange = (event, value) => {
    setPage(value);
    searchParams.set("page", value);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    setTabValue(tabParam || "user");
    const currentFilters = {
      ...filterObj,
      accountType: tabParam === "org-user" ? "org-user" : tabParam || "user",
      search: searchParams.get("search") || "",
    };
    if (searchParams.has("accountStatus")) {
      currentFilters.isLocked = accountStatus;
    }
    setFilterObj(currentFilters);
    if (pageParam) {
      setPage(parseInt(pageParam));
    }
  }, [tabParam, pageParam]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const reqParams = {
          params: { limit: PAGE_LIMIT, skip: (page - 1) * PAGE_LIMIT },
        };

        if (filterObj) {
          reqParams.params = { ...reqParams.params, ...filterObj };
        }
        let resp = {};
        // if (
        //   currentUser?.role === "admin" &&
        //   !["owner", "moderator", "org-user"].includes(filterObj?.accountType)
        // ) {
        if (!filterObj?.accountType) {
          reqParams.params = { ...reqParams.params, accountType: tabValue };
        }
        if (filterObj?.accountType === "vendor") {
          reqParams.params = {
            ...reqParams.params,
            roles: roleParam,
          };
        }
        resp = await axiosInstance.get("/users", reqParams);
        // } else {
        //   if (filterObj?.accountType === "org-user") {
        //     reqParams.params = { ...reqParams.params, accountType: "user" };
        //   }

        //   resp = await axiosInstance.get("/organisations/users", reqParams);
        // }
        if (resp?.status === 200) {
          setUsers(resp?.data);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        Notify.failure(
          error.response?.data?.message ||
            error.response?.statusText ||
            "an error occured",
        );
      }
    })();
  }, [page, filterObj, tabValue, roleParam]);

  const handleTabsChange = (newValue) => {
    setTabValue(newValue);
    setFilterObj({
      ...filterObj,
      accountType: newValue === "org-user" ? "org-user" : newValue,
    });
    setPage(1);
    searchParams.set("tab", newValue);
    searchParams.delete("page");
    searchParams.delete("role");
    setSearchParams(searchParams);
  };

  return (
    <Box m={3}>
      {["owner", "moderator"].includes(currentUser?.role) && (
        <Card>
          <CardHeader
            title={"Organisation Users"}
            action={
              <Stack direction="row" alignItems="center" spacing={2}>
                <Button
                  size="small"
                  variant="outlined"
                  startIcon={<GroupAddTwoTone />}
                  sx={{
                    minWidth: 100,
                  }}
                  component={Link}
                  to={`/dashboard/organisations/add-user`}
                >
                  Add User
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  startIcon={<PersonAddAltTwoTone />}
                  sx={{
                    minWidth: 100,
                  }}
                  component={Link}
                  to={`/dashboard/organisations/add-moderator`}
                >
                  Add Moderator
                </Button>
              </Stack>
            }
          />
        </Card>
      )}
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        my={2}
      >
        <Stack spacing={2} direction={"row"}>
          <Paper
            component="form"
            sx={{
              display: "flex",
              alignItems: "center",
              width: 500,
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search here..."
              inputProps={{ "aria-label": "search here" }}
              value={searchParams.get("search")}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  e.preventDefault();
                  if (e.target.value.length) {
                    setFilterObj({ ...filterObj, search: searchString });
                    searchParams.set("search", searchString);
                  } else {
                    setFilterObj({ ...filterObj, search: "" });
                    searchParams.delete("search");
                  }
                  setSearchParams(searchParams);
                }
              }}
              onChange={(e) => {
                if (e.target.value?.length > 0) {
                  setSearchString(e.target.value);
                  searchParams.set("search", e.target.value);
                } else {
                  if (filterObj?.search) {
                    setFilterObj({
                      ...filterObj,
                      search: "",
                    });
                    searchParams.delete("search");
                  }
                }
                setSearchParams(searchParams);
              }}
            />
            <IconButton
              type="button"
              sx={{
                m: 0.2,
                bgcolor: "primary.main",
                color: "white",
              }}
              onClick={() => {
                setFilterObj({
                  ...filterObj,
                  search: searchString,
                });
              }}
            >
              <SearchIcon />
            </IconButton>
          </Paper>
        </Stack>
        <Box display={"flex"}>
          {currentUser?.accountType === "admin" && (
            <Button
              size="small"
              variant="outlined"
              startIcon={<GroupAddTwoTone />}
              sx={{
                minWidth: 100,
              }}
              component={Link}
              to={`/dashboard/users/new`}
            >
              Add User
            </Button>
          )}
          <Autocomplete
            disablePortal
            size="small"
            id="org-box"
            options={[
              { title: "Active", value: false },
              { title: "Deactivated ", value: true },
            ]}
            getOptionLabel={(option) => option?.title}
            sx={{ width: 200, ml: 2 }}
            renderInput={(params) => (
              <TextField {...params} label="Account Status" />
            )}
            value={
              [
                { title: "Active", value: false },
                { title: "Deactivated ", value: true },
              ].filter((e) => e.value === accountStatus)[0]
            }
            onChange={(e, v) => {
              setFilterObj({
                ...filterObj,
                isLocked: v?.value,
              });
              setPage(1);
              searchParams.delete("page");
              if (v?.value !== null && v?.value !== undefined) {
                searchParams.set("accountStatus", v?.value);
              } else {
                searchParams.delete("accountStatus");
              }
              setSearchParams(searchParams);
            }}
          />
        </Box>
      </Stack>
      {currentUser?.accountType === "admin" && (
        <Stack width={1}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            mb={1}
            sx={{
              width: "100%",
            }}
          >
            <UsersStatsCard
              handleTabsChange={handleTabsChange}
              tabValue={tabValue}
            />
            <Stack
              direction="row"
              sx={{
                minHeight: 30,
              }}
            >
              <OnlineUsersStats
                handleClick={(checked) => {
                  setFilterObj({ ...filterObj, isOnline: checked });
                  searchParams?.delete("page");
                  searchParams.set("isOnline", checked);
                  setSearchParams(searchParams);
                }}
                isOnline={filterObj?.isOnline}
              />
              {filterObj?.hasOwnProperty("isOnline") && (
                <Tooltip title="Clear filter">
                  <IconButton
                    onClick={() => {
                      let newFilterObj = { ...filterObj };
                      delete newFilterObj.isOnline;
                      setFilterObj(newFilterObj);
                      searchParams?.delete("page");
                      searchParams?.delete("isOnline");
                      setSearchParams(searchParams);
                    }}
                  >
                    <RestartAlt />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          </Stack>
          {tabValue === "vendor" && (
            <UsersStats
              selectedRole={roleParam}
              handleRole={(role) => {
                if (roleParam === role) {
                  searchParams.delete("role");
                } else {
                  searchParams.set("role", role);
                }
                setPage(1);
                searchParams.delete("page");
                setSearchParams(searchParams);
              }}
            />
          )}
        </Stack>
      )}

      <Box sx={{ mb: 4 }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Full Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>
                  {tabValue === "vendor" ? "Specialization" : "Account Type"}
                </TableCell>
                <TableCell> Info </TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <LinearProgress sx={{ m: 3 }} />
              ) : (
                <>
                  {users?.data?.map((row) => (
                    <AdminUsersListCard
                      key={row._id}
                      item={row}
                      selectedTab={tabValue}
                    />
                  ))}
                  {users?.data?.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        No users found
                      </TableCell>
                    </TableRow>
                  )}
                </>
              )}
            </TableBody>
          </Table>

          {!loading && users?.data?.length > 0 && (
            <Box sx={{ m: 3, display: "flex", justifyContent: "flex-end" }}>
              <Stack spacing={2}>
                <Pagination
                  count={users?.totalPages}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                />
              </Stack>
            </Box>
          )}
        </TableContainer>
      </Box>
    </Box>
  );
}
