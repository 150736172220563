import {
  AddCircleOutline,
  AddTwoTone,
  CloseOutlined,
  Edit,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Notify } from "notiflix";
import React, { Fragment, useEffect, useState } from "react";
import { InputTypes } from "src/utils/constants";
import QuestionInputField from "../../common/QuestionInputField";

export default function NewFormDrawer({ formData, formInputs, handleSubmit }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isWait, setIsWait] = useState(false);

  const [formInput, setFormInput] = useState({
    placeholder: "",
    type: "text",
  });

  useEffect(() => {
    if (formData) {
      setFormInput(formData);
    }
  }, [formData]);

  const handleSave = async () => {
    try {
      if (!formInput?.title || !formInput?.type) {
        Notify.warning("Please fill all required fields");
        return;
      }

      if (
        !formInput?._id &&
        formInputs?.inputs?.some(
          (item) =>
            item?.title?.toLowerCase() === formInput?.title?.toLowerCase(),
        )
      ) {
        Notify.warning("Field with same placeholder already exists");
        return;
      }
      // setIsWait(true);

      let formFields;
      if (formInput?._id) {
        const _tags = formInputs?.inputs.map((item) => {
          if (item._id === formInput._id) {
            return formInput;
          }
          return item;
        });
        formFields = { ...(formInputs || {}), inputs: _tags };
      } else {
        const _tags = [...(formInputs?.inputs || []), formInput];
        formFields = { ...(formInputs || {}), inputs: _tags };
      }

      const done = await handleSubmit(formFields);
      if (done) {
        setIsWait(false);
        setFormInput({
          placeholder: "",
          type: "text",
          value: "",
        });
        setIsOpen(false);
      } else {
        setIsWait(false);
      }
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error?.message ||
          error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured",
      );
    }
  };

  return (
    <div>
      {formInput?._id ? (
        <IconButton
          color="primary"
          size="small"
          onClick={() => setIsOpen(true)}
        >
          <Edit />
        </IconButton>
      ) : (
        <Box my={6}>
          <Divider>
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              onClick={() => setIsOpen(true)}
              sx={{ cursor: "pointer" }}
            >
              <AddCircleOutline />
              <Typography variant="h6" component="h6">
                Add New Field
              </Typography>
            </Stack>
          </Divider>
        </Box>
      )}
      {isOpen && (
        <Drawer
          anchor={"right"}
          open={isOpen}
          onClose={() => {
            setIsOpen(false);
            if (!formInput?._id) {
              setFormInput({
                placeholder: "",
                type: "text",
                value: "",
              });
            }
          }}
        >
          <Box
            sx={{
              position: "relative",
              width: 800,
            }}
          >
            <CardContent>
              <Box mb={3}>
                <Typography variant="h5" component="h3" gutterBottom>
                  Add/Edit Form Fields
                </Typography>
              </Box>
              <Stack spacing={2}>
                <FormControl fullWidth>
                  <InputLabel id="field-type">Select Input Type</InputLabel>
                  <Select
                    labelId="field-type"
                    value={formInput?.type}
                    label="Select Input Type*"
                    onChange={(e) => {
                      setFormInput({
                        ...formInput,
                        type: e.target.value,
                        options: !["select", "radio", "checkbox"]?.includes(
                          e.target.value,
                        )
                          ? []
                          : formInput?.options,
                      });
                    }}
                  >
                    {InputTypes?.map((item, idx) => (
                      <MenuItem value={item?.value} key={idx}>
                        {item?.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <TextField
                  fullWidth
                  label={"Enter question title *"}
                  type="text"
                  variant="outlined"
                  value={formInput?.title}
                  onChange={(e) => {
                    setFormInput({ ...formInput, title: e.target.value });
                  }}
                />

                {/* <TextField
                  fullWidth
                  label={"Enter question description"}
                  type="text"
                  variant="outlined"
                  value={formInput?.description}
                  rows={4}
                  multiline
                  onChange={(e) => {
                    setFormInput({ ...formInput, description: e.target.value });
                  }}
                /> */}

                {!["radio", "checkbox", "file"]?.includes(formInput?.type) && (
                  <TextField
                    fullWidth
                    label={"Enter question placeholder"}
                    type="text"
                    variant="outlined"
                    value={formInput?.placeholder}
                    onChange={(e) => {
                      setFormInput({
                        ...formInput,
                        placeholder: e.target.value,
                      });
                    }}
                  />
                )}

                <Stack
                  direction={"row"}
                  spacing={2}
                  pb={3}
                  alignItems={"center"}
                >
                  <Typography variant="subtitle1">Input mandatory?</Typography>
                  <Switch
                    checked={formInput?.required || false}
                    name="required"
                    color="primary"
                    onChange={(e) => {
                      setFormInput({
                        ...formInput,
                        required: e.target.checked,
                      });
                    }}
                  />
                </Stack>
                {formInput?.options?.map((item, index) => (
                  <Stack key={index} spacing={1}>
                    <Stack
                      direction={"row"}
                      spacing={1}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      sx={{ mt: 1 }}
                    >
                      <TextField
                        fullWidth
                        name="label"
                        size="small"
                        sx={{
                          ".MuiFilledInput-underline": {
                            "&:before": {
                              borderColor: "transparent",
                            },
                          },
                          ".MuiFilledInput-input": {
                            paddingTop: "8px",
                            paddingLeft: 0,
                          },
                        }}
                        InputProps={{
                          sx: {
                            backgroundColor: "transparent",
                            borderRadius: 0,
                            borderColor: "transparent",
                            "&:hover": {
                              backgroundColor: "transparent",
                            },
                          },
                        }}
                        placeholder={"Option"}
                        variant="filled"
                        value={item?.title}
                        onChange={(e) => {
                          const newOptions = [...formInput?.options];
                          newOptions[index].title = e.target.value;
                          setFormInput({
                            ...formInput,
                            options: newOptions,
                          });
                        }}
                      />

                      <IconButton
                        onClick={() => {
                          const newOptions = [...formInput?.options];
                          newOptions.splice(index, 1);
                          setFormInput({
                            ...formInput,
                            options: newOptions,
                          });
                        }}
                        size="small"
                      >
                        <CloseOutlined fontSize="small" />
                      </IconButton>
                    </Stack>
                  </Stack>
                ))}
                {["select", "radio", "checkbox"]?.includes(formInput?.type) && (
                  <LoadingButton
                    variant="text"
                    startIcon={<AddTwoTone fontSize="small" />}
                    onClick={() => {
                      const _option = formInput?.options || [];
                      _option.push({
                        position: _option.length + 1,
                        title: "New Option " + (_option.length + 1),
                      });
                      setFormInput({
                        ...formInput,
                        options: _option,
                      });
                    }}
                    size="small"
                  >
                    Add New Option
                  </LoadingButton>
                )}
              </Stack>
              <LoadingButton
                sx={{ mt: 2 }}
                variant="contained"
                onClick={handleSave}
                loading={isWait}
              >
                Save
              </LoadingButton>
            </CardContent>
          </Box>
          {formInput?.type && (
            <Fragment>
              <Divider>Preview</Divider>
              <Box p={2}>
                <Card>
                  <CardHeader
                    title={
                      formInput?.title && (
                        <Typography variant="h5" component="div">
                          {formInput?.title || ""}
                        </Typography>
                      )
                    }
                    subheader={
                      formInput?.description && (
                        <Typography variant="subtitle1">
                          {formInput?.description || ""}
                        </Typography>
                      )
                    }
                  />
                  <CardContent sx={{ py: 0 }}>
                    <QuestionInputField
                      question={formInput}
                      handleFormInput={() => {}}
                      formInput={{}}
                      setFormInput={() => {}}
                    />
                  </CardContent>
                </Card>
              </Box>
            </Fragment>
          )}
        </Drawer>
      )}
    </div>
  );
}
