import BaseLayout from "src/layouts/BaseLayout";
import authRoutes from "./auth";
import baseRoutes from "./base";
import dashboardsRoutes from "./dashboards";
import BoxedSidebarLayout from "src/layouts/BoxedSidebarLayout";

const router = [
  {
    path: "auth",
    children: authRoutes,
  },
  {
    path: "*",
    element: <BaseLayout />,
    children: baseRoutes,
  },
  {
    path: "dashboard",
    element: <BoxedSidebarLayout />,
    children: dashboardsRoutes,
  },
];

export default router;
