import React from "react";

import { Avatar, Box, IconButton, styled, Typography } from "@mui/material";
import { CloseOutlined, CloudUploadOutlined } from "@mui/icons-material";

function SelectFilesToUpload({
  multiple = true,
  content = "Upload Files",
  files = [],
  setFiles,
  imageSx,
  accept = "image/*",
}) {
  const handleRemove = (index) => {
    setFiles(files.filter((file, i) => i !== index));
  };

  return (
    <>
      <Box
        sx={{
          borderWidth: "2px",
          borderStyle: "dashed",
          borderColor: "grey.300",
          borderRadius: 1,
          height: "100%",
          cursor: "pointer",
          padding: 2,
          textAlign: "center",
        }}
      >
        <Box
          display={"flex"}
          alignItems="center"
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
        >
          <CloudUploadOutlined />
          <VisuallyHiddenInput
            type="file"
            multiple={multiple}
            accept={accept}
            onChange={(e) => {
              if (multiple) {
                setFiles([...files, ...e.target.files]);
              } else {
                setFiles([e.target.files[0]]);
              }
            }}
          />
          <Typography sx={{ ml: 1 }}>{content}</Typography>
        </Box>
      </Box>
      <Box>
        {files.map((file, index) => (
          <Box
            display={"flex"}
            alignItems="center"
            sx={{
              p: 1,
              mt: 1,
              backgroundColor: "ButtonHighlight",
              borderRadius: 1,
            }}
            key={index}
          >
            <Avatar
              variant="rounded"
              src={URL.createObjectURL(file)}
              alt={file?.name}
              sx={{
                width: 40,
                height: 40,
                ...imageSx,
              }}
            />

            <Box
              display={"flex"}
              justifyContent="between"
              alignItems="center"
              sx={{ ml: 1, flex: 1 }}
            >
              {file?.name}
            </Box>
            <IconButton
              size="small"
              aria-label="more"
              aria-haspopup="true"
              onClick={() => handleRemove(index)}
            >
              <CloseOutlined />
            </IconButton>
          </Box>
        ))}
      </Box>
    </>
  );
}

export default SelectFilesToUpload;

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
