import { Box, CircularProgress, Typography } from "@mui/material";

function AppInit() {
  return (
    <Box
      sx={{
        position: "fixed",
        width: "100%",
        height: "100%",
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box>
        <CircularProgress />
        <br />
        <Typography>Loading...</Typography>
      </Box>
    </Box>
  );
}

export default AppInit;
