import * as Yup from "yup";
import { Formik } from "formik";
import {
  Button,
  TextField,
  Typography,
  CircularProgress,
  Card,
  Box,
  LinearProgress,
  Stack,
} from "@mui/material";
import useRefMounted from "src/hooks/useRefMounted";
import { useTranslation } from "react-i18next";
import {
  ArrowBackIos,
  CorporateFareTwoTone,
  SaveTwoTone,
} from "@mui/icons-material";
import { Notify } from "notiflix";
import axiosInstance from "src/utils/axiosInstance";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { LoadingButton } from "@mui/lab";
import { Link } from "react-router-dom";

function CreateOrg({ page }) {
  const isMountedRef = useRefMounted();
  const navigator = useNavigate();
  let { organisationId } = useParams();
  const { t } = useTranslation();
  const [formInput, setFormInput] = useState({});
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (e) => {
    setFormInput((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    if (organisationId) {
      (async () => {
        try {
          const resp = await axiosInstance.get(
            `/organisations/${organisationId}`
          );
          if (resp?.status === 200) {
            setFormInput(resp?.data?.data);
          }
          setLoading(false);
        } catch (error) {
          console.error(error);
          setLoading(false);
          Notify.failure(
            error.response?.data?.message ||
              error.response?.statusText ||
              "an error occured"
          );
        }
      })();
    }
    setLoading(false);
  }, [organisationId]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsSubmitting(true);
      if (!formInput?.title) return Notify.warning("Company name is required");
      if (!formInput?.companyEmail)
        return Notify.warning("Company email is required");
      if (!formInput?.companyPhone)
        return Notify.warning("Company phone is required");
      if (organisationId) {
        const resp = await axiosInstance.put(
          `/organisations/${organisationId}`,
          formInput
        );
        if (resp?.status === 200) {
          Notify.success(resp?.data?.message || "success");
        }
      } else {
        const resp = await axiosInstance.post(`/organisations`, formInput);
        if (resp?.status === 201) {
          Notify.success("Organisation created successfully");
          setFormInput({});
          navigator(`/dashboard/organisations/${resp?.data?._id}`);
        }
      }
      setIsSubmitting(false);
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  return (
    <Card sx={{ m: 2 }}>
      {loading ? (
        <LinearProgress sx={{ m: 2 }} />
      ) : (
        <Stack sx={{ p: 2 }} spacing={2}>
          <TextField
            fullWidth
            label={t("Company Name")}
            name="title"
            onChange={handleInputChange}
            value={formInput?.title || ""}
          />
          <TextField
            fullWidth
            label={t("Company Email address")}
            name="companyEmail"
            onChange={handleInputChange}
            type="email"
            value={formInput?.companyEmail || ""}
          />
          <TextField
            fullWidth
            label={t("Company Contact Number")}
            name="companyPhone"
            onChange={handleInputChange}
            value={formInput?.companyPhone || ""}
          />
          <TextField
            fullWidth
            label={t("Company Address")}
            rows={4}
            name="companyAddress"
            onChange={handleInputChange}
            value={formInput?.companyAddress || ""}
          />

          <TextField
            fullWidth
            label={t("Company Website")}
            name="companyWebsite"
            onChange={handleInputChange}
            value={formInput?.companyWebsite || ""}
          />

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              {organisationId && (
                <Button
                  startIcon={<ArrowBackIos />}
                  variant="text"
                  component={Link}
                  to={`/dashboard/organisations/${organisationId}`}
                >
                  {t("Back to Profile")}
                </Button>
              )}
            </Box>
            <LoadingButton
              sx={{
                mt: 3,
              }}
              color="primary"
              startIcon={<SaveTwoTone />}
              disabled={isSubmitting}
              type="submit"
              size="large"
              variant="contained"
              onClick={handleFormSubmit}
            >
              {t("Create Orgnisation")}
            </LoadingButton>
          </Box>
        </Stack>
      )}
    </Card>
  );
}

export default CreateOrg;
