import { Suspense, lazy } from "react";
import { Navigate } from "react-router";

import SuspenseLoader from "src/components/SuspenseLoader";
import ResetPassword from "src/content/pages/Auth/ResetPassword";
import VerifyAccount from "src/content/pages/VerifyAccount";
import { AuthProvider } from "src/contexts/AuthContext";

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const LoginBasic = Loader(
  lazy(() => import("src/content/pages/Auth/Login/Basic"))
);

const RegisterBasic = Loader(
  lazy(() => import("src/content/pages/Auth/Register/Basic"))
);

const RecoverPassword = Loader(
  lazy(() => import("src/content/pages/Auth/RecoverPassword"))
);

const authRoutes = [
  {
    path: "",
    element: <Navigate to="/auth/login" replace />,
  },
  {
    path: "login",
    element: (
      <AuthProvider>
        <LoginBasic />
      </AuthProvider>
    ),
  },
  {
    path: "register",
    element: (
      <AuthProvider>
        <RegisterBasic />
      </AuthProvider>
    ),
  },
  {
    path: "recover-password",
    element: <RecoverPassword />,
  },
  {
    path: "reset-password",
    children: [
      {
        path: ":token",
        element: <ResetPassword />,
      },
    ],
  },

  {
    path: "verify",
    children: [
      {
        path: ":token",
        element: <VerifyAccount />,
      },
    ],
  },
];

export default authRoutes;
