import { ArrowBackTwoTone, Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Confirm, Notify } from "notiflix";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import EmptyHere from "src/components/EmptyHere";
// import { GlobalContext } from "src/contexts/GlobalContext";
import axiosInstance from "src/utils/axiosInstance";
import { FORM_TYPE } from "src/utils/constants";
import { refineText } from "src/utils/helpers";
import NewFormDrawer from "./components/NewFormDrawer";
import QuestionInputField from "../common/QuestionInputField";
import { Link } from "react-router-dom";
import SuspenseLoader from "src/components/SuspenseLoader";

export default function AddEditForm() {
  const { formType } = useParams();
  const [formInputs, setFormInputs] = useState({
    type: "",
    inputs: [],
  });
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!formType) {
      Notify.failure("Form type not found");
      return;
    }
    (async () => {
      setLoading(true);
      const resp = await axiosInstance.get("/tag-manager/inputs", {
        params: {
          type: FORM_TYPE.FRAUD_REPORT,
        },
      });
      if (resp?.status === 200) {
        setFormInputs(
          resp?.data?.data || {
            type: formType,
            inputs: [],
          },
        );
      }
      setLoading(false);
    })();
  }, [formType]);

  const handleSubmit = async (reqData) => {
    try {
      reqData.type = formType;
      reqData.inputs = JSON.stringify(reqData.inputs);
      reqData.tags = JSON.stringify(reqData.tags);

      const resp = await axiosInstance.put(`/tag-manager`, reqData);
      if (resp.status === 200) {
        const data = resp?.data?.data || {};
        setFormInputs(
          resp?.data?.data || {
            type: data?.type || formType,
            inputs: data?.inputs || [],
            tags: data?.tags || [],
          },
        );
      }
      return true;
    } catch (error) {
      console.log(error);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured",
      );
      return false;
    }
  };

  return (
    <Box m={3}>
      <Stack spacing={4}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography variant="h3" component="h3" gutterBottom>
              Edit {refineText(formType || "")} form
            </Typography>
            <Typography variant="subtitle2">
              Add or remove fields for {refineText(formType || "")} form
            </Typography>
          </Box>{" "}
          <Button
            sx={{
              mt: { xs: 2, sm: 0 },
            }}
            component={Link}
            startIcon={<ArrowBackTwoTone />}
            to={`/dashboard/reports`}
          >
            Go back to reports
          </Button>
        </Box>

        {loading ? (
          <SuspenseLoader />
        ) : (
          <>
            <Stack spacing={2}>
              {formType === FORM_TYPE.FRAUD_REPORT && (
                <Card elevation={0} sx={{ p: 2 }}>
                  <Typography variant="subtitle2" component="div">
                    Please note: Title is already added by default for Fraud
                    Report form and cannot be removed or edited. You can only
                    add more fields.
                  </Typography>
                </Card>
              )}
              {formInputs?.inputs?.length > 0 ? (
                formInputs?.inputs?.map((question, index) => (
                  <Card
                    key={question?._id}
                    elevation={0}
                    sx={{
                      "&:hover": {
                        boxShadow: "0 0 10px 0 rgba(33,33,33,.2)",
                      },
                    }}
                  >
                    <CardHeader
                      title={
                        question?.title && (
                          <Typography variant="h5" component="div">
                            {question?.title || ""}
                          </Typography>
                        )
                      }
                      subheader={
                        question?.description && (
                          <Typography variant="subtitle1">
                            {question?.description || ""}
                          </Typography>
                        )
                      }
                      action={
                        <Stack direction="row" spacing={1}>
                          <NewFormDrawer
                            formData={question}
                            handleSubmit={handleSubmit}
                            formInputs={formInputs}
                          />
                          <IconButton
                            size="small"
                            color="error"
                            onClick={() => {
                              Confirm.show(
                                "Confirmation",
                                "Are you sure you want to remove this field?",
                                "Confirm",
                                "Cancel",
                                () => {
                                  const _tags = formInputs?.inputs || [];
                                  _tags.splice(index, 1);
                                  setFormInputs({
                                    ...formInputs,
                                    inputs: _tags,
                                  });
                                  handleSubmit(formInputs);
                                },
                                () => {},
                                {
                                  width: "500px",
                                  messageMaxLength: 1000,
                                },
                              );
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </Stack>
                      }
                    />
                    <CardContent sx={{ py: 0 }}>
                      <QuestionInputField
                        question={question}
                        handleFormInput={() => {}}
                        formInput={{}}
                        setFormInput={() => {}}
                      />
                    </CardContent>
                  </Card>
                ))
              ) : (
                <EmptyHere message="No fields added yet" />
              )}
            </Stack>

            <NewFormDrawer
              formInputs={formInputs}
              setFormInputs={setFormInputs}
              handleSubmit={handleSubmit}
            />
          </>
        )}
      </Stack>
    </Box>
  );
}
