import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

export default function ImageAttachments({attachments}) {
  return (
    <ImageList sx={{ width: "100%", height: 'auto' }} cols={1} rowHeight={164}>
      {attachments.map((item) => (
        <ImageListItem key={item}>
          <img
            // srcSet={item}
            src={item}
            alt={"message attachment"}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}
