import {
  Box,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  capitalize,
  useTheme,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import {
  ArrowForwardIos,
  Check,
  PhoneAndroid,
  Place,
} from "@mui/icons-material";
import { getImageUrl, roleFormator } from "src/utils/helpers";
import UserCard from "src/components/Common/UserCard";
import ValidationCard from "src/components/Common/ValidationCard";
import { DotLegend } from "src/components/CommonStyled";

export default function AdminUsersListCard({ item, selectedTab }) {
  const theme = useTheme();
  return (
    <TableRow
      sx={{
        color: item?.isLocked && "red",
        textDecoration: item?.isLocked && "line-through",
        "&:last-child td, &:last-child th": { border: 0 },
      }}
    >
      <TableCell>
        <UserCard
          avatar={getImageUrl(item?.profileImage || "")}
          title={item?.firstName + " " + item?.lastName}
        />
      </TableCell>
      <TableCell style={{ width: "25%" }}>
        <ValidationCard
          title={item?.email}

          // icon={
          //   <Check
          //     color={item?.verified ? "success" : "error"}
          //     fontSize="small"
          //   />
          // }
        />
      </TableCell>
      <TableCell>
        {item?.phoneNumber ? (
          <Tooltip
            title={
              "Phone Number " +
              (item?.phoneVerified ? "verified" : "not verified")
            }
          >
            <Box>
              <ValidationCard
                title={"+91-" + item?.phoneNumber}
                icon={
                  <Check
                    color={item?.phoneVerified ? "success" : "error"}
                    fontSize="small"
                  />
                }
              />
            </Box>
          </Tooltip>
        ) : (
          "-"
        )}
      </TableCell>
      <TableCell>
        {selectedTab === "vendor"
          ? roleFormator(item?.role)
          : capitalize(item?.accountType || "User")}
      </TableCell>{" "}
      <TableCell>
        <Stack direction="row" alignItems="center">
          {["responder", "vendor"]?.includes(item?.accountType) && (
            <Tooltip
              title={item?.isOnline ? "Available" : "Not Available right now"}
            >
              <IconButton>
                <DotLegend
                  sx={{
                    background: `${theme.colors[item?.isOnline ? "success" : "error"].main}`,
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip
            title={
              item?.deviceToken
                ? "Device Token Available"
                : "No Device Token: User will not receive notifications/calls"
            }
          >
            <IconButton>
              <PhoneAndroid
                color={item?.deviceToken ? "success" : "secondary"}
              />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={
              item?.address?.latitude && item?.address?.longitude
                ? "Geo Location Available"
                : "No Geo Location"
            }
          >
            <IconButton
              component={
                item?.address?.latitude && item?.address?.longitude
                  ? "a"
                  : "div"
              }
              href={`https://www.google.com/maps?q=${item?.address?.latitude},${item?.address?.longitude}`}
              target="_blank"
              rel="noreferrer"
            >
              <Place
                color={
                  item?.address?.latitude && item?.address?.longitude
                    ? "success"
                    : "secondary"
                }
              />
            </IconButton>
          </Tooltip>
        </Stack>
      </TableCell>
      <TableCell align="right">
        <Link
          to={`/dashboard/users/${item?._id}`}
          style={{
            textDecoration: "none",
          }}
        >
          <IconButton
            variant="outlined"
            color="primary"
            size="small"
            sx={{ mr: 1 }}
          >
            <ArrowForwardIos />
          </IconButton>
        </Link>
      </TableCell>
    </TableRow>
  );
}
