import {
  Box,
  Typography,
  Card,
  CardHeader,
  Divider,
  styled,
  Stack,
  Chip,
  Grid,
  alpha,
  useTheme,
  capitalize,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  TableContainer,
  Table,
  Paper,
  CardContent,
  IconButton,
} from "@mui/material";
import { useParams } from "react-router";
import axiosInstance from "src/utils/axiosInstance";
import { Notify } from "notiflix";
import { useCallback, useEffect, useState } from "react";
import SuspenseLoader from "src/components/SuspenseLoader";
import EmptyHere from "src/components/EmptyHere";
import CampaignBulkActions from "../components/CampaignBulkActions";
import { DoneTwoTone, RefreshOutlined } from "@mui/icons-material";
import { fDateTimeSuffix } from "src/utils/date-fns";
import { LoadingButton } from "@mui/lab";
import { DataGrid } from "@mui/x-data-grid";

function CampaignNotificationDetails() {
  const theme = useTheme();
  let { campaignId } = useParams();
  const [loading, setLoading] = useState(true);
  const [silentLoading, setSilentLoading] = useState(false);
  const [campaign, setCampaign] = useState(null);

  const getDetails = useCallback(async (silentCall = false) => {
    try {
      if (silentCall) {
        setSilentLoading(true);
      } else {
        setLoading(true);
      }
      const resp = await axiosInstance.get(`/campaigns/${campaignId}`);
      if (resp?.status === 200) {
        setCampaign(resp?.data?.data || {});
        if (silentCall) {
          Notify.success("Campaign stats refreshed.");
        }
      }
    } catch (error) {
      console.error(error);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured",
      );
    } finally {
      if (silentCall) {
        setSilentLoading(false);
      } else {
        setLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    getDetails();
  }, [campaignId]);

  return loading ? (
    <SuspenseLoader />
  ) : (
    <Stack my={2} mx={3}>
      {campaign ? (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Card>
                <Box p={3}>
                  <Typography
                    variant="h1"
                    sx={{
                      pb: 1,
                    }}
                  >
                    {campaign?.title || ""}{" "}
                  </Typography>
                </Box>

                <Divider />
                <CardHeader
                  titleTypographyProps={{ variant: "h4", mb: 1 }}
                  subheaderTypographyProps={{ variant: "subtitle2" }}
                  title={campaign?.data?.title}
                  subheader={campaign?.data?.body}
                  sx={{ mx: 1 }}
                />
              </Card>
              <Card
                sx={{ my: 2, backgroundColor: "transparent" }}
                elevation={0}
              >
                <CardHeader title="Audience" />
                <Divider />
                {campaign?.category === "manual" ? (
                  <>
                    <Box sx={{ maxHeight: "60vh", overflow: "auto" }}>
                      {campaign?.users?.map((user, idx) => (
                        <Card sx={{ my: 2 }} key={idx}>
                          <CardHeader
                            title={user?.firstName + " " + user?.lastName}
                            action={user?.email}
                          />
                        </Card>
                      ))}
                    </Box>
                    {campaign?.users?.length === 0 && (
                      <Card sx={{ my: 2 }}>
                        <EmptyHere
                          title={"No audience have added to this campaign."}
                        />
                      </Card>
                    )}
                  </>
                ) : (
                  <>
                    <Card
                      elevation={0}
                      sx={{
                        background: `${alpha(theme.colors.alpha.black[100], 0.05)}`,
                        width: "100%",
                      }}
                    >
                      <CardHeader
                        title="Categories"
                        action={campaign?.accountTypes?.map((value) => (
                          <Chip
                            color={"success"}
                            variant={"filled"}
                            icon={<DoneTwoTone />}
                            label={<b>{capitalize(value)}</b>}
                            key={value}
                            sx={{ m: 0.5 }}
                          />
                        ))}
                      />
                    </Card>
                    {campaign?.accountTypes?.includes("vendor") && (
                      <Card
                        elevation={0}
                        sx={{
                          background: `${alpha(theme.colors.alpha.black[100], 0.05)}`,
                          width: "100%",
                        }}
                      >
                        <CardHeader
                          title="Specialists"
                          action={campaign?.roles?.map((value) => (
                            <Chip
                              color={"success"}
                              variant={"filled"}
                              icon={<DoneTwoTone />}
                              label={<b>{capitalize(value)}</b>}
                              key={value}
                              sx={{ m: 0.5 }}
                            />
                          ))}
                        />
                      </Card>
                    )}
                  </>
                )}
              </Card>
              {/* stats */}

              <Card>
                <Box display={"flex"} alignItems={"center"}>
                  <CardHeader title="Campaign Stats" />
                  {campaign?.stats?.length !== 0 && (
                    <IconButton
                      title="Refresh campaign data"
                      color="primary"
                      onClick={() => getDetails(true)}
                    >
                      <RefreshOutlined />
                    </IconButton>
                  )}
                </Box>
                <Divider />
                {campaign?.stats?.length === 0 && (
                  <CardContent
                    sx={{
                      p: 3,
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography align="center">
                      No stats found, Seems like you haven't send campaign yet
                      or try refreshing by given below button
                    </Typography>
                    <LoadingButton
                      variant="outlined"
                      sx={{ mt: 1 }}
                      size="small"
                      loading={silentLoading}
                      startIcon={<RefreshOutlined />}
                      onClick={() => getDetails(true)}
                    >
                      Refresh
                    </LoadingButton>
                  </CardContent>
                )}
                <Box sx={{ height: 370, width: "100%" }}>
                  <DataGrid
                    sx={{
                      width: "100%",
                      borderTopLeftRadius: 8,
                      borderTopRightRadius: 8,
                      overflow: "hidden",
                      ".MuiDataGrid-columnHeader": {
                        width: "100%",
                      },
                    }}
                    rows={campaign?.stats?.map((each) => {
                      each.id = each._id;
                      return each;
                    })}
                    columns={[
                      {
                        field: "sendAt",
                        headerName: "Send At",
                        width: 180,
                        flex: 1,
                        valueGetter: (val, row) =>
                          fDateTimeSuffix(row?.campaignedAt),
                      },
                      {
                        field: "successCount",
                        headerName: "Success Count",
                        width: 180,
                      },
                      {
                        field: "failureCount",
                        headerName: "Failure Count",
                        width: 180,
                      },
                    ]}
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                      },
                    }}
                    pageSizeOptions={[5, 10]}
                  />
                </Box>
                {/* end stats */}
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <CampaignBulkActions
                campaign={campaign}
                onSend={() => getDetails(true)}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <EmptyHere title={"No campaign found"} />
      )}
    </Stack>
  );
}

export default CampaignNotificationDetails;
