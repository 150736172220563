import { useRef } from "react";
import { Quill } from "react-quill";
import ReactDOMServer from "react-dom/server";
import QuillEditorToolbarStyle from "./QuillEditorToolbarStyle";
import { Icon } from "@mui/material";

// ----------------------------------------------------------------------

const FONT_FAMILY = ["Roboto", "Raleway", "Montserrat", "Lato", "Rubik"];

const FONT_SIZE = [
  "8px",
  "9px",
  "10px",
  "12px",
  "14px",
  "16px",
  "20px",
  "24px",
  "32px",
  "42px",
  "54px",
  "68px",
  "84px",
  "98px",
];
const HEADINGS = [
  "Heading 1",
  "Heading 2",
  "Heading 3",
  "Heading 4",
  "Heading 5",
  "Heading 6",
];

const Size = Quill.import("attributors/style/size");
Size.whitelist = FONT_SIZE;
Quill.register(Size, true);

const Icons = Quill.import("ui/icons");
function renderIcon(icon) {
  return ReactDOMServer.renderToString(
    <Icon width={18} height={18}>
      {icon}
    </Icon>
  );
}

Icons.bold = renderIcon("format_bold");
Icons.italic = renderIcon("format_italic");
Icons.underline = renderIcon("format_underlined");
Icons.strike = renderIcon("strikethrough_s");
Icons.link = renderIcon("link");
Icons.image = renderIcon("image");
Icons.list.bullet = renderIcon("format_list_bulleted");
Icons.list.ordered = renderIcon("format_list_numbered");
Icons.indent["+1"] = renderIcon("format_indent_increase");
Icons.indent["-1"] = renderIcon("format_indent_decrease");
Icons.color = renderIcon("format_color_text");
Icons.background = renderIcon("format_color_fill");
Icons.video = renderIcon("movie");
Icons.align[""] = renderIcon("format_align_left");
Icons.align.center = renderIcon("format_align_center");
Icons.align.justify = renderIcon("format_align_justify");
Icons.align.right = renderIcon("format_align_right");

export const formats = [
  "align",
  "background",
  "bold",
  "bullet",
  "color",
  "header",
  "image",
  "indent",
  "italic",
  "link",
  "list",
  "size",
  "strike",
  "underline",
  "video",
];

export default function QuillEditorToolbar({ id, imageHandler, ...other }) {
  const fileInputRef = useRef(null);

  const handleClickAttach = (e) => {
    fileInputRef.current.click();
  };

  return (
    <QuillEditorToolbarStyle {...other}>
      <div id={id}>
        <div className="ql-formats">
          <select className="ql-header" defaultValue="">
            {HEADINGS.map((heading, index) => (
              <option key={heading} value={index + 1}>
                {heading}
              </option>
            ))}
            <option value="">Normal</option>
          </select>

          <select className="ql-size" defaultValue="16px">
            {FONT_SIZE.map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>

        <div className="ql-formats">
          <button type="button" className="ql-bold" />
          <button type="button" className="ql-italic" />
          <button type="button" className="ql-underline" />
          <button type="button" className="ql-strike" />
        </div>

        <div className="ql-formats">
          <select className="ql-color" />
          <select className="ql-background" />
        </div>

        <div className="ql-formats">
          <button type="button" className="ql-list" value="ordered" />
          <button type="button" className="ql-list" value="bullet" />

          <button type="button" className="ql-indent" value="-1" />
          <button type="button" className="ql-indent" value="+1" />

          <select className="ql-align" />
        </div>

        <div className="ql-formats">
          <button type="button" className="ql-video" />
          <Icon
            onClick={handleClickAttach}
            style={{
              cursor: "pointer",
            }}
          >
            image
          </Icon>
          <input
            type="file"
            onChange={(e) => {
              imageHandler(e.target.files[0]);
            }}
            ref={fileInputRef}
            style={{ display: "none" }}
          />
        </div>
      </div>
    </QuillEditorToolbarStyle>
  );
}
