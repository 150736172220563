import PageTitleWrapper from "src/components/PageTitleWrapper";

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Stack,
} from "@mui/material";

import Block1 from "./Block1";
import Block2 from "./Block2";
import ActivityLogs from "./ActivityLogs";
import Block3 from "./Block3";
import { useContext, useEffect, useState } from "react";
import axiosInstance from "src/utils/axiosInstance";
import { Notify } from "notiflix";
import SuspenseLoader from "src/components/SuspenseLoader";
import { GlobalContext } from "src/contexts/GlobalContext";
import UsersStatsCard from "../users/components/UsersStatsCard";
import TicketStats from "../Tickets/components/TicketStats";
import AppointmentStats from "../appointments/AppointmentList/AppointmentStats";
import OrdersStat from "../Orders/components/OrdersStat";
import CallingStats from "../CallRecordings/components/CallingStats";
import { useNavigate } from "react-router";

function DashboardAnalytics() {
  const navigate = useNavigate();
  const { handleGetStats } = useContext(GlobalContext);
  const [stat, setStat] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get(`/dashboard`);
        if (response.status === 200) {
          setStat(response.data || null);
        }
        await handleGetStats();
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
        Notify.failure(
          error.response?.data?.message ||
            error.response?.statusText ||
            "an error occured",
        );
      }
    })();
  }, []);

  return (
    <Box>
      <PageTitleWrapper>
        <Block1 />
      </PageTitleWrapper>
      {loading ? (
        <SuspenseLoader />
      ) : (
        <Grid
          sx={{
            px: 4,
          }}
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={4}
        >
          <Grid item xs={12}>
            <OrdersStat whichPage="dashboard" />
            <Box sx={{ mt: 4 }}>
              <UsersStatsCard whichPage="dashboard" />
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box sx={{ mb: 4 }}>
              <TicketStats
                handleClick={(val) =>
                  navigate(`/dashboard/tickets${val ? "?status=" + val : ""}`)
                }
              />
            </Box>
            <Block2 stats={stat} />

            <Card>
              <CardHeader title="Appointments Analytics" />
              <Divider />
              <CardContent>
                <AppointmentStats
                  whichPage={"dashboard"}
                  handleClick={(val) =>
                    navigate(
                      `/dashboard/appointments${val ? "?status=" + val : ""}`,
                    )
                  }
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Block3 stat={stat} />
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={4}>
              <Card>
                <CardHeader title="Calling Stats" />
                <Divider />
                <CardContent>
                  <CallingStats
                    handleStatus={(val) =>
                      navigate(
                        `/dashboard/call-history${val ? "?status=" + val : ""}`,
                      )
                    }
                    handleType={(val) =>
                      navigate(
                        `/dashboard/call-history${val ? "?type=" + val : ""}`,
                      )
                    }
                  />
                </CardContent>
              </Card>
              <ActivityLogs />
            </Stack>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

export default DashboardAnalytics;
