import { Avatar, Box, Typography } from '@mui/material';
import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import SuspenseLoader from 'src/components/SuspenseLoader';
import axiosInstance from 'src/utils/axiosInstance';

const RecommendUsers = ({userIds}) => {

  const [isLoading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);

  const fetchUserDetails = async () => {
    try {
      const res = await axiosInstance.post('/users/ids', {
        userIds
      });
      setUsers(res?.data?.data || []);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserDetails();
    return () => {
      setLoading(false);
      setUsers([]);
    };
  }, []);

  if (isLoading) {
    return (
      <SuspenseLoader />
    );
  }

  console.log(users)
  return users.map(user => (
    <Link to={'/dashboard/users/'+user?._id} style={{display: 'flex', alignItems: 'center'}}>
      <Avatar sx={{width: 64, height: 64}} src={user?.profileImage ? process.env.REACT_APP_DO_SPACE_URL + user?.profileImage : "/static/images/avatars/6.png"} variant="circular" />
      <Box sx={{paddingLeft: 1}}>
        <Typography component={"h2"} >{user?.firstName} {user?.lastName}</Typography>
        <Typography sx={{
          fontSize: 12,
          fontWeight: 'bold',
          textTransform: 'capitalize'
        }}>{user?.accountType}</Typography>
        <Typography sx={{fontSize: 10}}>{Math.floor(user?.duration / 60)} mins / session</Typography>
      </Box>
    </Link>
  ));
};

export default RecommendUsers;
