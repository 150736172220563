import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import { Avatar, Box, Button, IconButton, Typography } from "@mui/material";
import { CloseOutlined, CloudUploadOutlined } from "@mui/icons-material";

function FileUpload({
  multiple = true,
  content = "Upload Files",
  onSubmit,
  imageSx,
}) {
  const schema = yup
    .object({
      uploadedFiles: yup.array().required("Please select image."),
    })
    .required();
  const {
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    multiple: multiple,
    accept: {
      "image/*": [".jpeg", ".png", ".jpg"],
    },
    onDrop: (acceptedFiles) => {
      if (errors.uploadedFiles) {
        clearErrors("uploadedFiles");
      }
      setValue("uploadedFiles", acceptedFiles);
      if (multiple) {
        setFiles([
          ...files,
          ...acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            }),
          ),
        ]);
      } else {
        setFiles([
          ...acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            }),
          ),
        ]);
      }
    },
  });

  const handleRemove = (path) => {
    const filteredFiles = files.filter((file) => file.path !== path);
    setFiles(filteredFiles);
    setValue("uploadedFiles", filteredFiles.length ? filteredFiles : null);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        {...getRootProps({
          sx: {
            borderWidth: "2px",
            borderStyle: "dashed",
            borderColor: errors.uploadedFiles ? "#e63757" : "grey.300",
            borderRadius: 1,
            height: "100%",
            cursor: "pointer",
            padding: 2,
            textAlign: "center",
          },
        })}
      >
        <input
          name="uploadedFiles"
          {...getInputProps({ multiple: multiple })}
        />
        <Box display={"flex"} alignItems="center">
          <CloudUploadOutlined />
          <Typography sx={{ ml: 1 }}>{content}</Typography>
        </Box>
      </Box>
      <Box>
        {files.map((file) => (
          <Box
            display={"flex"}
            alignItems="center"
            sx={{
              p: 1,
              mt: 1,
              backgroundColor: "ButtonHighlight",
              borderRadius: 1,
            }}
            key={file.path}
          >
            <Avatar
              variant="rounded"
              src={file.preview}
              alt={file.path}
              sx={{
                width: 40,
                height: 40,
                ...imageSx,
              }}
            />

            <Box
              display={"flex"}
              justifyContent="between"
              alignItems="center"
              sx={{ ml: 1, flex: 1 }}
            >
              {file.path}
            </Box>
            <IconButton
              size="small"
              aria-label="more"
              aria-haspopup="true"
              onClick={() => handleRemove(file.path)}
            >
              <CloseOutlined />
            </IconButton>
          </Box>
        ))}
      </Box>
      <Box display={"flex"} alignItems={"center"}>
        <Button
          size="small"
          variant="outlined"
          type="submit"
          sx={{ mt: 1, mr: 1 }}
          disabled={files?.length === 0}
        >
          Upload now
        </Button>
        <Typography color={"#e63757"}>
          {errors.uploadedFiles?.message}
        </Typography>
      </Box>
    </form>
  );
}

export default FileUpload;
