import axios from "axios";
import { Notify } from "notiflix";
import { createContext } from "react";
import axiosInstance from "src/utils/axiosInstance";

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const login = async (obj) => {
    try {
      const resp = await axios.post(
        process.env.REACT_APP_API_URL + "/auth/login",
        obj
      );
      if (resp.status === 200) {
        // TODO: remove it later
        if (resp?.data?.user?.accountType !== "admin") {
          Notify.failure("You are not authorized to access");
          return;
        }

        localStorage.setItem("user", JSON.stringify(resp.data?.user));
        localStorage.setItem(
          "accessToken",
          JSON.stringify(resp.data?.accessToken)
        );
        localStorage.setItem(
          "refreshToken",
          JSON.stringify(resp.data?.refreshToken)
        );

        if (resp?.data?.requiresPasswordReset) {
          localStorage.setItem(
            "requiresPasswordReset",
            JSON.stringify(resp.data?.requiresPasswordReset)
          );
          Notify.info("Please change your password");
        } else Notify.success("Logged in successfully");

        setTimeout(() => {
          window.location.replace(
            resp?.data?.requiresPasswordReset
              ? "/dashboard/account/change-password?q=verify"
              : "/"
          );
        }, 1000);
      }
    } catch (error) {
      console.log(error);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  const register = async (obj) => {
    try {
      const resp = await axiosInstance.post("/auth/register", obj);
      if (resp.status === 201) {
        Notify.success("Registered successfully. Please login.");
        return resp;
      }
    } catch (error) {
      console.log(error);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
      return null;
    }
  };

  const registerOrganisation = async (obj) => {
    try {
      const resp = await axiosInstance.post("/organisations", obj);
      return resp;
    } catch (error) {
      console.log(error);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
      return null;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        // methods
        login,
        register,
        registerOrganisation,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
