import {
  ConfirmationNumber,
  LocalActivity,
  TaskAlt,
} from "@mui/icons-material";
import { alpha, Grid, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import StatCard2 from "src/components/Statistics/StatCard2";
import { GlobalContext } from "src/contexts/GlobalContext";

export default function TicketStats({ status, handleClick = () => {} }) {
  const theme = useTheme();
  const { stats, handleGetStats } = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);
  const { ticketsStats } = stats || {};
  const [ticketsByStatus, setTicketsByStatus] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        await handleGetStats();
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (stats) {
      const _ticketsByStatus = ticketsStats?.ticketsByStatus?.reduce(
        (acc, item) => {
          acc[item._id] = item;
          return acc;
        },
        {},
      );
      setTicketsByStatus(_ticketsByStatus);
    }
  }, [stats]);

  return (
    !loading && (
      <Grid container spacing={2}>
        {[
          {
            label: "Total Tickets",
            color: "primary",
            count: ticketsStats?.totalTickets || 0,
            icon: <ConfirmationNumber />,
            value: null,
          },
          {
            label: "Open Tickets",
            color: "warning",
            count: ticketsByStatus?.open?.count || 0,
            icon: <LocalActivity />,
            value: "open",
          },
          {
            label: "Closed Tickets",
            color: "success",
            count: ticketsByStatus?.closed?.count || 0,
            icon: <TaskAlt />,
            value: "closed",
          },
        ]?.map((item, idx) => (
          <Grid item xs={12} md={4} key={idx}>
            <StatCard2
              item={item}
              onClick={() => handleClick(item.value)}
              sx={{
                backgroundColor:
                  status === item.value
                    ? alpha(theme.palette[item.color]?.main, 0.1)
                    : null,
              }}
            />
          </Grid>
        ))}
      </Grid>
    )
  );
}
