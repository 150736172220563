import { Box, CircularProgress, Typography } from "@mui/material";

function SuspenseLoader() {
  return (
    <Box
      sx={{
        position: "fixed",
        left: "10%",
        width: "100%",
        height: "100%",
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box textAlign="center">
        <CircularProgress size={50} thickness={3} />
        <Typography variant="h6" color="textSecondary" ml={2}>
          Please wait...
        </Typography>
      </Box>
    </Box>
  );
}

export default SuspenseLoader;
