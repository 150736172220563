import { PlayCircleOutline } from "@mui/icons-material";
import React, { useState } from "react";
import ReactPlayer from "react-player";
import { Box, Modal, Typography, IconButton, Divider } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

export default function CallRecordingPreview({ item }) {
  const [show, setShow] = useState(false);
  const handleChange = (value) => {
    setShow(value);
  };

  return (
    <>
      <IconButton
        onClick={() => handleChange(true)}
        variant="outlined"
        color="primary"
        size="small"
        sx={{ mr: 1 }}
      >
        <PlayCircleOutline />
      </IconButton>
      {show && (
        <Modal open={show} onClose={() => handleChange(false)}>
          <Box sx={{ ...style }}>
            <Box>
              <Typography variant="h3" sx={{ mb: 2 }}>
                {item?.name || ""}'s call recording
              </Typography>
            </Box>
            <Divider sx={{ my: 2 }} />
            <Box className="player-wrapper">
              <ReactPlayer
                className="react-player"
                url={process.env.REACT_APP_DO_SPACE_URL + item?.url}
                width={item?.type === "video" ? "100%" : "800px"}
                height={item?.type === "video" ? "80vh" : "50px"}
                controls
              />
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
}
