import React, { useEffect } from "react";
import { Card, Box, CardHeader, CardContent, Typography } from "@mui/material";
import UserForm from "../common/UserForm";
import { useParams } from "react-router";

export default function AddOwner() {
  const { organisationId } = useParams();

  return (
    <Box mx={3}>
      {organisationId ? (
        <Card sx={{ my: 2 }}>
          <CardHeader
            title="Add a new Owner"
            subheader="Add a new Owner to your organisation directly"
          />
          <CardContent>
            <UserForm
              accountType="owner"
              role="owner"
              organisationId={organisationId}
            />
          </CardContent>
        </Card>
      ) : (
        <Typography variant="h5" component="h2" color="error">
          Organisation Id not found
        </Typography>
      )}
    </Box>
  );
}
