import {
  Box,
  Button,
  IconButton,
  InputBase,
  LinearProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import axiosInstance from "src/utils/axiosInstance";
import EditIcon from "@mui/icons-material/Edit";
import { PAGE_LIMIT } from "src/utils/constants";
import { ArrowForwardIos, CorporateFareTwoTone } from "@mui/icons-material";

export default function Organisations() {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get("page");
  const [filterObj, setFilterObj] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const [templates, setTemplates] = React.useState([]);
  const [totalPages, setTotalPages] = React.useState(1);
  const [page, setPage] = React.useState(parseInt(pageParam) || 1);
  const [searchString, setSearchString] = React.useState("");

  const handlePageChange = (event, value) => {
    setPage(value + 1);
    setSearchParams({ page: value + 1 });
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const reqParams = {
        params: { limit: PAGE_LIMIT, skip: (page - 1) * PAGE_LIMIT },
      };

      if (filterObj) {
        reqParams.params = { ...reqParams.params, ...filterObj };
      }
      const resp = await axiosInstance.get("/organisations", reqParams);
      if (resp?.status === 200) {
        setTemplates(resp?.data?.data);
        setTotalPages(resp?.data?.totalPages || 1);
      }
      setLoading(false);
    })();
  }, [page, filterObj]);

  return (
    <Box mx={3}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ pt: 3, pb: 2 }}
      >
        <Box fullWidth>
          <Paper
            component="form"
            sx={{
              display: "flex",
              alignItems: "center",
              width: 500,
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search here..."
              inputProps={{ "aria-label": "search here" }}
              onChange={(e) => {
                if (e.target.value?.length > 0) {
                  setSearchString(e.target.value);
                } else {
                  if (filterObj?.search) {
                    setFilterObj({
                      ...filterObj,
                      search: "",
                    });
                  }
                }
              }}
            />
            <IconButton
              type="button"
              sx={{
                p: "10px",
                m: 0.2,
                bgcolor: "primary.main",
                color: "white",
              }}
              onClick={() => {
                setFilterObj({
                  ...filterObj,
                  search: searchString,
                });
              }}
            >
              <SearchIcon />
            </IconButton>
          </Paper>
        </Box>
        <Box display={"flex"}>
          <Button
            variant="contained"
            component={Link}
            to="/dashboard/organisations/create"
            startIcon={<CorporateFareTwoTone />}
          >
            Create New Organisation
          </Button>
        </Box>
      </Stack>
      <Box sx={{ my: 2 }}>
        {loading && <LinearProgress />}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Company Name</TableCell>
                <TableCell>Company Email</TableCell>
                <TableCell>Company Phone</TableCell>
                <TableCell>Company Address</TableCell>
                <TableCell>Company Website</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {templates?.map((row, idx) => (
                <TableRow
                  key={idx}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row?.title}
                  </TableCell>
                  <TableCell>{row?.companyEmail || "_"}</TableCell>
                  <TableCell>{row?.companyPhone || "_"}</TableCell>
                  <TableCell>{row?.companyAddress || "_"}</TableCell>
                  <TableCell>{row?.companyWebsite || "_"}</TableCell>
                  <TableCell align="right">
                    <Box display={"flex"} justifyContent="flex-end">
                      <IconButton
                        size="small"
                        component={Link}
                        to={`/dashboard/organisations/${row?._id}/edit`}
                        color="warning"
                      >
                        <EditIcon />
                      </IconButton>
                      <Link
                        to={`/dashboard/organisations/${row?._id}`}
                        style={{
                          textDecoration: "none",
                        }}
                      >
                        <IconButton
                          variant="outlined"
                          color="primary"
                          size="small"
                          sx={{ mr: 1 }}
                        >
                          <ArrowForwardIos />
                        </IconButton>
                      </Link>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}

              {templates?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    No organisation found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {templates?.length > 0 && (
            <TablePagination
              rowsPerPageOptions={[]}
              component="div"
              count={totalPages}
              rowsPerPage={PAGE_LIMIT}
              page={page - 1}
              onPageChange={handlePageChange}
            />
          )}
        </TableContainer>
      </Box>
    </Box>
  );
}
