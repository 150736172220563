import { LoadingButton } from "@mui/lab";
import { Box, Button, Card, Container, Stack, TextField } from "@mui/material";
import { Notify } from "notiflix";
import React, { useContext, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { GlobalContext } from "src/contexts/GlobalContext";
import axiosInstance from "src/utils/axiosInstance";

export default function ChangePassword() {
  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get("q");
  const { currentUser } = useContext(GlobalContext);

  const [formInput, setFormInput] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleOldShowPassword = () => {
    setShowOldPassword(!showOldPassword);
  };
  const handleChange = (event) => {
    event.preventDefault();
    setFormInput({ ...formInput, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      if (formInput.newPassword !== formInput.confirmNewPassword)
        return Notify.warning("Passwords do not match");

      if (query === "verify") {
        formInput.verify = true;
      }
      setLoading(true);
      const response = await axiosInstance.post(
        "/auth/update-password",
        formInput
      );
      if (response.status === 200) {
        setLoading(false);
        Notify.success("Password updated successfully");
        setFormInput(null);

        if (query === "verify") {
          localStorage.removeItem("requiresPasswordReset");
          window.location.replace("/");
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  return (
    <Container maxWidth="sm">
      <Card sx={{ m: 4, p: 3 }}>
        <Stack spacing={3} alignItems="flex-end">
          <TextField
            fullWidth
            autoComplete="on"
            type={showOldPassword ? "text" : "password"}
            label="Old Password"
            name="oldPassword"
            value={formInput?.oldPassword || ""}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <Button
                  onClick={handleOldShowPassword}
                  sx={{
                    p: 0,
                  }}
                >
                  {showOldPassword ? "Hide" : "Show"}
                </Button>
              ),
            }}
          />

          <TextField
            fullWidth
            autoComplete="on"
            type={showPassword ? "text" : "password"}
            label="New Password"
            name="newPassword"
            value={formInput?.newPassword || ""}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <Button
                  onClick={handleShowPassword}
                  sx={{
                    p: 0,
                  }}
                >
                  {showPassword ? "Hide" : "Show"}
                </Button>
              ),
            }}
          />

          <TextField
            fullWidth
            autoComplete="on"
            type="text"
            label="Confirm New Password"
            name="confirmNewPassword"
            value={formInput?.confirmNewPassword || ""}
            onChange={handleChange}
          />

          <LoadingButton
            type="submit"
            variant="contained"
            onClick={handleSubmit}
            loading={loading}
          >
            Save Changes
          </LoadingButton>
        </Stack>
      </Card>
    </Container>
  );
}
