import { Box } from "@mui/material";
import React from "react";
import ImageAttachments from "./ImageAttachments";
import RecommendUsers from "./RecommendUsers";

export default function MessageContent({ message }) {
  if (message?.content) {
    return <Box>{message?.content || ""}</Box>;
  } else if (message?.attachments) {
    // return <Box>{message?.attachments?.length || 0} Attachment</Box>;
    const imageAttachments = message.attachments.map(
      attachment => process.env.REACT_APP_DO_SPACE_URL + attachment,
    );
    return <ImageAttachments attachments={imageAttachments} />
  } else {
    return <RecommendUsers userIds={message?.recommendedusers} />
  }
}
