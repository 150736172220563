import { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";

import SuspenseLoader from "src/components/SuspenseLoader";
import BlogDetail from "src/content/dashboards/Blogs/BlogDetail";

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// Status

const Status404 = Loader(
  lazy(() => import("src/content/pages/Status/Status404"))
);
const Status500 = Loader(
  lazy(() => import("src/content/pages/Status/Status500"))
);

const baseRoutes = [
  {
    path: "",
    element: <Navigate to="/dashboard" replace />,
  },
  {
    path: "blogs",
    children: [
      {
        path: ":blogId",
        element: <BlogDetail />,
      },
    ],
  },
  {
    path: "status",
    children: [
      { path: "", element: <Navigate to="404" replace /> },
      { path: "", element: <Status404 /> },
      { path: "500", element: <Status500 /> },
    ],
  },
  { path: "*", element: <Status404 /> },
];

export default baseRoutes;
