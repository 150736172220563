import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  InputBase,
  Pagination,
  Paper,
  Stack,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  capitalize,
} from "@mui/material";
import axiosInstance from "src/utils/axiosInstance";
import SuspenseLoader from "src/components/SuspenseLoader";
import { FORM_TYPE, PAGE_LIMIT } from "src/utils/constants";
import { Link, useSearchParams } from "react-router-dom";
import { Notify } from "notiflix";
import EmptyHere from "src/components/EmptyHere";
import { ArrowForwardIos, Edit, Search } from "@mui/icons-material";
import { fDateTimeSuffix } from "src/utils/date-fns";

export default function Reports({ userId, whichPage }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get("page");
  const [reports, setReports] = useState([]);
  const [filterObj, setFilterObj] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(parseInt(pageParam) || 1);
  const [searchString, setSearchString] = useState("");

  const handlePageChange = (event, value) => {
    setPage(value);
    setSearchParams({ page: value });
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const reqParams = {
          params: { limit: PAGE_LIMIT, skip: (page - 1) * PAGE_LIMIT },
        };

        if (filterObj) {
          reqParams.params = { ...reqParams.params, ...filterObj };
        }

        if (userId) {
          reqParams.params = { ...reqParams.params, userId };
        }

        const resp = await axiosInstance.get("/reports", reqParams);
        if (resp?.status === 200) {
          setReports(resp.data);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        Notify.failure(
          error.response?.data?.message ||
            error.response?.statusText ||
            "an error occured"
        );
      }
    })();
  }, [page, filterObj, userId]);

  return (
    <Box m={3}>
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ my: 3 }}
        >
          <Box>
            <Box fullWidth display={"flex"}>
              <Paper
                component="form"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: 500,
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search here..."
                  inputProps={{ "aria-label": "search here" }}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                      if (e.target.value.length) {
                        setFilterObj({ ...filterObj, search: searchString });
                      } else {
                        setFilterObj({ ...filterObj, search: "" });
                      }
                    }
                  }}
                  onChange={(e) => {
                    if (e.target.value?.length > 0) {
                      setSearchString(e.target.value);
                    } else {
                      if (filterObj?.search) {
                        setFilterObj({
                          ...filterObj,
                          search: "",
                        });
                      }
                    }
                  }}
                />
                <IconButton
                  type="button"
                  sx={{
                    m: 0.2,
                    bgcolor: "primary.main",
                    color: "white",
                  }}
                  onClick={() => {
                    setFilterObj({
                      ...filterObj,
                      search: searchString,
                    });
                  }}
                >
                  <Search />
                </IconButton>
              </Paper>
            </Box>
            {!whichPage && (
              <Button
                size="small"
                startIcon={<Edit />}
                sx={{
                  minWidth: 100,
                }}
                component={Link}
                to={`/dashboard/reports/${FORM_TYPE.FRAUD_REPORT}/edit`}
              >
                Edit fraud report template
              </Button>
            )}
          </Box>
          <Box display={"flex"}>
            <Autocomplete
              disablePortal
              size="small"
              id="org-box"
              options={[
                { title: "open ", value: "open" },
                { title: "Closed ", value: "closed" },
              ]}
              getOptionLabel={(option) => option?.title}
              sx={{ width: 200, ml: 2 }}
              renderInput={(params) => <TextField {...params} label="Status" />}
              onChange={(e, v) => {
                setFilterObj({
                  ...filterObj,
                  status: v?.value,
                });
              }}
            />
          </Box>
        </Stack>{" "}
        {loading ? (
          <SuspenseLoader />
        ) : reports?.data?.length > 0 ? (
          <>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Report Id</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>
                      Submitted By
                      {/* <Typography color="text.secondary">
                    <small>Organisation</small>
                  </Typography> */}
                    </TableCell>
                    {/* <TableCell>Category</TableCell> */}
                    <TableCell>Status</TableCell>
                    <TableCell>Date </TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reports?.data?.map((item, idx) => (
                    <TableRow key={idx}>
                      <TableCell>{item?.reportId}</TableCell>
                      <TableCell>{capitalize(item?.title || "")}</TableCell>
                      <TableCell>
                        {item?.createdBy?.firstName || "-"}{" "}
                        {item?.createdBy?.lastName || ""}
                        {/* <Typography color="text.secondary">
                      <small>{row?.organisation?.title || "-"}</small>
                    </Typography> */}
                      </TableCell>
                      <TableCell>
                        <Chip
                          color={
                            item?.status === "closed" ? "success" : "warning"
                          }
                          label={<b>{capitalize(item?.status || "closed")}</b>}
                          size="small"
                        />
                      </TableCell>{" "}
                      <TableCell>
                        {fDateTimeSuffix(item?.createdAt) || ""}
                      </TableCell>
                      <TableCell align="right">
                        <Link
                          to={`/dashboard/reports/${item?._id}`}
                          style={{
                            textDecoration: "none",
                          }}
                        >
                          <IconButton
                            variant="outlined"
                            color="primary"
                            size="small"
                            sx={{ mr: 1 }}
                          >
                            <ArrowForwardIos />
                          </IconButton>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {!loading && reports?.data?.length > 0 && (
              <Box sx={{ m: 3, display: "flex", justifyContent: "flex-end" }}>
                <Stack spacing={2}>
                  <Pagination
                    count={reports?.totalPages}
                    page={page}
                    onChange={handlePageChange}
                    color="primary"
                  />
                </Stack>
              </Box>
            )}
          </>
        ) : (
          <EmptyHere title="No reports found" />
        )}
      </Box>
    </Box>
  );
}
