import { styled } from "@mui/material";

export const DotLegend = styled("span")(
  ({ theme }) => `
        border-radius: 22px;
        width: ${theme.spacing(1.5)};
        height: ${theme.spacing(1.5)};
        display: inline-block;
        margin-right: ${theme.spacing(0.5)};
        border: ${theme.colors.alpha.white[100]} solid 2px;
    `,
);
