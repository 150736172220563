import {
  Box,
  Card,
  Divider,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import { DotLegend } from "src/components/CommonStyled";
import { GlobalContext } from "src/contexts/GlobalContext";

export default function OnlineUsersStats({ isOnline, handleClick }) {
  const theme = useTheme();
  const { stats, handleGetStats } = useContext(GlobalContext);
  const { usersStats } = stats || {};

  useEffect(() => {
    (async () => {
      try {
        await handleGetStats();
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <Card>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        justifyContent="space-around"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        {[
          {
            label: "Available Vendors & Responders",
            color: "success",
            count: usersStats?.onlineUsers || 0,
            isOnline: true,
          },
          {
            label: "Not Available (Vendors & Responders)",
            color: "error",
            count: usersStats?.offlineUsers || 0,
            isOnline: false,
          },
        ]?.map((item, index) => (
          <Tooltip key={index} title={item?.label}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                minWidth: 120,
                cursor: "pointer",
                borderWidth: 2,
                borderRadius:
                  index % 2 === 0 ? `10px 0 0 10px` : `0 10px 10px 0`,
                borderStyle: "solid",
                backgroundColor:
                  isOnline === item.isOnline
                    ? theme.palette.primary.main + 14
                    : null,
                borderColor:
                  isOnline === item.isOnline
                    ? theme.palette.primary.main
                    : theme.palette.background.default,
                height: "100%",
              }}
              px={2}
              onClick={() => handleClick(item?.isOnline)}
            >
              <DotLegend
                style={{
                  background: `${theme.colors[item?.color].main}`,
                  marginRight: theme.spacing(2),
                }}
              />
              <Typography variant="h4">{item?.count || 0}</Typography>
            </Box>
          </Tooltip>
        ))}
      </Stack>
    </Card>
  );
}
