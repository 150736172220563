import ReactQuill from "react-quill";
import QuillEditorToolbar, { formats } from "./QuillEditorToolbar";
import { styled, useTheme } from "@mui/material";
import { useRef } from "react";
import { Notify } from "notiflix";
import { uploadMedia } from "src/utils/upload";
import useOffSetTop from "src/hooks/useOffSetTop";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  border: `solid 1px #BCC1CB`,
  "& .ql-container.ql-snow": {
    borderColor: "transparent",
    ...theme.typography.body1,
    fontFamily: theme.typography.fontFamily,
  },
  "& .ql-editor": {
    minHeight: 200,
    "&.ql-blank::before": {
      fontStyle: "normal",
      color: theme.palette.text.disabled,
    },
    "& pre.ql-syntax": {
      ...theme.typography.body2,
      padding: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.grey[900],
    },
  },
}));

export default function QuillTextarea({ id, value, onChange, sx, ...other }) {
  const theme = useTheme();
  const quillRef = useRef();
  const isOffset = useOffSetTop(620);

  const imageHandler = async (file) => {
    try {
      const editor = quillRef.current.getEditor();

      const formData = new FormData();
      formData.append("files", file);
      formData.append("fileCategory", "others");
      const res = await uploadMedia(formData);
      const imageLink = process.env.REACT_APP_DO_SPACE_URL + res?.data[0];
      if (imageLink) {
        editor.insertEmbed(
          editor.getSelection()
            ? editor.getSelection().index
            : editor.getSelection(),
          "image",
          imageLink
        );
      }
    } catch (err) {
      console.log("upload err, try again!", err);
      Notify.failure("Error while uploading image");
    }
  };

  const modules = {
    toolbar: {
      container: `#${id}`,
    },
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true,
    },
    clipboard: {
      matchVisual: false,
    },
  };

  const currentTheme = localStorage.getItem("appTheme") || "PureLightTheme";

  return (
    <RootStyle sx={{ ...sx }}>
      <QuillEditorToolbar
        id={id}
        imageHandler={imageHandler}
        style={{
          top: isOffset && 10,
          zIndex: 999,
          width: isOffset && "69%",
          position: isOffset && "fixed",
          backgroundColor:
            currentTheme === "PureLightTheme" ? "#EDEFF1" : "#282C47",
          borderRadius: isOffset
            ? theme.shape.borderRadius
            : `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
        }}
      />
      <ReactQuill
        ref={quillRef}
        value={value}
        onChange={onChange}
        modules={modules}
        formats={formats}
        placeholder="Write something here..."
        {...other}
      />
    </RootStyle>
  );
}
