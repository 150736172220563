import { Box, Typography, useTheme, styled } from "@mui/material";
import React, { useEffect, useState } from "react";

import Chart from "react-apexcharts";

export default function DonutChart({ items, total, title, sx, ...rest }) {
  const theme = useTheme();

  const chartOptions = {
    chart: {
      background: "transparent",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: sx?.size || "65%",
        },
      },
    },
    colors: items?.map((item) => theme.colors[item.color].main) || [],
    dataLabels: {
      enabled: true,
      formatter(val) {
        let _value = Math.round(val);
        return `${_value}%`;
      },
      dropShadow: {
        enabled: true,
        top: 1,
        left: 1,
        blur: 1,
        color: theme.colors.alpha.black[50],
        opacity: 0.5,
      },
    },
    fill: {
      opacity: 1,
    },
    labels: items?.map((item) => item.label) || [],
    legend: {
      labels: {
        colors: theme.colors.alpha.trueWhite[100],
      },
      show: false,
    },
    stroke: {
      width: rest.strokeWidth || 0,
    },
    theme: {
      mode: theme.palette.mode,
    },
    colors: items?.map((item) => theme.colors[item.color].main) || [],
    labels: items?.map((item) => item.label) || [],
  };

  if (rest?.dataLabelsEnabled === false) {
    chartOptions.dataLabels = {
      ...chartOptions.dataLabels,
      enabled: false,
    };
  }

  const chartSeries = items?.map((item) => item.value) || [];
  //   setLoading(true);
  //   let _chartOptions = {
  //     ...chartOptions,
  //     colors: items?.map((item) => theme.colors[item.color].main) || [],
  //     labels: items?.map((item) => item.label) || [],
  //   };

  //   if (rest?.dataLabelsEnabled === false) {
  //     _chartOptions = {
  //       ..._chartOptions,
  //       ...{
  //         dataLabels: {
  //           ..._chartOptions.dataLabels,
  //           enabled: false,
  //         },
  //       },
  //     };
  //   }

  //   setChartOptions(_chartOptions);

  //   setLoading(false);
  // }, [rest]);

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <BoxChartWrapperText>
        {total > 0 && (
          <div className="ChartText">
            <span>{total || 0}</span>
            <Typography
              variant="subtitle1"
              sx={{
                whiteSpace: "nowrap",
                fontSize: sx?.fontSize || "0.9rem",
              }}
            >
              {title || "Total"}
            </Typography>
          </div>
        )}
        <Chart
          options={chartOptions}
          series={chartSeries}
          type="donut"
          width={sx?.height && sx?.height}
          height={sx?.height && sx?.height}
        />
      </BoxChartWrapperText>
    </Box>
  );
}

const BoxChartWrapperText = styled(Box)(
  ({ theme }) => `
    position: relative;
    
    .ChartText {
      color: ${theme.colors.alpha.black[100]};
      font-weight: bold;
      position: absolute;
      height: 100px;
      width: 100px;
      font-size: ${theme.typography.pxToRem(20)};
      top: 50%;
      left: 50%;
      margin: -65px 0 0 -50px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
`,
);
