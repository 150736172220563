import { ChevronRightTwoTone, Duo, Mic } from "@mui/icons-material";
import {
  Grid,
  Box,
  CircularProgress,
  Card,
  Typography,
  alpha,
  circularProgressClasses,
  useTheme,
  Stack,
  Divider,
  Avatar,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "src/contexts/GlobalContext";

function CallingStats({ status, handleStatus, type, handleType }) {
  const theme = useTheme();
  const { stats, handleGetStats } = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);
  const [callsByStatus, setCallsByStatus] = useState({});
  const [callsByType, setCallsByType] = useState({});
  const { callsStats } = stats || {};

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        await handleGetStats();
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (callsStats) {
      setLoading(true);
      const _callsByStatus = callsStats?.callsByStatus?.reduce((acc, item) => {
        acc[item._id] = item;
        return acc;
      }, {});

      const _callsByType = callsStats?.callsByType?.reduce((acc, item) => {
        acc[item._id] = item;
        return acc;
      }, {});

      setCallsByStatus(_callsByStatus);
      setCallsByType(_callsByType);
      setLoading(false);
    }
  }, [callsStats]);

  return (
    !loading && (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Card
              sx={{
                p: 1.5,
                backgroundColor: alpha(theme.colors.success.main, 0.2),
                height: "100%",
              }}
            >
              <Grid container spacing={2}>
                {[
                  {
                    label: "Dialed Calls",
                    color: "info",
                    count: callsByStatus?.dialed?.count || 0,
                    status: "dialed",
                  },
                  {
                    label: "Missed Calls",
                    color: "warning",
                    count: callsByStatus?.missed?.count || 0,
                    status: "missed",
                  },
                  {
                    label: "Received Calls",
                    color: "success",
                    count: callsByStatus?.answered?.count || 0,
                    status: "answered",
                  },
                  {
                    label: "Rejected Calls",
                    color: "error",
                    count: callsByStatus?.rejected?.count || 0,
                    status: "rejected",
                  },
                ].map((item, index) => (
                  <Grid item xs={12} md={6} lg={3} key={index}>
                    <Card
                      onClick={() => handleStatus(item?.status)}
                      sx={{
                        boxShadow: `
                      0 .7rem 1rem ${alpha(theme.colors[item?.color].main, 0.08)},
                      0 .25rem .7rem ${alpha(theme.colors[item?.color].main, 0.15)}
                      `,
                        display: "flex",
                        alignItems: "center",
                        p: 1,
                        px: 2,
                        borderStyle: "solid",
                        borderColor:
                          status === item?.status
                            ? alpha(theme.colors[item?.color].main, 1)
                            : null,
                        borderWidth: status === item?.status ? 2 : 0,
                        cursor: "pointer",
                        "&:hover": {
                          borderColor: alpha(theme.colors[item?.color].main, 1),
                          borderWidth: 2,
                        },
                      }}
                    >
                      <Box flexGrow={1} mr={1}>
                        <Typography
                          component="div"
                          sx={{
                            pb: 1,
                          }}
                          variant="caption"
                        >
                          {item?.label}
                        </Typography>
                        <Typography
                          sx={{
                            lineHeight: 1,
                            display: "flex",
                            alignItems: "center",
                          }}
                          variant="h3"
                        >
                          <span>{item?.count}</span>
                        </Typography>
                      </Box>
                      <Box display="inline-flex" position="relative">
                        <Box
                          sx={{
                            animationDuration: "550ms",
                            position: "absolute",
                            left: 0,
                            top: 0,
                            bottom: 0,
                            right: 0,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              color: `${theme.colors[item?.color].main}`,
                            }}
                            variant="h5"
                          >
                            {(
                              (item?.count / callsStats?.totalCalls) *
                              100
                            )?.toFixed(0) || 0}
                            %
                          </Typography>
                        </Box>
                        <CircularProgress
                          variant="determinate"
                          sx={{
                            color: theme.colors[item?.color].lighter,
                          }}
                          size={50}
                          thickness={2}
                          value={100}
                        />
                        <CircularProgress
                          size={50}
                          sx={{
                            animationDuration: "550ms",
                            position: "absolute",
                            left: 0,
                            color: theme.colors[item?.color].main,
                            top: 0,
                            [`& .${circularProgressClasses.circle}`]: {
                              strokeLinecap: "round",
                            },
                          }}
                          thickness={2}
                          variant="determinate"
                          value={
                            (
                              (item?.count / callsStats?.totalCalls) *
                              100
                            )?.toFixed(0) || 0
                          }
                        />
                      </Box>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card
              sx={{
                background: `${theme.colors.gradients.blue4}`,
                height: "100%",
              }}
            >
              <Stack
                direction="row"
                divider={
                  <Divider
                    sx={{
                      background: `${theme.colors.alpha.white[30]}`,
                    }}
                    orientation="vertical"
                    flexItem
                  />
                }
                justifyContent="space-around"
                alignItems="center"
                spacing={0}
              >
                {[
                  {
                    label: "Video Calls",
                    color: "error",
                    icon: Duo,
                    count: callsByType?.video?.count || 0,
                    type: "video",
                  },
                  {
                    label: "Voice Calls",
                    color: "success",
                    icon: Mic,
                    count: callsByType?.audio?.count || 0,
                    type: "audio",
                  },
                ]?.map((item, index) => (
                  <Box
                    py={2}
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      cursor: "pointer",
                    }}
                    onClick={() => handleType(item.type)}
                  >
                    <Box display="flex" alignItems="center">
                      <Avatar
                        sx={{
                          width: `${theme.spacing(6)}`,
                          height: `${theme.spacing(6)}`,
                          background:
                            type === item.type
                              ? `${theme.colors[item?.color].main}`
                              : `${theme.palette.getContrastText(
                                  theme.colors[item?.color].dark,
                                )}`,
                          color:
                            type === item.type
                              ? `${theme.palette.getContrastText(
                                  theme.colors[item?.color].dark,
                                )}`
                              : `${theme.colors[item?.color].main}`,
                        }}
                      >
                        <item.icon fontSize="large" />
                      </Avatar>
                      <Box ml={2}>
                        <Typography
                          variant="h2"
                          sx={{
                            color: `${theme.colors.alpha.trueWhite[100]}`,
                          }}
                        >
                          {item?.count || 0}
                        </Typography>
                        <Box display="flex" alignItems="center" mt={0.5}>
                          <Typography
                            component="div"
                            variant="caption"
                            sx={{
                              color: `${theme.colors.alpha.trueWhite[70]}`,
                            }}
                          >
                            {item?.label || ""}
                          </Typography>
                          <ChevronRightTwoTone
                            sx={{
                              ml: 1,
                              color: `${theme.colors.alpha.trueWhite[70]}`,
                              transform:
                                type === item.type ? "rotate(90deg)" : "none",
                            }}
                            fontSize="small"
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </>
    )
  );
}

export default CallingStats;
