import React, { useRef, useState } from "react";
import {
  Button,
  CardMedia,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FileDownloadTwoTone } from "@mui/icons-material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs/locale/en-in";
import dayjs from "dayjs";

export default function QuestionInputField({
  question,
  handleFormInput,
  formInput,
  setFormInput,
  disabled,
}) {
  const inputFile = useRef(null);
  const dropArea = useRef(null);

  switch (question?.type) {
    case "text":
      return (
        <TextField
          fullWidth
          disabled={disabled}
          variant="outlined"
          placeholder={
            (question?.placeholder || "") + (question?.required ? " *" : "")
          }
          name={question?.name}
          required={question?.required}
          onChange={handleFormInput}
          value={formInput[question?.name] || question?.response || ""}
        />
      );
    case "textarea":
      return (
        <TextField
          fullWidth
          multiline
          rows={4}
          disabled={disabled}
          variant="outlined"
          placeholder={
            (question?.placeholder || "") + (question?.required ? " *" : "")
          }
          name={question?.name}
          required={question?.required}
          onChange={handleFormInput}
          value={formInput[question?.name] || question?.response || ""}
        />
      );
    case "checkbox":
      return (
        <FormGroup>
          {question?.options?.map((option, index) => (
            <FormControlLabel
              key={index}
              required={question?.required}
              control={
                <Checkbox
                  sx={{ width: "max-content" }}
                  disabled={disabled}
                  name={question?.name}
                  checked={
                    question?.response
                      ? question?.response?.includes(option?.title)
                      : formInput[question?.name]
                        ? formInput[question?.name]?.includes(option?.title)
                        : false
                  }
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFormInput({
                        ...formInput,
                        [question?.name]: [
                          ...(formInput[question?.name] || []),
                          option?.title,
                        ],
                      });
                    } else {
                      setFormInput({
                        ...formInput,
                        [question?.name]: [
                          ...(formInput[question?.name] || [])?.filter(
                            (item) => item !== option?.title
                          ),
                        ],
                      });
                    }
                  }}
                />
              }
              label={option?.title || ""}
            />
          ))}
        </FormGroup>
      );
    case "radio":
      return (
        <FormControl disabled={disabled}>
          {question?.options?.map((option, index) => (
            <RadioGroup
              key={index}
              disabled={disabled}
              name={question?.name}
              required={question?.required}
              onChange={handleFormInput}
              value={formInput[question?.name] || question?.response || ""}
            >
              <FormControlLabel
                control={
                  <Radio
                    sx={{ width: "max-content" }}
                    value={option?.title || ""}
                  />
                }
                label={option?.title || ""}
              />
            </RadioGroup>
          ))}
        </FormControl>
      );
    case "select":
      return (
        <FormControl fullWidth>
          <InputLabel>{question?.placeholder || ""}</InputLabel>
          <Select
            disabled={disabled}
            label={question?.placeholder || ""}
            required={question?.required}
            onChange={handleFormInput}
            value={formInput[question?.name] || question?.response || ""}
          >
            {question?.options?.map((option) => (
              <MenuItem value={option?.title || ""} key={option._id}>
                {option?.title || ""}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    case "file":
      const processFiles = async (e, files) => {
        e.preventDefault();

        dropArea.current.style.backgroundColor = "#fff";
        if (files.length) {
          setFormInput({
            ...formInput,
            [question?.name]: files[0],
          });
        }
      };

      return (
        <>
          {!question?.response && (
            <div className="file-upload">
              <div
                className="drop"
                ref={dropArea}
                onClick={() => inputFile.current.click()}
                onDrop={(e) => processFiles(e, e.dataTransfer.files)}
                onDragOver={(e) => {
                  e.preventDefault();
                  dropArea.current.style.backgroundColor = "#ddd";
                }}
                onDragLeave={(e) => {
                  e.preventDefault();
                  dropArea.current.style.backgroundColor = "#fff";
                }}
                style={{
                  cursor: "pointer",
                  padding: "30px",
                }}
              >
                <div className="info">
                  {"Drag & drop files or click to browse"}
                  {(question?.required ? " (mandatory)" : "") || ""}
                  <br />
                  {"(max size: 10MB)"}
                </div>
              </div>

              <input
                type="file"
                accept="*"
                multiple={false}
                onChange={(e) => processFiles(e, inputFile.current.files)}
                ref={inputFile}
                style={{ display: "none" }}
              />
            </div>
          )}{" "}
          {/* RESPONSE  */}
          {question?.response && (
            <Typography variant="body1" sx={{ mb: 1 }}>
              Response:
            </Typography>
          )}
          <Stack direction={"column"} spacing={1} sx={{ mt: 2 }}>
            {(() => {
              let file = null;
              if (question?.response) {
                file = question?.response;
              } else {
                file = formInput[question?.name];
              }
              if (!file) return null;
              const ext =
                typeof file === "string"
                  ? file?.split(".")?.pop()
                  : file?.name?.split(".")?.pop();
              if (
                ext === "jpg" ||
                ext === "jpeg" ||
                ext === "png" ||
                ext === "gif" ||
                ext === "svg"
              ) {
                return (
                  <CardMedia
                    component="img"
                    image={
                      typeof file === "string"
                        ? process.env.REACT_APP_DO_SPACE_URL +
                          file +
                          "?" +
                          new Date().getTime()
                        : URL.createObjectURL(file)
                    }
                    alt={question?.placeholder + "_failed_to_load"}
                    sx={{
                      width: "100%",
                      position: "relative",
                      height: "200px",
                      objectFit: "contain",
                    }}
                    // enlarge onClick
                    onClick={() => {
                      window.open(
                        typeof file === "string"
                          ? process.env.REACT_APP_DO_SPACE_URL + file
                          : URL.createObjectURL(file)
                      );
                    }}
                  />
                );
              } else {
                return (
                  <Button
                    size="small"
                    variant="outlined"
                    startIcon={<FileDownloadTwoTone />}
                    component={"a"}
                    href={process.env.REACT_APP_DO_SPACE_URL + file}
                    target="_blank"
                    fullWidth
                  >
                    {typeof file === "string" ? "Download File" : file?.name}
                  </Button>
                );
              }
            })()}
          </Stack>
        </>
      );
    case "date":
      return (
        <LocalizationProvider dateAdapter={AdapterDayjs} locale="en-in">
          <DatePicker
            name={question?.name}
            required={question?.required}
            label={
              (question?.placeholder || "") + (question?.required ? " *" : "")
            }
            disabled={disabled}
            value={dayjs(
              question?.response ? new Date(question?.response) : new Date()
            )}
            onChange={(newValue) => {
              setFormInput({
                ...formInput,
                [question?.name]: newValue,
              });
            }}
          />
        </LocalizationProvider>
      );
    default:
      return null;
  }
}
