import React from "react";

export default function ParseHtml({ html, style }) {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: html }}
      style={{
        wordWrap: "break-word",
        ...style,
      }}
      className="parse-html"
    />
  );
}
