import React, { Fragment, useState } from "react";
import {
  Button,
  Card,
  Box,
  Avatar,
  IconButton,
  Divider,
  CardHeader,
  CardContent,
  Paper,
  InputBase,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
} from "@mui/material";
import { AddTwoTone, Done, Search, SendTwoTone } from "@mui/icons-material";
import { Notify } from "notiflix";
import axiosInstance from "src/utils/axiosInstance";
import UserForm from "../common/UserForm";
import { LoadingButton } from "@mui/lab";

export default function AddModerator() {
  const [searchString, setSearchString] = useState("");
  const [isWait, setIsWait] = useState(false);
  const [users, setUsers] = useState([]);

  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      setIsWait(true);
      setUsers([]);

      const reqParams = {
        params: {
          search: searchString,
        },
      };

      const resp = await axiosInstance.get("/user", reqParams);
      if (resp?.status === 200 && resp?.data?.data?.length) {
        setUsers(resp?.data?.data || []);
      } else {
        setUsers([]);
        Notify.info("No user found");
      }
      setIsWait(false);
    } catch (error) {
      setUsers([]);
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  const AddUserButton = ({ user }) => {
    const [isWait, setIsWait] = useState(false);
    const [isAdded, setIsAdded] = useState(false);

    const handleAddModerator = async (user) => {
      try {
        setIsWait(true);
        const resp = await axiosInstance.post("/organisations/users", {
          userId: user?._id,
          accountType: "moderator",
        });
        if (resp?.status === 200) {
          Notify.success(resp?.data?.message);
          setIsAdded(true);
        }
        setIsWait(false);
      } catch (error) {
        setIsWait(false);
        Notify.failure(
          error.response?.data?.message ||
            error.response?.statusText ||
            "an error occured"
        );
      }
    };
    return (
      <LoadingButton
        edge="end"
        variant="outlined"
        color={isAdded ? "success" : "primary"}
        startIcon={isAdded ? <Done /> : <AddTwoTone />}
        onClick={() => handleAddModerator(user)}
        loading={isWait}
      >
        Add as moderator
      </LoadingButton>
    );
  };

  return (
    <Box mx={3}>
      <Card sx={{ my: 2 }}>
        <CardHeader
          title="Search and Add a  moderator"
          subheader="Add a user to become a moderator of your organisation"
        />
        <CardContent>
          <Paper
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search here..."
              inputProps={{ "aria-label": "search here" }}
              onKeyDown={(e) => {
                if (e.keyCode === 13 && searchString?.length > 0) {
                  e.preventDefault();
                  handleSearch(e);
                }
              }}
              onChange={(e) => {
                if (e.target.value?.length > 0) {
                  setSearchString(e.target.value);
                } else {
                  setSearchString("");
                }
              }}
            />
            <IconButton
              type="button"
              sx={{
                m: 0.2,
                bgcolor: "primary.main",
                color: "white",
              }}
              onClick={(e) => {
                handleSearch(e);
              }}
            >
              <Search />
            </IconButton>
          </Paper>
          {isWait ? (
            <LinearProgress sx={{ m: 2 }} />
          ) : (
            <List sx={{ width: "100%", my: 2, bgcolor: "background.paper" }}>
              {users?.length > 0 &&
                users.map((user, idx) => (
                  <Fragment key={idx}>
                    <ListItem
                      alignItems="flex-start"
                      secondaryAction={<AddUserButton user={user} />}
                    >
                      <ListItemAvatar>
                        <Avatar
                          alt={user?.firstName}
                          src={user?.profileImage}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={user?.firstName + " " + user?.lastName}
                        secondary={<small>{user?.email}</small>}
                      />
                    </ListItem>
                    <Divider />
                  </Fragment>
                ))}
            </List>
          )}
        </CardContent>
      </Card>
      <Divider sx={{ my: 5 }}>OR</Divider>
      <Card sx={{ my: 2 }}>
        <CardHeader
          title="Add a new moderator"
          subheader="Add a new moderator to your organisation directly"
        />
        <CardContent>
          <UserForm accountType="user" role="moderator" />
        </CardContent>
      </Card>
    </Box>
  );
}
