/* eslint-disable jsx-a11y/accessible-emoji */
import * as React from "react";
import {
  Box,
  Typography,
  Divider,
  LinearProgress,
  styled,
  CardContent,
  Card,
  CardHeader,
  Stack,
} from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import DonutChart from "src/components/Charts/DonutChart";
import { GlobalContext } from "src/contexts/GlobalContext";

const LinearProgressWrapper = styled(LinearProgress)(
  ({ theme }) => `
      flex-grow: 1;
      height: 10px;
      
      &.MuiLinearProgress-root {
        background-color: ${theme.colors.alpha.black[10]};
      }
      
      .MuiLinearProgress-bar {
        border-radius: ${theme.general.borderRadiusXl};
      }
`,
);

function Block3({ stat }) {
  const { t } = useTranslation();
  const { stats } = React.useContext(GlobalContext);
  const [chartValues, setChartValues] = React.useState([]);
  const { usersStats, callsStats } = stats || {};
  const { callsByStatus } = callsStats || {};

  useEffect(() => {
    setChartValues([
      {
        label: "Dialed",
        value: callsByStatus?.find((item) => item._id === "dialed")?.count || 0,
        color: "info",
      },
      {
        label: "Missed",
        value: callsByStatus?.find((item) => item._id === "missed")?.count || 0,
        color: "warning",
      },
      {
        label: "Answered",
        value:
          callsByStatus?.find((item) => item._id === "answered")?.count || 0,
        color: "success",
      },
      {
        label: "Rejected",
        value:
          callsByStatus?.find((item) => item._id === "rejected")?.count || 0,
        color: "error",
      },
    ]);
  }, [stat]);

  return (
    <Stack spacing={4}>
      <Card>
        <CardHeader
          title={<Typography variant="h4">User Analytics</Typography>}
        />
        <Divider />

        <CardContent>
          {[
            {
              title: "Accounts with Phone Number vs Total Users",
              value: usersStats?.usersByPhoneNumber,
              total: usersStats?.totalUsers,
            },
            {
              title: "Verified Phone Numbers vs Total Phone Numbers",
              value: usersStats?.usersByPhoneVerified,
              total: usersStats?.totalUsers,
            },
            {
              title: "Emails verified",
              value: usersStats?.usersByEmailVerified,
              total: usersStats?.totalUsers,
            },
            {
              title: "Accounts with Device Token",
              value: usersStats?.usersByDeviceToken,
              total: usersStats?.totalUsers,
            },
            {
              title: "Accounts with Geolocation",
              value: usersStats?.usersByLocation,
              total: usersStats?.totalUsers,
            },
          ].map((item, index) => (
            <Box py={2} key={index}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h5">
                  {item.title}{" "}
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="span"
                  >
                    ({item.value || 0} of {item.total || 0})
                  </Typography>
                </Typography>
                <Typography variant="h5">
                  {item.total
                    ? ((item.value / item.total) * 100)?.toFixed(1)
                    : 0}
                  %{" "}
                </Typography>
              </Box>
              <LinearProgressWrapper
                value={
                  item.total ? ((item.value / item.total) * 100)?.toFixed(1) : 0
                }
                color="primary"
                variant="determinate"
              />
            </Box>
          ))}
        </CardContent>
      </Card>
      <Card>
        <CardHeader
          title={
            <Typography variant="h4" gutterBottom>
              Call Statistics
            </Typography>
          }
        />
        <Divider sx={{ mb: 2 }} />
        <DonutChart
          items={chartValues}
          total={callsStats?.totalCalls}
          title={"Total Calls"}
        />
      </Card>
    </Stack>
  );
}

export default Block3;
