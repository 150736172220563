import {
  Box,
  Card,
  Typography,
  CardMedia,
  Divider,
  useTheme,
  IconButton,
  Stack,
  CardContent,
  capitalize,
  alpha,
} from "@mui/material";

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { Link, useParams } from "react-router-dom";

import Label from "src/components/Label";
import { IMAGE_FORMATS } from "src/utils/constants";
import { useEffect, useState } from "react";
import { Notify } from "notiflix";
import axiosInstance from "src/utils/axiosInstance";
import { CloudDownloadTwoTone, ThumbUpAlt } from "@mui/icons-material";
import { fDate, fTime } from "src/utils/date-fns";
import SuspenseLoader from "src/components/SuspenseLoader";
import EmptyHere from "src/components/EmptyHere";
import AddTicketLog from "./components/AddTicketLog";

function TicketDetails() {
  const theme = useTheme();
  const { ticketId } = useParams();
  const [loading, setLoading] = useState(true);
  const [ticketData, setTicketData] = useState(null);
  const [ticketLogs, setTicketLogs] = useState([]);

  const getTicketLogs = async () => {
    try {
      const resp = await axiosInstance.get(`/tickets/${ticketId}/logs`);
      if (resp?.status === 200) {
        setTicketLogs(resp?.data?.data || []);
      }
      return true;
    } catch (error) {
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured",
      );
      return false;
    }
  };

  useEffect(() => {
    if (!ticketId) {
      Notify.failure("Invalid ticket id");
      return;
    }
    (async () => {
      try {
        setLoading(true);
        const resp = await axiosInstance.get(`/tickets/${ticketId}`);
        if (resp?.status === 200) {
          setTicketData(resp?.data?.data || {});
        }
        getTicketLogs(ticketId);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        Notify.failure(
          error.response?.data?.message ||
            error.response?.statusText ||
            "an error occured",
        );
      }
    })();
  }, []);

  return loading ? (
    <SuspenseLoader />
  ) : !ticketData && ticketData === null ? (
    <EmptyHere message="No Ticket found" />
  ) : (
    <Box m={2}>
      <Card>
        <Box
          p={2.5}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Typography variant="h3">Ticket ID: ({ticketId})</Typography>
            <Typography variant="subtitle2">
              By{" "}
              <Link
                to={`/dashboard/users/${ticketData?.client?._id}`}
                target="_blank"
                style={{
                  textDecoration: "none",
                }}
              >
                <b>
                  {ticketData?.client?.firstName} {ticketData?.client?.lastName}
                </b>{" "}
              </Link>
            </Typography>
          </Box>
          <Stack direction="row" spacing={1} alignItems="center">
            <Label
              color={ticketData?.status === "closed" ? "success" : "warning"}
            >
              <h3>{capitalize(ticketData?.status || "closed")}</h3>
            </Label>
          </Stack>
        </Box>
        <Divider />
        <CardContent>
          <Timeline>
            {ticketData && (
              <>
                {ticketLogs?.length > 0 ? (
                  ticketLogs?.map((item, idx) => (
                    <TimelineItem
                      key={idx}
                      sx={{
                        p: 0,
                      }}
                    >
                      <TimelineOppositeContent
                        sx={{
                          width: "200px",
                          flex: "none",
                          whiteSpace: "nowrap",
                        }}
                        color="text.secondary"
                      >
                        <Typography>
                          <strong>{fDate(item?.createdAt)}</strong>
                        </Typography>
                        {fTime(item?.createdAt)}
                      </TimelineOppositeContent>
                      <TimelineSeparator
                        sx={{
                          position: "relative",
                          mx: 2,
                        }}
                      >
                        <TimelineDot
                          sx={{
                            marginTop: 0,
                            top: theme.spacing(1.2),
                          }}
                          variant="outlined"
                          color="primary"
                        />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent
                        sx={{
                          pb: 4,
                        }}
                      >
                        <Stack
                          spacing={2}
                          direction={"row"}
                          justifyContent={"space-between"}
                        >
                          <Typography variant="h6">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item?.description || "",
                              }}
                            />
                          </Typography>

                          {item?.user && (
                            <>
                              <Typography
                                component={Link}
                                to={`/dashboard/users/${item?.user?._id}`}
                                style={{
                                  textDecoration: "none",
                                }}
                              >
                                by-{" "}
                                <b>
                                  {item?.user?.firstName} {item?.user?.lastName}
                                </b>
                              </Typography>
                            </>
                          )}
                        </Stack>

                        {item?.attachments && item?.attachments?.length > 0 && (
                          <Stack direction="row" spacing={1} mt={1}>
                            {item?.attachments?.length > 0 &&
                              item?.attachments?.map((attachment, indx) => (
                                <Box
                                  key={indx}
                                  component={"a"}
                                  href={
                                    process.env.REACT_APP_DO_SPACE_URL +
                                    attachment
                                  }
                                  target="_blank"
                                >
                                  {IMAGE_FORMATS?.includes(
                                    attachment?.split(".")?.pop(),
                                  ) ? (
                                    <CardMedia
                                      component="img"
                                      key={indx}
                                      sx={{
                                        height: 64,
                                        width: 64,
                                        borderRadius: 1,
                                      }}
                                      image={
                                        process.env.REACT_APP_DO_SPACE_URL +
                                        attachment +
                                        `?${Math.random()}`
                                      }
                                      alt={`file ${indx + 1}`}
                                    />
                                  ) : (
                                    <IconButton
                                      size="small"
                                      color="primary"
                                      variant="contained"
                                      sx={{
                                        height: 64,
                                        width: 64,
                                        borderRadius: 1,
                                        backgroundColor: (theme) =>
                                          alpha(
                                            theme.palette.primary.main,
                                            0.1,
                                          ),
                                      }}
                                    >
                                      <CloudDownloadTwoTone />
                                    </IconButton>
                                  )}
                                </Box>
                              ))}
                          </Stack>
                        )}
                      </TimelineContent>
                    </TimelineItem>
                  ))
                ) : (
                  <EmptyHere title={"No logs found"} />
                )}
              </>
            )}
            {ticketData?.status === "open" && (
              <AddTicketLog
                ticketId={ticketId}
                ticketLogs={ticketLogs}
                setTicketLogs={setTicketLogs}
                ticketData={ticketData}
                setTicketData={setTicketData}
              />
            )}
            {["closed"].includes(ticketData?.status) && (
              <>
                <Divider />
                <Stack
                  direction="row"
                  mt={2}
                  alignItems="center"
                  spacing={2}
                  justifyContent={"center"}
                >
                  <ThumbUpAlt sx={{ fontSize: "3rem" }} color="success" />
                  <Typography variant="h2" color={"#57CA22"}>
                    This ticket is closed
                  </Typography>
                </Stack>
              </>
            )}
          </Timeline>
        </CardContent>
      </Card>
    </Box>
  );
}

export default TicketDetails;
