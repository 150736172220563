import {
  Box,
  Typography,
  Card,
  CardHeader,
  Divider,
  Chip,
  Stack,
  capitalize,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import axiosInstance from "src/utils/axiosInstance";
import { Notify } from "notiflix";
import SuspenseLoader from "src/components/SuspenseLoader";
import EmptyHere from "src/components/EmptyHere";
import ParseHtml from "src/components/parseHtml";
import { EditTwoTone } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { GlobalContext } from "src/contexts/GlobalContext";

export default function BlogDetail() {
  const { t } = useTranslation();
  const { currentUser } = useContext(GlobalContext);
  const { source, blogId } = useParams();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const resp = await axiosInstance.get(`/blogs/${blogId}`);
        if (resp?.status === 200) {
          setBlog(resp?.data?.data || null);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        Notify.failure(
          error.response?.data?.message ||
            error.response?.statusText ||
            "an error occured",
        );
      }
    })();
  }, []);

  return (
    <Box m={{ xs: 0, md: 3 }} flexGrow={1}>
      {loading ? (
        <SuspenseLoader />
      ) : blog ? (
        <Card>
          <CardHeader
            // avatar={
            //   <Avatar
            //     src={
            //       blog?.author?.profileImage
            //         ? `${process.env.REACT_APP_DO_SPACE_URL}${blog?.author?.profileImage}?${new Date().getTime()}`
            //         : "/static/images/placeholders/covers/automation-bg.jpg"
            //     }
            //     sx={{
            //       width: 26,
            //       height: 26,
            //     }}
            //   />
            // }
            action={
              currentUser?.accountType === "admin" && (
                <Stack direction="row" spacing={1} alignItems="center">
                  {blog?.isFeatured && (
                    <Chip
                      color={"success"}
                      label={<b>Featured</b>}
                      size="small"
                    />
                  )}
                  <Chip
                    color={blog?.status === "published" ? "success" : "warning"}
                    label={<b>{capitalize(blog?.status || "draft")}</b>}
                    size="small"
                  />
                  <Button
                    color="primary"
                    startIcon={<EditTwoTone />}
                    component={Link}
                    size="small"
                    to={`/dashboard/blogs/${blog?._id}/edit`}
                  >
                    Edit
                  </Button>
                </Stack>
              )
            }
            // titleTypographyProps={{ variant: "h4" }}
            // subheaderTypographyProps={{ variant: "subtitle2" }}
            // title={
            //   blog?.author?.firstName + " " + (blog?.author?.lastName || "")
            // }
            // subheader={
            //   <>
            //     {blog?.author?.specialization?.[0]
            //       ? blog?.author?.specialization?.[0] + ", "
            //       : ""}
            //     {blog?.author?.profession?.[0] || ""}
            //   </>
            // }
          />
          {blog?.subtitle && (
            <Box px={3}>
              <Typography variant="h4" fontWeight="normal">
                {blog?.subtitle || ""}
              </Typography>
            </Box>
          )}
          {blog?.coverImage && (
            <Box m={2}>
              <img
                style={{
                  width: "100%",
                  height: "280px",
                  objectFit: "cover",
                  borderRadius: 15,
                }}
                src={
                  blog?.coverImage
                    ? `${process.env.REACT_APP_DO_SPACE_URL}${blog?.coverImage}?${new Date().getTime()}`
                    : "/static/images/placeholders/covers/automation-bg.jpg"
                }
                alt=""
              />
            </Box>
          )}
          <Box px={2} pt={0}>
            <Typography
              variant="h2"
              sx={{
                pb: 1,
              }}
            >
              {blog?.title || ""}
            </Typography>
            <Stack spacing={2} direction={"row"} alignItems="center">
              <Box>
                {blog?.tags?.map((tag, index) => (
                  <Chip
                    key={index}
                    sx={{
                      mr: 1,
                      border: "none",
                    }}
                    size="small"
                    label={"#" + tag}
                    color="info"
                    variant="outlined"
                  />
                ))}
              </Box>
              {/* <Typography variant="subtitle2">
                <small>{fDateTimeSuffix(blog?.createdAt) || ""}</small>
              </Typography> */}
            </Stack>
          </Box>
          <Divider />
          <Box px={3} py={0}>
            <ParseHtml html={blog?.content || ""} />
          </Box>
        </Card>
      ) : (
        <EmptyHere title={t("No blog found")} />
      )}
    </Box>
  );
}
