import { Close, SaveTwoTone } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Card,
  CardHeader,
  Divider,
  FormHelperText,
  Stack,
  styled,
  TextField,
  Typography,
  Tabs,
  Tab,
  ListItemText,
  List,
  ListItem,
  IconButton,
  debounce,
} from "@mui/material";
import { Notify } from "notiflix";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import SuspenseLoader from "src/components/SuspenseLoader";
import axiosInstance from "src/utils/axiosInstance";
import {
  CAMPAIGN_CATEGORIES,
  CAMPAIGN_SPACIALIST_ROLES,
} from "src/utils/constants";

const tabs = [
  {
    label: "Send Campaign by Category",
    value: "automatic",
  },
  {
    label: "Send Campaign by Individuals",
    value: "manual",
  },
];

function AddEditCampaignNotification() {
  let { campaignId } = useParams();
  const navigator = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);
  const { t } = useTranslation();
  const [currentSelectedPerson, setCurrentSelectedPerson] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [searchedUsers, setSearchedUsers] = useState([]);
  const [tabValue, setTabValue] = React.useState("automatic");
  const [formInput, setFormInput] = useState({
    category: tabValue,
    data: {
      navigationId: "campaign",
    },
  });

  const handleInputChange = (e) => {
    setFormInput((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleTabsChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleFormSubmit = async (e) => {
    console.log(formInput);
    e.preventDefault();
    try {
      if (!formInput?.title) return Notify.warning("Title  is required");
      if (!formInput?.data?.title || !formInput?.data?.body)
        return Notify.warning("Notitication details are required");
      if (tabValue === "manual") {
        if (!formInput?.persons || formInput?.persons?.length === 0)
          return Notify.warning("Select atleast one individual");
      } else {
        if (!formInput?.accountTypes || formInput?.accountTypes?.length === 0)
          return Notify.warning("Select atleast one account type.");
      }

      // setIsSubmitting(true);

      const newFormInput = {
        title: formInput?.title,
        data: formInput?.data,
        category: formInput?.category,
      };

      if (tabValue === "manual") {
        newFormInput.users = formInput?.persons?.map((p) => p?._id);
      } else {
        newFormInput.accountTypes = formInput?.accountTypes?.map(
          (p) => p?.value,
        );
        if (formInput?.roles) {
          newFormInput.roles = formInput?.roles?.map((r) => r?.value);
        }
      }

      if (campaignId) {
        const resp = await axiosInstance.put(
          `/campaigns/${campaignId}`,
          newFormInput,
        );
        if (resp?.status === 200) {
          Notify.success(resp?.data?.message || "success");
          navigator(`/dashboard/campaign-notifications/${campaignId}`);
        }
      } else {
        const resp = await axiosInstance.post(`/campaigns`, newFormInput);
        if (resp?.status === 201) {
          Notify.success("Created successfully");
          setFormInput({});
          navigator(
            `/dashboard/campaign-notifications/${resp?.data?.data?._id}`,
          );
        }
      }
      setIsSubmitting(false);
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured",
      );
    }
  };

  const getUsers = async (search) => {
    try {
      setLoadingUser(true);
      const res = await axiosInstance.get(
        `/users?accountType=user,vendor,responder${search ? `&search=${search}` : null}`,
      );
      if (res?.status === 200) {
        setSearchedUsers(res?.data?.data || []);
      }
    } catch (error) {
      console.error(error);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured",
      );
    } finally {
      setLoadingUser(false);
    }
  };

  const fetchUsers = React.useMemo(
    () =>
      debounce((inputValue) => {
        getUsers(inputValue);
      }, 1000),
    [],
  );

  useEffect(() => {
    if (campaignId) {
      (async () => {
        try {
          setLoading(true);
          const res = await axiosInstance.get(`/campaigns/${campaignId}`);
          if (res?.status === 200) {
            const fetchedData = res?.data?.data;
            const newFecthedInputs = {
              title: fetchedData?.title,
              data: fetchedData?.data,
              category: fetchedData?.category,
            };
            if (fetchedData?.category === "manual") {
              // const userRes = await axiosInstance.post(`/users/ids`, fetchedData?.users?.map(u=>u._id))
              // if(userRes?.status===200){
              newFecthedInputs.persons = fetchedData?.users;
              // }
            } else {
              if (formInput?.accountTypes?.find((r) => r.value === "all")) {
                newFecthedInputs.accountTypes = [];
                newFecthedInputs.roles = [];
              } else {
                newFecthedInputs.accountTypes = CAMPAIGN_CATEGORIES.filter(
                  (each) => fetchedData?.accountTypes?.includes(each?.value),
                );
                if (fetchedData?.roles) {
                  newFecthedInputs.roles = CAMPAIGN_SPACIALIST_ROLES.filter(
                    (each) => fetchedData?.roles?.includes(each?.value),
                  );
                }
              }
            }
            setTabValue(fetchedData?.category);
            setFormInput(newFecthedInputs);
          }
        } catch (error) {
          console.error(error);
          Notify.failure(
            error.response?.data?.message ||
              error.response?.statusText ||
              "an error occured",
          );
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [campaignId]);

  useEffect(() => {
    if (inputValue) {
      fetchUsers(inputValue);
    }
  }, [inputValue]);

  useEffect(() => {
    if (tabValue === "manual") {
      if (searchedUsers?.length === 0) {
        getUsers();
      }
      delete formInput?.accountTypes;
      delete formInput?.roles;
    } else {
      delete formInput?.persons;
      setInputValue("");
    }
    setFormInput({
      ...formInput,
      category: tabValue,
    });
  }, [tabValue]);

  useEffect(() => {
    if (!formInput?.accountTypes?.find((type) => type.value === "vendor")) {
      delete formInput?.roles;
    }
  }, [formInput?.accountTypes]);

  return loading ? (
    <SuspenseLoader />
  ) : (
    <Card sx={{ m: 2 }}>
      <CardHeader
        sx={{
          p: 3,
        }}
        title={
          <Typography variant="h4" gutterBottom>
            {t(campaignId ? "Edit Campaign" : "Create Campaign")}
          </Typography>
        }
      />
      <Divider />
      <Stack sx={{ p: 2 }} spacing={2}>
        <Stack spacing={2}>
          <TextField
            fullWidth
            label={"Campaign Title*"}
            name="title"
            onChange={handleInputChange}
            value={formInput?.title || ""}
          />
        </Stack>
        <Divider />
        <Stack spacing={2}>
          <Box width={"100%"}>
            <Typography variant="h4" sx={{ my: 1 }}>
              Notification Details
            </Typography>
            <Stack spacing={1}>
              <TextField
                fullWidth
                label={t("Title*")}
                name="notificationTitle"
                onChange={(e) => {
                  setFormInput({
                    ...formInput,
                    data: {
                      ...formInput.data,
                      title: e.target.value,
                    },
                  });
                }}
                value={formInput?.data?.title || ""}
              />

              <TextField
                fullWidth
                multiline
                minRows={2}
                label={t("Body*")}
                name="notificationBody"
                onChange={(e) => {
                  setFormInput({
                    ...formInput,
                    data: {
                      ...formInput.data,
                      body: e.target.value,
                    },
                  });
                }}
                value={formInput?.data?.body || ""}
              />
            </Stack>
          </Box>
          <Box width={"100%"}>
            <TabsWrapper
              onChange={handleTabsChange}
              scrollButtons="auto"
              textColor="secondary"
              value={tabValue || "admin"}
              variant="scrollable"
              sx={{ mb: 2 }}
            >
              {tabs.map((tab) => (
                <Tab key={tab.value} value={tab.value} label={tab.label} />
              ))}
            </TabsWrapper>
            {tabValue === "automatic" && (
              <>
                <Autocomplete
                  fullWidth
                  multiple
                  options={CAMPAIGN_CATEGORIES}
                  getOptionLabel={(option) => option.label}
                  value={formInput?.accountTypes || []}
                  onChange={(e, value) => {
                    /**
                     * case 1: empty accountTypes, added new as all -> [all]
                     * case 2: empty accountTypes, added new as other than all -> [other]
                     * case 3: all there, and clicked on other value -> [all]
                     * case 4: other values there, and clicked on all -> [all]
                     */
                    if (
                      formInput?.accountTypes?.length > 0 &&
                      value?.length === 0
                    ) {
                      setFormInput({
                        ...formInput,
                        accountTypes: [],
                      });
                      return;
                    }
                    if (
                      formInput?.accountTypes?.find((r) => r.value === "all")
                    ) {
                      // do nothing
                    } else {
                      if (value?.find((r) => r.value === "all")) {
                        setFormInput({
                          ...formInput,
                          accountTypes: value.filter((r) => r.value === "all"),
                        });
                      } else {
                        setFormInput({
                          ...formInput,
                          accountTypes: value,
                        });
                      }
                    }
                  }}
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        label="Select Account Types"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                      <FormHelperText>
                        Select the account types that will be included in this
                        campaign
                      </FormHelperText>
                    </>
                  )}
                />
                {formInput?.accountTypes?.find((r) => r.value === "vendor") && (
                  <Autocomplete
                    fullWidth
                    multiple
                    options={CAMPAIGN_SPACIALIST_ROLES}
                    getOptionLabel={(option) => option.label}
                    value={formInput?.roles || []}
                    onChange={(e, value) => {
                      setFormInput({
                        ...formInput,
                        roles: value,
                      });
                    }}
                    renderInput={(params) => (
                      <>
                        <TextField
                          {...params}
                          label="Select Specialists"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password", // disable autocomplete and autofill
                          }}
                        />
                        <FormHelperText>
                          Select the specialists that will be included in this
                          campaign
                        </FormHelperText>
                      </>
                    )}
                  />
                )}
              </>
            )}
            {tabValue === "manual" && (
              <>
                <Autocomplete
                  fullWidth
                  options={searchedUsers}
                  getOptionLabel={(option) => ""}
                  loading={loadingUser}
                  filterOptions={(x) => x}
                  isOptionEqualToValue={false}
                  filterSelectedOptions
                  value={currentSelectedPerson}
                  noOptionsText="No Users Found"
                  onChange={(e, value) => {
                    setCurrentSelectedPerson(null);
                    setFormInput({
                      ...formInput,
                      persons: formInput?.persons
                        ? [...formInput?.persons, value]
                        : [value],
                    });
                    setSearchedUsers(
                      searchedUsers?.filter((u) => u?._id !== value?._id),
                    );
                  }}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  renderOption={(props, option) => {
                    return (
                      <ListItem {...props} key={option._id} disableGutters>
                        <ListItemText
                          primary={`${option?.firstName} ${option?.lastName}`}
                          secondary={option?.email}
                        />
                      </ListItem>
                    );
                  }}
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        label="Select Users"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                      <FormHelperText>
                        Select the users that will be included in this campaign
                      </FormHelperText>
                    </>
                  )}
                />
                <Stack>
                  <List
                    sx={{
                      width: "100%",
                      bgcolor: "background.paper",
                      overflow: "auto",
                      maxHeight: 400,
                    }}
                  >
                    {formInput?.persons?.map((eachUser) => (
                      <ListItem
                        key={eachUser._id}
                        disableGutters
                        secondaryAction={
                          <IconButton
                            aria-label="comment"
                            onClick={() => {
                              setFormInput({
                                ...formInput,
                                persons: formInput?.persons?.filter(
                                  (p) => p?._id !== eachUser._id,
                                ),
                              });
                            }}
                          >
                            <Close color="error" />
                          </IconButton>
                        }
                      >
                        <ListItemText
                          primary={`${eachUser?.firstName} ${eachUser?.lastName}`}
                          secondary={eachUser?.email}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Stack>
              </>
            )}
          </Box>
        </Stack>
        <Stack justifyContent={"flex-end"} direction={"row"} spacing={2}>
          <LoadingButton
            sx={{
              mt: 3,
            }}
            color="primary"
            startIcon={<SaveTwoTone />}
            disabled={isSubmitting}
            type="submit"
            size="large"
            variant="contained"
            onClick={handleFormSubmit}
          >
            {t(campaignId ? "Update Campaign" : "Create Campaign")}
          </LoadingButton>
        </Stack>
      </Stack>
    </Card>
  );
}

export default AddEditCampaignNotification;

const TabsWrapper = styled(Tabs)(
  ({ theme }) => `
    @media (max-width: ${theme.breakpoints.values.md}px) {
      .MuiTabs-scrollableX {
        overflow-x: auto !important;
      }

      .MuiTabs-indicator {
          box-shadow: none;
      }
    }
    `,
);
