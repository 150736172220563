import {
  Box,
  Card,
  Typography,
  Avatar,
  CardActionArea,
  alpha,
  styled,
  useTheme,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import AddAlertTwoToneIcon from "@mui/icons-material/AddAlertTwoTone";
import ChevronRightTwoToneIcon from "@mui/icons-material/ChevronRightTwoTone";

const CardBorderBottom = styled(Card)(
  () => `
    border-bottom: transparent 5px solid;
  `,
);

function StatCard2({ item, sx, onClick = () => {} }) {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <CardBorderBottom
      sx={{
        borderBottomColor: `${theme.colors[item?.color || "success"].main}`,
        boxShadow: `
              0 0.47rem 2.2rem ${alpha(theme.colors[item?.color || "success"].main, 0.04)}, 
              0 0.94rem 1.4rem ${alpha(theme.colors[item?.color || "success"].main, 0.04)}, 
              0 0.25rem 0.54rem ${alpha(theme.colors[item?.color || "success"].main, 0.06)}, 
              0 0.13rem 0.19rem ${alpha(theme.colors[item?.color || "success"].main, 0.04)}`,
        ...sx,
      }}
      onClick={onClick}
    >
      <CardActionArea
        sx={{
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box display="flex">
          {item?.icon && (
            <Avatar
              variant="rounded"
              sx={{
                width: `${theme.spacing(5.5)}`,
                height: `${theme.spacing(5.5)}`,
                background: `${theme.colors[item?.color || "primary"].main}`,
                color: `${theme.palette.getContrastText(
                  theme.colors[item?.color || "primary"].dark,
                )}`,
              }}
            >
              {item?.icon}
            </Avatar>
          )}
          <Box ml={3}>
            <Typography gutterBottom component="div" variant="caption">
              {item?.label || ""}{" "}
            </Typography>
            <Typography variant="h2">{item?.count || 0}</Typography>
          </Box>
        </Box>
        <ChevronRightTwoToneIcon />
      </CardActionArea>
    </CardBorderBottom>
  );
}

export default StatCard2;
