import { Notify } from "notiflix";
import { useState, createContext, useEffect } from "react";
import axiosInstance from "src/utils/axiosInstance";

export const GlobalContext = createContext({});

export const GlobalProvider = ({ children }) => {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };
  const closeSidebar = () => {
    setSidebarToggle(false);
  };

  // ----------------------------------
  const [currentUser, setCurrentUser] = useState(null);
  const [globalLoading, setGlobalLoading] = useState("");
  const [stats, setStats] = useState(null);

  useEffect(() => {
    handleCurrentUser();
  }, []);

  const handleCurrentUser = () => {
    if (localStorage.getItem("user")) {
      setCurrentUser(JSON.parse(localStorage.getItem("user")));
    } else {
      setCurrentUser(null);
    }
  };

  const handleGetStats = async () => {
    try {
      // If stats are available and created within the last 5 minutes, no need to fetch new stats
      if (stats?.createdAt) {
        const diff = new Date() - new Date(stats.createdAt);
        if (diff <= 300000) {
          // 5 minutes in milliseconds
          return;
        }
      }

      const resp = await axiosInstance.get("/dashboard/stats");
      setStats(resp.data || {});
      return true;
    } catch (error) {
      console.log(error);
      setStats(null);
      return false;
    }
  };

  const logout = async () => {
    try {
      await axiosInstance.post("/auth/logout");

      localStorage.removeItem("user");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("requiresPasswordReset");
      Notify.success("Logged out successfully");
    } catch (error) {
      console.log(error);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured",
      );
    }
  };

  return (
    <GlobalContext.Provider
      value={{
        // state
        sidebarToggle,
        currentUser,
        globalLoading,
        stats,

        // methods
        toggleSidebar,
        closeSidebar,
        handleCurrentUser,
        logout,
        setGlobalLoading,
        handleGetStats,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
