import { Box, styled, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        padding: ${theme.spacing(0, 1, 0, 0)};
        display: flex;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
        align-items: center;
`
);

const LogoSignWrapper = styled(Box)(
  () => `
        width: 45px;
        height: 45px;
        margin-top: 4px;
        margin-right: 10px;
        transform: scale(.8);
`
);

const LogoText = styled(Box)(
  ({ theme }) => `
        font-size: ${theme.typography.pxToRem(25)};
        font-weight: ${theme.typography.fontWeightBold};
`
);

function Logo() {
  const { t } = useTranslation();

  return (
    <LogoWrapper to="/">
      <LogoSignWrapper>
        {/* <LogoSign>
          <LogoSignInner />
        </LogoSign> */}
        <img
          src="/static/images/logo/logo.png"
          alt="logo"
          style={{ width: "100%", height: "100%", background: "white" }}
        />
      </LogoSignWrapper>
      <Box
        component="span"
        sx={{
          display: { xs: "none", sm: "inline-block" },
        }}
      >
        <LogoText>CopConnect</LogoText>
      </Box>
    </LogoWrapper>
  );
}

export default Logo;
