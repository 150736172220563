import {
  Typography,
  Box,
  useTheme,
  styled,
  LinearProgress,
  Button,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import axiosInstance from "src/utils/axiosInstance";
import { GlobalContext } from "src/contexts/GlobalContext";
import { AddTwoTone, EditTwoTone } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { Notify } from "notiflix";
import EmptyHere from "src/components/EmptyHere";

const RootWrapper = styled(Box)(
  ({ theme }) => `
        margin-top: ${theme.spacing(0)};
        background: ${theme.colors.gradients.purple1};
        padding: ${theme.spacing(3)};
        border-radius: ${theme.general.borderRadius};
        height: ${theme.spacing(21)};
        margin-bottom: ${theme.spacing(10)};
`
);

const TypographyPrimary = styled(Typography)(
  ({ theme }) => `
      color: ${theme.colors.alpha.trueWhite[100]};
`
);

function OrgProfile() {
  const { t } = useTranslation();
  const theme = useTheme();
  let { organisationId } = useParams();
  const { currentUser } = useContext(GlobalContext);

  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let _orgId = "";
        if (organisationId && organisationId !== "account") {
          _orgId = organisationId;
        } else {
          _orgId = currentUser?.orgId;
        }
        if (!_orgId) {
          setProfile(null);
          setLoading(false);
        } else {
          const resp = await axiosInstance.get(`/organisations/${_orgId}`);
          setProfile(resp.data || {});
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
        Notify.failure(
          error.response?.data?.message ||
            error.response?.statusText ||
            "an error occured"
        );
      }
    })();
  }, []);

  return loading ? (
    <LinearProgress sx={{ m: 2 }} />
  ) : !profile ? (
    <Box sx={{ m: 2 }}>
      <EmptyHere />
    </Box>
  ) : (
    <Box sx={{ m: 2 }}>
      <RootWrapper>
        <Box
          mb={4}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Box>
            <TypographyPrimary
              sx={{ fontSize: `${theme.typography.pxToRem(30)}` }}
              variant="h2"
            >
              {profile?.data?.title}
            </TypographyPrimary>
          </Box>
          <Box display={"flex"} alignItems={"center"}>
            {!profile?.users?.some((user) => user.role === "owner") && (
              <Button
                variant="contained"
                color="info"
                size="small"
                startIcon={<AddTwoTone />}
                component={Link}
                to={`/dashboard/organisations/${profile?.data?._id}/add-owner`}
              >
                {t("Add Owner")}
              </Button>
            )}
            <Button
              variant="text"
              color="info"
              size="small"
              startIcon={<EditTwoTone />}
              component={Link}
              to={`/dashboard/organisations/${profile?.data?._id}/edit`}
            >
              {t("Edit")}
            </Button>
          </Box>
        </Box>
      </RootWrapper>
    </Box>
  );
}

export default OrgProfile;
