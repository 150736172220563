import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Grid,
  Typography,
  CardContent,
  capitalize,
  Divider,
  Stack,
} from "@mui/material";
import { Notify } from "notiflix";
import { Link, useParams } from "react-router-dom";
import axiosInstance from "src/utils/axiosInstance";
import SuspenseLoader from "src/components/SuspenseLoader";
import EmptyHere from "src/components/EmptyHere";
import Label from "src/components/Label";
import { fDateTimeSuffix } from "src/utils/date-fns";
import { APPOINTMENT_STATUS } from "src/utils/constants";

function AppointmentDetails() {
  const { appointmentId } = useParams();
  const [loading, setLoading] = useState(true);
  const [appointmentData, setAppointmentData] = useState(null);

  useEffect(() => {
    if (!appointmentId) {
      Notify.failure("Invalid appointment id");
      return;
    }
    (async () => {
      try {
        setLoading(true);
        const resp = await axiosInstance.get(`/appointments/${appointmentId}`);
        if (resp?.status === 200) {
          setAppointmentData(resp?.data?.data || {});
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        Notify.failure(
          error.response?.data?.message ||
            error.response?.statusText ||
            "an error occured"
        );
      }
    })();
  }, []);

  return loading ? (
    <SuspenseLoader />
  ) : !appointmentData && appointmentData === null ? (
    <EmptyHere message="No Appointment found" />
  ) : (
    <Box m={2}>
      <Card>
        <Box
          p={2.5}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Typography>
              <b>Appointment ID:</b> {appointmentData?.appointmentId}
            </Typography>
            <Typography>
              <b>Reference ID:</b> {appointmentId}
            </Typography>
          </Box>

          <Label color={APPOINTMENT_STATUS[appointmentData?.status]?.color}>
            <h3>{APPOINTMENT_STATUS[appointmentData?.status]?.label}</h3>
          </Label>
        </Box>
        <Divider />
        <CardContent>
          <Grid container>
            <Grid item sm={3}>
              <Box>
                <Typography mb={1} variant="subtitle2">
                  Client
                </Typography>
                <Typography variant="h4">
                  {appointmentData?.user?.firstName || "-"}{" "}
                  {appointmentData?.user?.lastName || ""}
                </Typography>
                <Typography>{appointmentData?.user?.email || "-"}</Typography>
              </Box>
            </Grid>
            <Grid item sm={3}>
              <Box>
                <Typography mb={1} variant="subtitle2">
                  Specialist
                </Typography>
                <Typography variant="h4">
                  {appointmentData?.vendor?.firstName || "-"}{" "}
                  {appointmentData?.vendor?.lastName || ""}
                </Typography>
                <Typography>{appointmentData?.vendor?.email || "-"}</Typography>
              </Box>
            </Grid>
            <Grid item sm={3}>
              <Box>
                <Typography mb={1} variant="subtitle2">
                  Booked By
                </Typography>
                <Typography variant="h4">
                  {appointmentData?.bookedBy?.firstName || "-"}{" "}
                  {appointmentData?.bookedBy?.lastName || ""}
                </Typography>
                <Typography>
                  {appointmentData?.bookedBy?.email || "-"}
                </Typography>
              </Box>
            </Grid>
            <Grid item sm={3}>
              <Box>
                <Typography mb={1} variant="subtitle2">
                  Recommended By
                </Typography>
                {appointmentData?.recommendedBy ? (
                  <>
                    <Typography variant="h4">
                      {appointmentData?.recommendedBy?.firstName || "-"}{" "}
                      {appointmentData?.recommendedBy?.lastName || ""}
                    </Typography>
                    <Typography>
                      {appointmentData?.recommendedBy?.email || "-"}
                    </Typography>
                  </>
                ) : (
                  <Typography>NA</Typography>
                )}
              </Box>
            </Grid>
          </Grid>
          <Box my={6} />
          <Stack spacing={2}>
            <Box>
              <Typography variant="h5" mb={2}>
                Summary
              </Typography>
              <Grid container>
                <Grid item sm={6}>
                  <Stack spacing={1}>
                    <Stack direction={"row"} spacing={1}>
                      <Box>
                        <Typography variant="subtitle2">Status:</Typography>
                      </Box>
                      <Box>{capitalize(appointmentData?.status)}</Box>
                    </Stack>
                    <Stack direction={"row"} spacing={1}>
                      <Box>
                        <Typography variant="subtitle2">Start At:</Typography>
                      </Box>
                      <Box>{fDateTimeSuffix(appointmentData?.startAt)}</Box>
                    </Stack>
                    <Stack direction={"row"} spacing={1}>
                      <Box>
                        <Typography variant="subtitle2">Duration:</Typography>
                      </Box>
                      <Box>{appointmentData?.duration} Seconds</Box>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item sm={6}>
                  <Stack spacing={1}>
                    <Stack direction={"row"} spacing={1}>
                      <Box>
                        <Typography variant="subtitle2">Service:</Typography>
                      </Box>
                      <Box>{capitalize(appointmentData?.service)}</Box>
                    </Stack>
                    <Stack direction={"row"} spacing={1}>
                      <Box>
                        <Typography variant="subtitle2">End At:</Typography>
                      </Box>
                      <Box>{fDateTimeSuffix(appointmentData?.endAt)}</Box>
                    </Stack>
                    <Stack direction={"row"} spacing={1}>
                      <Box>
                        <Typography variant="subtitle2">Amount:</Typography>
                      </Box>
                      <Box>Rs. {appointmentData?.amount}</Box>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
}

export default AppointmentDetails;
