import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  ListItemButton,
  styled,
  List,
  Avatar,
  ListItemAvatar,
  ListItemText,
  Divider,
} from "@mui/material";
import axiosInstance from "src/utils/axiosInstance";
import SuspenseLoader from "src/components/SuspenseLoader";
import { Notify } from "notiflix";
import EmptyHere from "src/components/EmptyHere";
import { fDate, fTime } from "src/utils/date-fns";
import Scrollbar from "src/components/Scrollbar";
import { getChatObjectMetadata } from "src/utils/helpers";
import ChatMessages from "./Chat";

const RootWrapper = styled(Box)(
  ({ theme }) => `
        height: 65vh;
         padding-bottom: ${theme.spacing(2)};
  `
);

const ListItemWrapper = styled(ListItemButton)(
  ({ theme }) => `
          &.MuiButtonBase-root {
              margin: ${theme.spacing(1)} 0;
          }
    `
);

export default function Messages({ userId, user }) {
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedChat, setSelectedChat] = useState(null);

  useEffect(()=>{
    if(chats?.data?.length){
        const el = document.querySelector('#messageTabContent').getBoundingClientRect();
        window.scrollTo({
          top: el.top + el.height + 1000,
          behavior: 'smooth'
        })
    }
  },[chats])

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const reqParams = {};

        if (userId) {
          reqParams.params = { ...reqParams.params, userId };
        }

        const resp = await axiosInstance.get("/chats", reqParams);
        if (resp?.status === 200) {
          setChats(resp.data);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        Notify.failure(
          error.response?.data?.message ||
            error.response?.statusText ||
            "an error occured"
        );
      }
    })();
  }, [userId]);

  return (
    <Box id="messageTabContent" m={3}>
      {loading ? (
        <SuspenseLoader />
      ) : chats?.data?.length > 0 ? (
        <>
          <Grid container spacing={2}>
            <Grid
              item
              xs={4}
              sx={{
                borderRight: (theme) => `1px solid ${theme.palette.divider}`,
                px: 2,
              }}
            >
              <Typography
                sx={{
                  mb: 1,
                }}
                variant="h3"
              >
                Chats
              </Typography>
              <Divider sx={{ my: 1 }} />
              <RootWrapper>
                <Scrollbar>
                  <List disablePadding component="div">
                    {chats?.data?.map((item, index) => {
                      const chat = getChatObjectMetadata(
                        item,
                        userId,
                        chats?.participants
                      );
                      return (
                        <ListItemWrapper
                          key={index}
                          onClick={() => setSelectedChat(chat)}
                          selected={selectedChat?.id === chat?.id}
                        >
                          <ListItemAvatar>
                            <Avatar
                              src={chat?.avatar || ""}
                              alt={chat?.title || ""}
                            />
                          </ListItemAvatar>
                          <ListItemText
                            sx={{
                              mr: 1,
                            }}
                            primaryTypographyProps={{
                              color: "textPrimary",
                              variant: "h5",
                              noWrap: true,
                            }}
                            secondaryTypographyProps={{
                              color: "textSecondary",
                              noWrap: true,
                            }}
                            primary={chat?.title || ""}
                            secondary={chat?.lastMessage || ""}
                          />
                          {chat?.updatedAt && (
                            <Typography variant="subtitle2" textAlign={"right"}>
                              <Typography>
                                <small>{fDate(chat?.updatedAt)}</small>
                              </Typography>
                              <small>{fTime(chat?.updatedAt)}</small>
                            </Typography>
                          )}
                        </ListItemWrapper>
                      );
                    })}
                  </List>
                </Scrollbar>
              </RootWrapper>
            </Grid>{" "}
            <Grid item xs={8}>
              <ChatMessages chat={selectedChat || null} user={user} />
            </Grid>
          </Grid>
        </>
      ) : (
        <EmptyHere title="No messages found" />
      )}
    </Box>
  );
}
