import { Box, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import Chart from "react-apexcharts";
import { useEffect, useState } from "react";

function BarChartSmall({ items, total, sx, ...rest }) {
  const { t } = useTranslation();
  const theme = useTheme();
  // const [chartOptions, setChartOptions] = useState({
  //   chart: {
  //     background: "transparent",
  //     toolbar: {
  //       show: false,
  //     },
  //     sparkline: {
  //       enabled: true,
  //     },
  //   },
  //   theme: {
  //     mode: theme.palette.mode === "dark" ? "light" : "dark",
  //   },
  //   colors: items?.map((item) => theme.colors[item.color].main) || [],
  //   plotOptions: {
  //     bar: {
  //       horizontal: false,
  //       borderRadius: 5,
  //       columnWidth: "60%",
  //       distributed: true,
  //       dataLabels: {
  //         position: "top",
  //       },
  //     },
  //   },
  //   markers: {
  //     size: 0,
  //   },
  //   dataLabels: {
  //     enabled: true,
  //     formatter(val) {
  //       const percentage = ((val / total) * 100).toFixed(0);
  //       return `${percentage}%`;
  //     },
  //   },
  //   tooltip: {
  //     x: {
  //       show: true,
  //     },
  //     y: {
  //       title: {
  //         formatter() {
  //           return "Orders";
  //         },
  //       },
  //     },
  //     marker: {
  //       show: false,
  //     },
  //   },
  //   yaxis: {
  //     show: false,
  //   },
  //   legend: {
  //     show: false,
  //   },
  // });

  const chartOptions = {
    chart: {
      background: "transparent",
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    theme: {
      mode: theme.palette.mode === "dark" ? "light" : "dark",
    },
    colors: items?.map((item) => theme.colors[item.color].main) || [],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 5,
        columnWidth: "60%",
        distributed: true,
        dataLabels: {
          position: "top",
        },
      },
    },
    markers: {
      size: 0,
    },
    dataLabels: {
      enabled: true,
      formatter(val) {
        const percentage = ((val / total) * 100).toFixed(0);
        return `${percentage}%`;
      },
    },
    tooltip: {
      x: {
        show: true,
      },
      y: {
        title: {
          formatter() {
            return "Orders";
          },
        },
      },
      marker: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    legend: {
      show: false,
    },
    colors: items?.map((item) => theme.colors[item.color].main) || [],
    labels: items?.map((item) => item.label) || [],
  };

  const chartData = [
    {
      name: "Orders",
      data: items?.map((item) => item?.value) || [],
    },
  ];

  if (!items) {
    return null;
  }

  return (
    <Box sx={{ height: "100%" }} {...rest}>
      <Chart
        options={chartOptions}
        series={chartData}
        type="bar"
        height={sx?.height || "100%"}
      />
    </Box>
  );
}

export default BarChartSmall;
