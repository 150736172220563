import ChangePassword from "src/content/dashboards/Account/ChangePassword";
import AddEditProfile from "src/content/dashboards/users/AddEditProfile";
import UserProfile from "src/content/dashboards/profile/UserProfile";
import AddModerator from "src/content/dashboards/organisations/AddModerator";
import Organisations from "src/content/dashboards/organisations";
import { ReportProvider } from "src/contexts/ReportContext";
import UsersList from "src/content/dashboards/users";
import OrgProfile from "src/content/dashboards/profile/OrgProfile";
import Analytics from "src/content/dashboards/Analytics";
import CreateOrg from "src/content/dashboards/organisations/createOrg";
import AddOrgUser from "src/content/dashboards/organisations/AddOrgUser";
import AddOwner from "src/content/dashboards/organisations/AddOwner";
import ActivityLogs from "src/content/dashboards/Analytics/ActivityLogs";
import AppointmentList from "src/content/dashboards/appointments/AppointmentList";
import AppointmentDetails from "src/content/dashboards/appointments/AppointmentDetails";

import Orders from "src/content/dashboards/Orders";
import InvoiceDetail from "src/content/pages/Invoice/InvoiceDetail";
import Blogs from "src/content/dashboards/Blogs";
import BlogDetail from "src/content/dashboards/Blogs/BlogDetail";
import AddEditBlog from "src/content/dashboards/Blogs/AddEditBlog";
import Reports from "src/content/dashboards/Reports";
import AddEditForm from "src/content/dashboards/Forms/AddEditForm";
import ReportDetails from "src/content/dashboards/Reports/ReportDetails";
import Tickets from "src/content/dashboards/Tickets";
import TicketDetails from "src/content/dashboards/Tickets/TicketDetails";
import CallRecordings from "src/content/dashboards/CallRecordings";
import CopconnectCafes from "src/content/dashboards/Cafes";
import AddEditCafe from "src/content/dashboards/Cafes/AddEditCafe";
import CampaignNotifications from "src/content/dashboards/Campaigns/CampaignNotifications";
import AddEditCampaignNotification from "src/content/dashboards/Campaigns/AddEditCampaignNotification";
import CampaignNotificationDetails from "src/content/dashboards/Campaigns/CampaignNotificationDetails";
import TagManager from "src/content/dashboards/TagManager";

const dashboardsRoutes = [
  {
    path: "",
    element: <Analytics />,
  },
  {
    path: "logs",
    element: <ActivityLogs />,
  },
  {
    path: "organisations",
    element: <ReportProvider />,
    children: [
      {
        path: "",
        element: <Organisations />,
      },
      {
        path: "create",
        element: <CreateOrg />,
      },
      {
        path: "add-user",
        element: <AddOrgUser />,
      },
      {
        path: "add-moderator",
        element: <AddModerator />,
      },
      {
        path: "add-owner",
        element: <AddOwner />,
      },
      {
        path: "account",
        element: <OrgProfile page="account" />,
      },
      {
        path: ":organisationId",
        element: <OrgProfile />,
      },
      {
        path: ":organisationId/edit",
        element: <CreateOrg />,
      },
      {
        path: ":organisationId/add-owner",
        element: <AddOwner />,
      },
    ],
  },
  {
    path: "users",
    children: [
      {
        path: "",
        element: <UsersList />,
      },
      {
        path: ":userId",
        element: <UserProfile />,
      },
      {
        path: "new",
        element: <AddEditProfile />,
      },
      {
        path: ":userId/edit",
        element: <AddEditProfile />,
      },
    ],
  },
  {
    path: "tickets",
    children: [
      {
        path: "",
        element: <Tickets />,
      },
      {
        path: ":ticketId",
        element: <TicketDetails />,
      },
    ],
  },
  {
    path: "reports",
    children: [
      {
        path: "",
        element: <Reports />,
      },
      {
        path: ":formType/edit",
        element: <AddEditForm />,
      },
      {
        path: ":reportId",
        element: <ReportDetails />,
      },
    ],
  },
  {
    path: "tag-manager",
    children: [
      {
        path: "",
        element: <TagManager />,
      },
    ],
  },
  {
    path: "cafes",
    children: [
      {
        path: "",
        element: <CopconnectCafes />,
      },
      {
        path: "new",
        element: <AddEditCafe />,
      },
      {
        path: ":cafeId/edit",
        element: <AddEditCafe />,
      },
    ],
  },
  {
    path: "campaign-notifications",
    children: [
      {
        path: "",
        element: <CampaignNotifications />,
      },
      {
        path: "new",
        element: <AddEditCampaignNotification />,
      },
      {
        path: ":campaignId/edit",
        element: <AddEditCampaignNotification />,
      },
      {
        path: ":campaignId",
        element: <CampaignNotificationDetails />,
      },
    ],
  },
  {
    path: "blogs",
    children: [
      {
        path: "",
        element: <Blogs />,
      },
      {
        path: "new",
        element: <AddEditBlog />,
      },
      {
        path: ":blogId",
        element: <BlogDetail />,
      },
      {
        path: ":blogId/edit",
        element: <AddEditBlog />,
      },
    ],
  },
  {
    path: "appointments",
    children: [
      {
        path: "",
        element: <AppointmentList />,
      },
      {
        path: ":appointmentId",
        element: <AppointmentDetails />,
      },
    ],
  },
  {
    path: "orders",
    children: [
      { path: "", element: <Orders /> },
      { path: "invoices/:invoiceId", element: <InvoiceDetail /> },
    ],
  },
  {
    path: "call-history",
    children: [{ path: "", element: <CallRecordings /> }],
  },
  {
    path: "account",
    children: [
      {
        path: "profile",
        element: <UserProfile page="profile" />,
      },
      {
        path: "profile/edit",
        element: <AddEditProfile page="profile" />,
      },
      {
        path: "change-password",
        element: <ChangePassword />,
      },
    ],
  },
];

export default dashboardsRoutes;
