import {
  Button,
  Card,
  Box,
  Divider,
  Chip,
  ListItem,
  ListItemAvatar,
  ListItemText,
  useTheme,
  capitalize,
  Stack,
  Typography,
} from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import { fDateTimeSuffix } from "src/utils/date-fns";
import { Link } from "react-router-dom";

export default function BlogCard({ blog }) {
  const theme = useTheme();

  return (
    <Card>
      <ListItem>
        <ListItemAvatar
          sx={{
            mr: 2,
          }}
        >
          <img
            src={
              blog?.coverImage
                ? `${process.env.REACT_APP_DO_SPACE_URL}${blog?.coverImage}?${new Date().getTime()}`
                : "/static/images/placeholders/covers/automation-bg.jpg"
            }
            style={{
              width: 160,
              height: 160,
              objectFit: "cover",
              borderRadius: theme.shape.borderRadius,
            }}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                sx={{
                  mb: 1,
                }}
              >
                {blog?.tags?.map((tag, index) => (
                  <Typography
                    key={index}
                    sx={{
                      mr: 1,
                      color: theme.palette.info.main,
                    }}
                  >
                    #{tag}
                  </Typography>
                ))}
              </Stack>
              {blog?.title || ""}
            </>
          }
          primaryTypographyProps={{ variant: "h3" }}
          secondary={
            <>
              {fDateTimeSuffix(blog?.createdAt) || ""}
              <Stack direction="row" spacing={3} mt={2} alignItems="center">
                <Chip
                  color={blog?.status === "published" ? "success" : "warning"}
                  label={<b>{capitalize(blog?.status || "draft")}</b>}
                  size="small"
                />
                {blog?.isFeatured && (
                  <Chip
                    color={"success"}
                    label={<b>Featured</b>}
                    size="small"
                    variant="outlined"
                  />
                )}
                {blog?.subcategory && (
                  <Chip
                    color={"secondary"}
                    label={<b>{blog?.subcategory || ""}</b>}
                    size="small"
                    variant="outlined"
                  />
                )}
              </Stack>
            </>
          }
          secondaryTypographyProps={{
            variant: "subtitle2",
            sx: {
              pt: 1,
            },
          }}
        />
        <Box
          sx={{
            my: { xs: 2, md: 0 },
          }}
          display="flex"
          alignItems="center"
          justifyContent="flex-right"
        >
          <Button
            sx={{
              mx: 2,
            }}
            variant="outlined"
            size="small"
            endIcon={<ArrowForwardIos />}
            component={Link}
            to={`/dashboard/blogs/${blog?._id}`}
          >
            View
          </Button>
        </Box>
      </ListItem>
      <Divider component="li" />
    </Card>
  );
}
