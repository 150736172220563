import React from "react";
import {
  EditTwoTone,
  MailTwoTone,
  EmailTwoTone,
  AccountCircleOutlined,
} from "@mui/icons-material";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  Tooltip,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Confirm, Notify } from "notiflix";
import axiosInstance from "src/utils/axiosInstance";

export default function CampaignBulkActions({ campaign, onSend }) {
  const handleSendCampaign = () => {
    Confirm.show(
      "Send Confirm",
      "Are you sure you want to send campaign?",
      "Yes",
      "No",
      async () => {
        try {
          const resp = await axiosInstance.post(
            `/campaigns/${campaign?._id}/send`,
          );
          Notify.success(
            "Campaign send successfully, might take some time reach end users.",
          );
          // navigator(`/dashboard/campaign-notifications/${campaign?._id}`);
          onSend();
        } catch (error) {
          Notify.failure(
            error.response?.data?.message ||
              error.response?.statusText ||
              "an error occured",
          );
        }
      },
      () => {
        // nothing
      },
      {},
    );
  };

  return (
    <Stack spacing={2}>
      {campaign?.createdBy && (
        <Card sx={{ p: 3 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: "1 1 0%",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                margin: "0px 0px 24px",
                color: "rgb(88, 96, 105)",
                textTransform: "uppercase",
                fontSize: "14px",
                lineHeight: "18px",
              }}
            >
              Created By
            </Typography>
            <Divider sx={{ mb: 2 }} />
            {[
              {
                icon: <AccountCircleOutlined />,
                text:
                  (campaign?.createdBy?.firstName || "") +
                  " " +
                  (campaign?.createdBy?.lastName || ""),
              },
              {
                icon: <EmailTwoTone />,
                text: campaign?.createdBy?.email,
              },
            ].map(
              (item, idx) =>
                item?.text && (
                  <Box sx={{ display: "flex", marginBottom: "16px" }} key={idx}>
                    <Box
                      sx={{
                        display: "flex",
                        flex: "1 1 0%",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        filter: "none",
                      }}
                    >
                      <Box
                        sx={{
                          marginRight: "12px",
                          position: "relative",
                          cursor: "default",
                        }}
                      >
                        {item.icon}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          WebkitBoxPack: "center",
                          justifyContent: "center",
                          margin: "auto 0px",
                        }}
                      >
                        <Typography
                          variant="h5"
                          sx={{
                            color: "rgb(38, 38, 38)",
                            margin: "0px",
                            wordBreak: "break-word",
                            fontSize: "14px",
                            lineHeight: "18px",
                          }}
                        >
                          {item.text}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ),
            )}
          </Box>
        </Card>
      )}
      <Card>
        <CardHeader title="Actions" />
        <Divider />
        <CardContent>
          <Stack spacing={2}>
            <Tooltip
              title={
                campaign?.forms?.length === 0
                  ? "No forms added to this campaign yet"
                  : ""
              }
              arrow
            >
              <Box>
                <Button
                  fullWidth
                  color="warning"
                  variant="contained"
                  endIcon={<MailTwoTone />}
                  onClick={handleSendCampaign}
                  disabled={campaign?.forms?.length === 0}
                >
                  Send Campaign
                </Button>
              </Box>
            </Tooltip>
            <Stack direction="row" spacing={2}>
              <Button
                fullWidth
                color="primary"
                variant="outlined"
                startIcon={<EditTwoTone />}
                component={Link}
                to={`/dashboard/campaign-notifications/${campaign?._id}/edit`}
              >
                Edit Campaign
              </Button>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
}
