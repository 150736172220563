import { differenceInMinutes, format, formatDistanceToNow } from "date-fns";

import ceil from "lodash/ceil";
export const getDateDifference = (date) => {
  let diff = differenceInMinutes(new Date(), new Date(date));
  if (diff < 60) return diff + " minutes ago";
  diff = ceil(diff / 60);
  if (diff < 24) return `${diff} hour${diff === 0 ? "" : "s"} ago`;
  diff = ceil(diff / 24);
  if (diff < 30) return `${diff} day${diff === 0 ? "" : "s"} ago`;
  diff = ceil(diff / 30);
  if (diff < 12) return `${diff} month${diff === 0 ? "" : "s"} ago`;
  diff = diff / 12;
  return `${diff.toFixed(1)} year${ceil(diff) === 0 ? "" : "s"} ago`;
};

export const getUniqueArrayBy = (array, key) => {
  return [...new Map(array?.map((item) => [item[key], item])).values()];
};

export const getUniqueArrayValues = (array, key) => {
  return [...new Set(array?.map((item) => item[key]))];
};

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), "dd MMMM yyyy");
}

export function fTime(date) {
  if (!date) return "";
  return format(new Date(date), "hh:mm a");
}

export function fDateTime(date) {
  return format(new Date(date), "dd MMM, yyyy HH:mm");
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), "dd MMM yyyy hh:mm a");
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function getLocaleDate(date) {
  return date ? new Date(date)?.toString() : "-";
}
