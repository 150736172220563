import {
  Box,
  Card,
  Typography,
  Stack,
  Divider,
  Grid,
  useTheme,
  alpha,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import BarChartSmall from "src/components/Charts/BarChartSmall";
import Text from "src/components/Text";
import { GlobalContext } from "src/contexts/GlobalContext";
import { RuppeeSign } from "src/utils/constants";

function OrdersStat({ whichPage }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { stats, handleGetStats } = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);
  const { ordersStats } = stats || {};
  const [ordersByStatus, setOrdersByStatus] = useState({});

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        await handleGetStats();
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (ordersStats) {
      setLoading(true);
      const stats = ordersStats?.ordersByStatus?.reduce((acc, item) => {
        acc[item._id] = item;
        return acc;
      }, {});
      setOrdersByStatus(stats);
      setLoading(false);
    }
  }, [ordersStats]);

  return (
    !loading && (
      <>
        <Card>
          <Grid container spacing={0}>
            <Grid item xs={12} md={8}>
              <Stack
                direction={{ xs: "column", md: "row" }}
                divider={<Divider orientation="vertical" flexItem />}
                justifyContent="center"
                alignItems="center"
                spacing={0}
              >
                <Box
                  sx={{
                    width: "100%",
                  }}
                  p={1.5}
                  px={3}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box>
                    <Typography gutterBottom variant="h5">
                      {t("Successful Payments")}
                    </Typography>
                    <Typography variant="subtitle2">
                      <b>{ordersByStatus?.completed?.count || 0} </b> successful
                      orders
                    </Typography>
                  </Box>
                  <Text color="success">
                    <Typography variant="h2" fontSize="1.4rem">
                      {RuppeeSign} {ordersByStatus?.completed?.payment || 0}
                    </Typography>
                  </Text>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                  }}
                  p={1.5}
                  px={3}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box>
                    <Typography gutterBottom variant="h5">
                      {t("Payment Pending/Attempted")}
                    </Typography>
                    <Typography variant="subtitle2">
                      <b>
                        {(ordersByStatus?.pending?.count || 0) +
                          (ordersByStatus?.attempted?.count || 0) || 0}{" "}
                      </b>{" "}
                      pending orders
                    </Typography>
                  </Box>
                  <Text color="warning">
                    <Typography variant="h2" fontSize="1.4rem">
                      {RuppeeSign}{" "}
                      {(ordersByStatus?.pending?.payment || 0) +
                        (ordersByStatus?.attempted?.payment || 0) || 0}
                    </Typography>
                  </Text>
                </Box>
              </Stack>
              <Divider />
              <Stack
                direction={{ xs: "column", md: "row" }}
                divider={<Divider orientation="vertical" flexItem />}
                justifyContent="center"
                alignItems="center"
                spacing={0}
              >
                <Box
                  sx={{
                    width: "100%",
                  }}
                  p={1.5}
                  px={3}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box>
                    <Typography gutterBottom variant="h5">
                      {t("Payment Failed/Canceled")}
                    </Typography>
                    <Typography variant="subtitle2">
                      <b>
                        {(ordersByStatus?.failed?.count || 0) +
                          (ordersByStatus?.cancelled?.count || 0) || 0}{" "}
                      </b>
                      unsuccessful orders
                    </Typography>
                  </Box>
                  <Text color="error">
                    <Typography variant="h2" fontSize="1.4rem">
                      {RuppeeSign}{" "}
                      {(ordersByStatus?.failed?.payment || 0) +
                        (ordersByStatus?.cancelled?.payment || 0) || 0}
                    </Typography>
                  </Text>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                  }}
                  p={1.5}
                  px={3}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box>
                    <Typography gutterBottom variant="h5">
                      Appointment Orders
                    </Typography>
                    <Typography variant="subtitle2">
                      <b> {ordersStats?.ordersWithAppointment?.count || 0}</b>{" "}
                      successful appointments made
                    </Typography>
                  </Box>
                  <Text color="primary">
                    <Typography variant="h2" fontSize="1.4rem">
                      {RuppeeSign}{" "}
                      {ordersStats?.ordersWithAppointment?.payment || 0}
                    </Typography>
                  </Text>
                </Box>
              </Stack>
            </Grid>
            <Divider flexItem orientation="vertical" />
            <Grid
              item
              xs={12}
              md={3.99}
              sx={{ p: 1, background: alpha(theme.colors.success.main, 0.2) }}
            >
              <Card
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box px={2.5}>
                  <Typography
                    variant="caption"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Total Revenue
                  </Typography>
                  <Text color="success">
                    <Typography
                      variant="h2"
                      sx={{
                        pt: 1,
                      }}
                    >
                      {RuppeeSign} {ordersByStatus?.completed?.payment || 0}.00
                    </Typography>
                  </Text>
                  <Typography variant="subtitle2">
                    Overall <b>{ordersStats?.totalOrders || 0} Orders</b>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    position: "relative",
                    height: "100%",
                  }}
                >
                  <BarChartSmall
                    items={
                      ordersByStatus
                        ? [
                            {
                              label: "Attempted",
                              value: ordersByStatus?.attempted?.count || 0,
                              color: "secondary",
                            },
                            {
                              label: "Pending",
                              value: ordersByStatus?.pending?.count || 0,
                              color: "warning",
                            },
                            {
                              label: "Failed",
                              value: ordersByStatus?.failed?.count || 0,
                              color: "error",
                            },
                            {
                              label: "Cancelled",
                              value: ordersByStatus?.cancelled?.count || 0,
                              color: "info",
                            },
                            {
                              label: "Completed",
                              value: ordersByStatus?.completed?.count || 0,
                              color: "success",
                            },
                          ]
                        : []
                    }
                    total={ordersStats?.totalOrders || 0}
                    sx={{
                      height: 120,
                    }}
                    p={1}
                  />
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Card>
      </>
    )
  );
}

export default OrdersStat;
