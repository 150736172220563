import {
  Typography,
  Box,
  styled,
  Avatar,
  lighten,
  alpha,
  Stack,
  Divider,
  useTheme,
  Card,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import PieChartTwoToneIcon from "@mui/icons-material/PieChartTwoTone";
import { GlobalContext } from "src/contexts/GlobalContext";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router";

const AvatarPageTitle = styled(Avatar)(
  ({ theme }) => `
      width: ${theme.spacing(8)};
      height: ${theme.spacing(8)};
      color: ${theme.colors.primary.main};
      margin-right: ${theme.spacing(2)};
      background: ${
        theme.palette.mode === "dark"
          ? theme.colors.alpha.trueWhite[10]
          : theme.colors.alpha.white[50]
      };
      box-shadow: ${
        theme.palette.mode === "dark"
          ? `0 1px 0 ${alpha(
              lighten(theme.colors.primary.main, 0.8),
              0.2,
            )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.3), 0px 5px 16px -4px rgba(0, 0, 0, .5)`
          : `0px 2px 4px -3px ${alpha(
              theme.colors.alpha.black[100],
              0.4,
            )}, 0px 5px 16px -4px ${alpha(theme.colors.alpha.black[100], 0.2)}`
      };
`,
);

const DotLegend = styled("span")(
  ({ theme }) => `
    border-radius: 22px;
    width: ${theme.spacing(1.8)};
    height: ${theme.spacing(1.8)};
    display: inline-block;
    margin-right: ${theme.spacing(0.8)};
    border: ${theme.colors.alpha.white[100]} solid 2px;
`,
);

function Block1() {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const { stats } = useContext(GlobalContext);
  const { usersStats } = stats || {};

  return (
    <Box
      display="flex"
      alignItems={{ xs: "stretch", md: "center" }}
      flexDirection={{ xs: "column", md: "row" }}
      justifyContent="space-between"
    >
      <Box display="flex" alignItems="center">
        <AvatarPageTitle variant="rounded">
          <PieChartTwoToneIcon fontSize="large" />
        </AvatarPageTitle>
        <Box>
          <Typography variant="h3" component="h3" gutterBottom>
            {t("Dashboard Analytics")}
          </Typography>
          <Typography variant="subtitle2">
            {t(
              "Here you can see the statistics which are updated in every 5 minutes.",
            )}
          </Typography>
        </Box>
      </Box>
      <Card>
        <Stack
          direction="row"
          divider={
            <Divider
              sx={{
                background: `${theme.colors.alpha.black[10]}`,
              }}
              orientation="vertical"
              flexItem
            />
          }
          // justifyContent="space-around"
          alignItems="center"
          spacing={0}
        >
          <Box
            px={3}
            py={1}
            textAlign="center"
            sx={{
              width: "100%",
              cursor: "pointer",
              "&:hover": { backgroundColor: theme.palette.primary.main + 14 },
            }}
            onClick={() =>
              navigate("/dashboard/users?tab=responder&isOnline=true")
            }
          >
            <Typography
              variant="h4"
              fontWeight="normal"
              color="text.secondary"
              gutterBottom
            >
              Available
            </Typography>
            <Box display="flex" alignItems="center" justifyContent="center">
              <DotLegend
                style={{
                  // animation: `pulse 1s infinite`,
                  background: `${theme.colors.success.main}`,
                }}
              />
              <Typography color="text.primary" variant="h4">
                {usersStats?.onlineUsers || 0}
              </Typography>
            </Box>
          </Box>
          <Box
            px={3}
            py={1}
            textAlign="center"
            sx={{
              width: "100%",
              cursor: "pointer",
              "&:hover": { backgroundColor: theme.palette.primary.main + 14 },
            }}
            onClick={() =>
              navigate("/dashboard/users?tab=responder&isOnline=false")
            }
          >
            <Typography
              variant="h4"
              fontWeight="normal"
              color="text.secondary"
              gutterBottom
            >
              Not Available
            </Typography>
            <Box display="flex" alignItems="center" justifyContent="center">
              <DotLegend
                style={{
                  background: `${theme.colors.error.main}`,
                }}
              />
              <Typography color="text.primary" variant="h4">
                {usersStats?.offlineUsers || 0}
              </Typography>
            </Box>
          </Box>
        </Stack>

        <Box px={3} pb={1} textAlign="center">
          <Typography variant="caption">
            Vendors & Responders Availability
          </Typography>
        </Box>
      </Card>
    </Box>
  );
}

export default Block1;
