import {
  Button,
  Card,
  Box,
  Divider,
  Chip,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  useTheme,
  styled,
  capitalize,
  Stack,
  Typography,
  IconButton,
} from "@mui/material";
import {
  ArrowForwardIos,
  Edit,
  LocationOnOutlined,
  Storefront,
} from "@mui/icons-material";
import { fDateTimeSuffix } from "src/utils/date-fns";
import { Link } from "react-router-dom";

export default function CafeCard({ item }) {
  const theme = useTheme();

  return (
    <Card>
      <ListItem>
        <ListItemAvatar
          sx={{
            mr: 2,
            cursor: "pointer",
          }}
        >
          <Link
            to={
              item?.googleMapLink
                ? item?.googleMapLink
                : item?.address?.latitude && item?.address?.longitude
                  ? `https://www.google.com/maps/search/?api=1&query=${item?.address?.latitude},${item?.address?.longitude}`
                  : `https://www.google.com/maps/search/?api=1&query=${item?.address?.address1.replace(/ /g, "+")}`
            }
            target="_blank"
          >
            <img
              src={"/static/images/logo/google-maps.svg"}
              style={{
                width: 50,
                height: 50,
              }}
            />
          </Link>
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography variant="h4" fontSize={"1.2rem"}>
              {item?.title
                ? item?.title
                : "Copconnect Cafe" +
                  (item?.address?.city ? ", " + item?.address?.city : "")}
            </Typography>
          }
          secondary={item?.address?.address1}
          secondaryTypographyProps={{
            variant: "subtitle2",
            sx: {
              pt: 1,
            },
          }}
        />
        <Box
          sx={{
            my: { xs: 2, md: 0 },
          }}
          display="flex"
          alignItems="center"
          justifyContent="flex-right"
        >
          <IconButton
            component={Link}
            to={`/dashboard/cafes/${item?._id}/edit`}
            size="small"
          >
            <Edit />
          </IconButton>
        </Box>
      </ListItem>
      <Divider component="li" />
    </Card>
  );
}
