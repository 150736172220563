import axios from "axios";
import { Notify } from "notiflix";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const handleLogout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  window.location.replace(
    `/auth/login?redirect_url=${encodeURIComponent(window.location.pathname)}`
  );
};

const ISSERVER = typeof window === "undefined";
let headerAccessToken;
if (!ISSERVER) {
  headerAccessToken = localStorage.getItem("accessToken")
    ? JSON.parse(localStorage.getItem("accessToken"))
    : "";
}
axiosInstance.defaults.headers.common["authorization"] =
  "Bearer " + headerAccessToken;
axiosInstance.defaults.headers.post["Content-Type"] = "application/json";

(async () => {
  axiosInstance.interceptors.request.use(
    (request) => {
      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      if ([401].includes(response?.status)) {
        Notify.failure("Please Login");
        handleLogout();
        return;
      }
      if ([403].includes(response?.status)) {
        Notify.failure("Access Denied");
        handleLogout();
        return;
      }
      return response;
    },
    (error) => {
      if (error.response?.status === 401) {
        Notify.failure("Please Login");
      }
      if (error.response?.status === 403) {
        Notify.failure("Access Denied");
      }

      if ([401, 403].includes(error.response?.status)) {
        handleLogout();
      }
      return Promise.reject(error);
    }
  );
})();

export default axiosInstance;
