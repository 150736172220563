import {
  Box,
  Avatar,
  Typography,
  Card,
  styled,
  Divider,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  formatDistance,
  format,
  subDays,
  subHours,
  subMinutes,
} from "date-fns";
import ScheduleTwoToneIcon from "@mui/icons-material/ScheduleTwoTone";
import EmptyHere from "src/components/EmptyHere";
import Scrollbar from "src/components/Scrollbar";
import { useEffect, useRef, useState } from "react";
import { getDateDifference } from "src/utils/date-fns";
import axiosInstance from "src/utils/axiosInstance";
import { Notify } from "notiflix";
import MessageContent from "./components/MessageContent";
import { Link } from "react-router-dom";

const CardWrapperPrimary = styled(Card)(
  ({ theme }) => `
      background: ${theme.colors.primary.main};
      color: ${theme.palette.primary.contrastText};
      padding: ${theme.spacing(1)};
      border-radius: ${theme.general.borderRadiusXl};
      border-top-right-radius: ${theme.general.borderRadius};
      max-width: 500px;
      display: inline-flex;
`
);

const CardWrapperSecondary = styled(Card)(
  ({ theme }) => `
      background: ${theme.colors.alpha.black[10]};
      color: ${theme.colors.alpha.black[100]};
      padding: ${theme.spacing(1)};
      border-radius: ${theme.general.borderRadiusXl};
      border-top-left-radius: ${theme.general.borderRadius};
      max-width: 500px;
      display: inline-flex;
`
);

const ChatWindow = styled(Box)(
  () => `
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            flex: 1;
    `
);

const ChatTopBar = styled(Box)(
  ({ theme }) => `
            background: ${theme.colors.alpha.white[100]};
            border-bottom: ${theme.colors.alpha.black[10]} solid 1px;
            padding: ${theme.spacing(2)};
    `
);

function ChatMessages({ chat, user }) {
  const { t } = useTranslation();
  const [selectedChat, setSelectedChat] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentChat, setCurrentChat] = useState([]);


  useEffect(()=>{
    if(currentChat?.data?.length){
      var messageBody = document.querySelector('#allmessages');
      messageBody.lastChild.scrollIntoView();
    }
  },[currentChat])

  useEffect(() => {
    setSelectedChat(chat);
  }, [chat]);

  useEffect(() => {
    if (!selectedChat?.id) return;
    (async () => {
      try {
        setLoading(true);

        const resp = await axiosInstance.get(`/chats/${selectedChat.chatId}`);
        if (resp?.status === 200) {
          setCurrentChat(resp.data);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        Notify.failure(
          error.response?.data?.message ||
            error.response?.statusText ||
            "an error occured"
        );
      }
    })();
  }, [selectedChat]);

  const ChatHeader = () => {
    return (
      <Box
        sx={{
          display: { sm: "flex" },
        }}
        alignItems="center"
        component={Link}
        to={`/dashboard/users/${selectedChat?.id}`}
        style={{
          textDecoration: "none",
        }}
      >
        <Avatar
          variant="rounded"
          sx={{
            width: 50,
            height: 50,
          }}
          alt={selectedChat?.title || ""}
          src={selectedChat?.avatar || ""}
        />
        <Box
          sx={{
            pl: { sm: 1.5 },
            pt: { xs: 1.5, sm: 0 },
          }}
        >
          <Typography variant="h4" gutterBottom>
            {selectedChat?.title || ""}
          </Typography>
          <Typography variant="subtitle2">
            {getDateDifference(selectedChat?.updatedAt)}
          </Typography>
        </Box>
      </Box>
    );
  };

  return !selectedChat?.id ? (
    <EmptyHere
      title={t("No Chat selected")}
      subtitle={t("Please select a chat to view messages.")}
    />
  ) : (
    <ChatWindow>
      <ChatTopBar>
        <ChatHeader />
      </ChatTopBar>
      <Box flex={1}>
        {currentChat?.data?.length === 0 ? (
          <EmptyHere subtitle={t("No messages in this chat.")} />
        ) : (
          <Scrollbar id="messageScrollBar" style={{ width: "100%", height: "100%" }}>
            <Box id="allmessages" p={2}>
              {currentChat?.data
                ?.sort(
                  (a, b) => new Date(a.created_at) - new Date(b.created_at)
                )
                ?.map((message, index) =>
                  message?.sender === selectedChat?.id ? (
                    <Box
                      display="flex"
                      alignItems="flex-start"
                      justifyContent="flex-start"
                      py={3}
                      key={message.message_id}
                    >
                      <Tooltip title={selectedChat?.title}>
                        <Avatar
                          variant="rounded"
                          sx={{
                            width: 50,
                            height: 50,
                          }}
                          alt={selectedChat?.title || ""}
                          src={selectedChat?.avatar || ""}
                        />
                      </Tooltip>
                      <Box
                        display="flex"
                        alignItems="flex-start"
                        flexDirection="column"
                        justifyContent="flex-start"
                        ml={2}
                      >
                        <CardWrapperSecondary>
                          <MessageContent message={message} />
                        </CardWrapperSecondary>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            pt: 1,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <ScheduleTwoToneIcon
                            sx={{
                              mr: 0.5,
                            }}
                            fontSize="small"
                          />
                          {getDateDifference(message?.created_at)}
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      display="flex"
                      alignItems="flex-start"
                      justifyContent="flex-end"
                      py={3}
                      key={message.message_id}
                    >
                      <Box
                        display="flex"
                        alignItems="flex-end"
                        flexDirection="column"
                        justifyContent="flex-end"
                        mr={2}
                      >
                        <CardWrapperPrimary>
                          <MessageContent message={message} />
                        </CardWrapperPrimary>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            pt: 1,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <ScheduleTwoToneIcon
                            sx={{
                              mr: 0.5,
                            }}
                            fontSize="small"
                          />
                          {getDateDifference(message?.created_at)}
                        </Typography>
                      </Box>
                      <Tooltip title={user?.firstName}>
                        <Avatar
                          variant="rounded"
                          sx={{
                            width: 50,
                            height: 50,
                          }}
                          alt={""}
                          src={
                            user?.profileImage
                              ? process.env.REACT_APP_DO_SPACE_URL +
                                user?.profileImage
                              : ""
                          }
                        />
                      </Tooltip>
                    </Box>
                  )
                )}
            </Box>
          </Scrollbar>
        )}
      </Box>
    </ChatWindow>
  );
}

export default ChatMessages;
