import { AddTwoTone, Delete } from "@mui/icons-material";
import {
  LoadingButton,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Notify } from "notiflix";
import React, { useEffect, useRef, useState } from "react";
import axiosInstance from "src/utils/axiosInstance";
import { convertBytesToReadable } from "src/utils/helpers";

export default function AddTicketLog({
  ticketData,
  setTicketData,
  ticketId,
  ticketLogs,
  setTicketLogs,
}) {
  const inputFile = useRef(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formInputs, setFormInputs] = useState({});
  const [attachments, setAttachments] = useState([]);
  const [markasClosed, setMarkasClosed] = useState(false);

  useEffect(() => {
    setFormInputs({});
    setAttachments([]);
  }, []);

  const handleFormInputs = (e) => {
    setFormInputs({ ...formInputs, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    try {
      if (!formInputs?.description) {
        Notify.failure("Message is required");
        return;
      }
      setLoading(true);

      const formData = new FormData();
      if (attachments.length > 0) {
        for (let i = 0; i < attachments.length; i++) {
          formData.append("files", attachments[i]);
        }

        const imageResp = await axiosInstance.post(
          "/users/upload-media",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
        );
        if (imageResp.status === 200 && imageResp.data) {
          formInputs.attachments = imageResp.data;
          setFormInputs({
            ...formInputs,
            attachments: imageResp.data,
          });
        }
      }

      const resp = await axiosInstance.post(
        `/tickets/${ticketId}/logs`,
        formInputs,
      );
      if (resp?.status === 201) {
        setTicketLogs([...ticketLogs, resp.data?.data]);
        if (markasClosed) {
          await handleMarkAsClosed();
        }
        Notify.success("Request sent successfully");
        setFormInputs({});
        setLoading(false);
        setOpen(false);
      }
    } catch (error) {
      setLoading(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured",
      );
    }
  };

  const handleMarkAsClosed = async () => {
    try {
      const resp = await axiosInstance.put(`/tickets/${ticketId}`, {
        status: "closed",
      });
      if (resp.status === 200) {
        setTicketData({
          ...ticketData,
          status: "closed",
        });
      }
      return true;
    } catch (error) {
      console.log(error);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured",
      );
      return false;
    }
  };

  return (
    <div>
      <TimelineItem
        sx={{
          border: "1px dashed",
          borderRadius: 1,
          cursor: "pointer",
          borderColor: "primary.main",
          mt: 3,
        }}
        onClick={() => setOpen(true)}
      >
        <TimelineOppositeContent
          sx={{
            width: "190px",
            flex: "none",
          }}
        ></TimelineOppositeContent>
        <TimelineSeparator
          sx={{
            position: "relative",
            mb: 3,
          }}
        >
          <TimelineDot variant="outlined" color="primary">
            <AddTwoTone color="primary" />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ ml: 3 }}>
          <Typography color={"primary"} variant={"h6"}>
            Add Log / Message
          </Typography>
        </TimelineContent>
      </TimelineItem>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h4">
              Add Log for Ticket: ({formInputs?.ticketId || "N/A"})
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2} sx={{ mt: 2 }}>
            {/* <TextField
              fullWidth
              label="Log title"
              variant="outlined"
              name="title"
              onChange={handleFormInputs}
            />{" "} */}
            <TextField
              fullWidth
              label="Message (required)"
              placeholder="Write your comment here... "
              multiline
              rows={6}
              variant="outlined"
              name="description"
              onChange={handleFormInputs}
            />
            <Button
              sx={{
                color: "primary.main",
                border: "1px dashed",
                borderColor: "primary.main",
                "&:hover": {
                  color: "primary.dark",
                  borderColor: "primary.dark",
                },
              }}
              onClick={() => inputFile.current.click()}
              startIcon={<AddTwoTone />}
            >
              Add Attachment(s) (optional)
              <input
                type="file"
                onChange={(e) => setAttachments(e.target.files)}
                multiple
                style={{ display: "none" }}
                ref={inputFile}
              />
            </Button>
            <List dense>
              {Array.from(attachments)?.map((file, index) => (
                <ListItem
                  key={index}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => {
                        const newAttachments = [...attachments];
                        newAttachments.splice(index, 1);
                        setAttachments(newAttachments);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  }
                >
                  <ListItemAvatar>{index + 1}.</ListItemAvatar>
                  <ListItemText
                    primary={file?.name}
                    secondary={convertBytesToReadable(file?.size || 0)}
                  />
                </ListItem>
              ))}
            </List>
          </Stack>

          <Stack direction="row" justifyContent={"space-between"}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={markasClosed}
                  color="primary"
                  onChange={() => {
                    setMarkasClosed(true);
                  }}
                />
              }
              label={"Mark as CLOSED with this comment"}
            />
            <Box>
              <Button onClick={() => setOpen(false)}>Cancel</Button>
              <LoadingButton
                variant="contained"
                onClick={handleSubmit}
                loading={loading}
              >
                Submit
              </LoadingButton>
            </Box>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}
