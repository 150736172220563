import {
  Grid,
  Box,
  Card,
  Typography,
  Divider,
  Tooltip,
  Stack,
  IconButton,
  Avatar,
  alpha,
  styled,
  useTheme,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import AccountBoxTwoToneIcon from "@mui/icons-material/AccountBoxTwoTone";
import ArrowDownwardTwoToneIcon from "@mui/icons-material/ArrowDownwardTwoTone";
import ArrowUpwardTwoToneIcon from "@mui/icons-material/ArrowUpwardTwoTone";
import ThumbUpTwoToneIcon from "@mui/icons-material/ThumbUpTwoTone";
import CompareArrowsTwoToneIcon from "@mui/icons-material/CompareArrowsTwoTone";
import AddAlertTwoToneIcon from "@mui/icons-material/AddAlertTwoTone";
import Text from "src/components/Text";
import PersonTwoToneIcon from "@mui/icons-material/PersonTwoTone";
import SubscriptionsTwoToneIcon from "@mui/icons-material/SubscriptionsTwoTone";
import MonetizationOnTwoToneIcon from "@mui/icons-material/MonetizationOnTwoTone";
import MoneyTwoToneIcon from "@mui/icons-material/MoneyTwoTone";
import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone";
import AddLocationTwoToneIcon from "@mui/icons-material/AddLocationTwoTone";
import AddBusinessTwoToneIcon from "@mui/icons-material/AddBusinessTwoTone";
import {
  ConfirmationNumber,
  ConfirmationNumberTwoTone,
  PaymentsTwoTone,
  PeopleAltTwoTone,
} from "@mui/icons-material";

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
    padding: ${theme.spacing(1.5)};
    color: ${theme.palette.primary.contrastText};
    transform: translateY(0px);
    transition: ${theme.transitions.create([
      "color",
      "transform",
      "background",
    ])};
    
    .MuiSvgIcon-root {
        transform: scale(1);
        transition: ${theme.transitions.create(["transform"])};
    }

    &:hover {
        background: initial;
        transform: translateY(-2px);

        .MuiSvgIcon-root {
            transform: scale(1.2);
        }
    }
  `
);

function Block2({ stats }) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Grid container spacing={4} mb={4}>
      <Grid item xs={12} md={6}>
        <Card
          sx={{
            p: 2.5,
            background: `${theme.colors.gradients.black1}`,
          }}
        >
          <Box
            pb={4}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack spacing={2}>
              <Typography
                gutterBottom
                component="div"
                variant="caption"
                sx={{
                  color: `${theme.colors.alpha.trueWhite[70]}`,
                }}
              >
                {t("New Accounts")}
              </Typography>
              <Typography
                variant="h2"
                sx={{
                  color: `${theme.colors.alpha.trueWhite[100]}`,
                }}
              >
                {stats?.newAccounts || 0}
              </Typography>
            </Stack>
            <Avatar
              variant="rounded"
              sx={{
                width: `${theme.spacing(7)}`,
                height: `${theme.spacing(7)}`,
                background: `${theme.colors.alpha.trueWhite[100]}`,
                color: `${theme.colors.success.main}`,
              }}
            >
              <AccountBoxTwoToneIcon fontSize="large" />
            </Avatar>
          </Box>
          <Box display="flex" alignItems="center">
            <Typography
              variant="subtitle2"
              noWrap
              sx={{
                color: `${theme.colors.alpha.trueWhite[70]}`,
              }}
            >
              This Month (
              {new Date().toLocaleString("default", {
                month: "long",
              }) +
                " " +
                new Date().getFullYear()}
              )
            </Typography>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card
          sx={{
            p: 2.5,
            background: `${theme.colors.gradients.blue4}`,
          }}
        >
          <Box
            pb={4}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack spacing={2}>
              <Typography
                gutterBottom
                component="div"
                variant="caption"
                sx={{
                  color: `${theme.colors.alpha.trueWhite[70]}`,
                }}
              >
                {t("New Orders")}
              </Typography>
              <Typography
                variant="h2"
                sx={{
                  color: `${theme.colors.alpha.trueWhite[100]}`,
                }}
              >
                {stats?.orders?.completed || 0}
              </Typography>
            </Stack>
            <Avatar
              variant="rounded"
              sx={{
                width: `${theme.spacing(7)}`,
                height: `${theme.spacing(7)}`,
                background: `${theme.colors.alpha.trueWhite[100]}`,
                color: `${theme.colors.warning.main}`,
              }}
            >
              <PaymentsTwoTone fontSize="large" />
            </Avatar>
          </Box>
          <Box display="flex" alignItems="center">
            <Typography
              variant="subtitle2"
              sx={{
                display: "flex",
                alignItems: "center",
                pr: 0.5,
                color: `${theme.colors.success.main}`,
              }}
            >
              <span>
                {stats?.orders?.total
                  ? (
                      (stats?.orders?.completed / stats?.orders?.total) *
                      100
                    )?.toFixed(1)
                  : 0}
                %
              </span>
            </Typography>
            <Typography
              variant="subtitle2"
              noWrap
              sx={{
                color: `${theme.colors.alpha.trueWhite[70]}`,
              }}
            >
              {" "}
              (out of <b>{stats?.orders?.total || 0}</b>) order has been
              completed
            </Typography>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card
          sx={{
            p: 2.5,
            background: `${theme.colors.gradients.green2}`,
          }}
        >
          <Box
            pb={4}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack spacing={2}>
              <Typography
                gutterBottom
                component="div"
                variant="caption"
                sx={{
                  color: `${theme.colors.alpha.trueWhite[70]}`,
                }}
              >
                {t("New Appointments")}
              </Typography>
              <Typography
                variant="h2"
                sx={{
                  color: `${theme.colors.alpha.trueWhite[100]}`,
                }}
              >
                {stats?.appointments?.booked || 0}
              </Typography>
            </Stack>
            <Avatar
              variant="rounded"
              sx={{
                width: `${theme.spacing(7)}`,
                height: `${theme.spacing(7)}`,
                background: `${alpha(theme.colors.alpha.trueWhite[100], 0.2)}`,
                color: `${theme.colors.alpha.trueWhite[100]}`,
              }}
            >
              <PeopleAltTwoTone fontSize="large" />
            </Avatar>
          </Box>
          <Box display="flex" alignItems="center">
            <Typography
              variant="h4"
              sx={{
                display: "flex",
                alignItems: "center",
                pr: 0.5,
                color: `${theme.colors.alpha.trueWhite[100]}`,
              }}
            >
              <span>
                {stats?.appointments?.total
                  ? (
                      (stats?.appointments?.booked /
                        stats?.appointments?.total) *
                      100
                    )?.toFixed(1)
                  : 0}
                %
              </span>
            </Typography>
            <Typography
              variant="subtitle2"
              noWrap
              sx={{
                color: `${theme.colors.alpha.trueWhite[70]}`,
              }}
            >
              {" "}
              (out of {stats?.appointments?.total || 0}) appointment has been
              booked
            </Typography>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card
          sx={{
            p: 2.5,
            background: `${theme.colors.gradients.blue2}`,
          }}
        >
          <Box
            pb={4}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack spacing={2}>
              <Typography
                gutterBottom
                component="div"
                variant="caption"
                sx={{
                  color: `${theme.colors.alpha.trueWhite[70]}`,
                }}
              >
                {t("New Tickets")}
              </Typography>
              <Typography
                variant="h2"
                sx={{
                  color: `${theme.colors.alpha.trueWhite[100]}`,
                }}
              >
                {stats?.tickets?.total || 0}
              </Typography>
            </Stack>
            <Avatar
              variant="rounded"
              sx={{
                width: `${theme.spacing(7)}`,
                height: `${theme.spacing(7)}`,
                background: `${alpha(theme.colors.alpha.trueWhite[100], 0.2)}`,
                color: `${theme.colors.alpha.trueWhite[100]}`,
              }}
            >
              <ConfirmationNumberTwoTone fontSize="large" />
            </Avatar>
          </Box>
          <Box display="flex" alignItems="center">
            <Typography
              variant="subtitle2"
              sx={{
                display: "flex",
                alignItems: "center",
                pr: 0.5,
                color: `${theme.colors.alpha.trueWhite[100]}`,
              }}
            >
              <span>
                {stats?.tickets?.total
                  ? (
                      (stats?.tickets?.closed / stats?.tickets?.total) *
                      100
                    )?.toFixed(1)
                  : 0}
                %
              </span>
            </Typography>
            <Typography
              variant="subtitle2"
              noWrap
              sx={{
                color: `${theme.colors.alpha.trueWhite[70]}`,
              }}
            >
              {" "}
              tickets has been closed
            </Typography>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
}

export default Block2;
